import React from 'react';
import { makeStyles } from '@mui/styles';
import type { Theme } from '@mui/material/styles';
import type { RouteComponentProps } from '@reach/router';

import { AppBar, Box, Tabs, Tab, Typography } from '@mui/material';

import { SuppliersTable } from '../components/SuppliersTable';
import { ProductsTable } from '../components/ProductsTable';
import { InventoryTable } from '../components/InventoryTable';

interface InventoryProps extends RouteComponentProps {
  me?: any;
  children?: React.ReactNode;
  index?: any;
  value?: any;
}

function TabPanel(props: InventoryProps) {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const Inventory: React.FC<InventoryProps> = ({ me }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ bgcolor: 'background.paper', borderColor: 'divider' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          centered
          sx={{
            '&.MuiTabs-indicator': { bgColor: '#e15520' },
          }}
        >
          <Tab
            sx={{ color: '#ddd', '&.Mui-selected': { color: '#fff' } }}
            label="Inventar"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ color: '#ddd', '&.Mui-selected': { color: '#fff' } }}
            label="Produse"
            {...a11yProps(1)}
          />
          <Tab
            sx={{ color: '#ddd', '&.Mui-selected': { color: '#fff' } }}
            label="Furnizori"
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <InventoryTable me={me} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ProductsTable me={me} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SuppliersTable me={me} />
      </TabPanel>
    </Box>
  );
};
