import { gql } from '@apollo/client';

export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String!
    $sku: String
    $uom: UnityOfMeasure!
    $description: String
    $supplierId: ID!
  ) {
    createProduct(
      input: {
        name: $name
        sku: $sku
        uom: $uom
        description: $description
        supplierId: $supplierId
      }
    ) {
      id
      name
      sku
      uom
      description
      supplier {
        id
        name
        description
        location {
          location
          lat
          lng
        }
        phone
      }
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
    }
  }
`;
