import { gql } from '@apollo/client';

export const GET_ANIMALS_TOTAL = gql`
  query GetAnimalsTotal($startDate: String, $endDate: String) {
    getAnimalsTotal(input: { startDate: $startDate, endDate: $endDate }) {
      animalSpecies
      total
    }
  }
`;
