import { gql } from '@apollo/client';

export const GET_INVENTORIES_TOTAL = gql`
  query getInventoriesTotal {
    getInventoriesTotal {
      id
      product {
        id
        name
        uom
        description
        supplier {
          id
          name
          description
          location {
            location
            lat
            lng
          }
          phone
        }
      }
      totalQuantity
    }
  }
`;
