import type React from 'react';
import type { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';

import { Container } from '@mui/material';
import MaterialTable from 'material-table';
import DateFnsAdapter from '@date-io/date-fns';

import { Loading } from '../components/Loading';
import { MainContainer } from '../components/MainContainer';
import { Header } from '../components/Header';

import { PatchedPagination } from '../utils/PatchedPagination';

import { GET_ACTIVITIES } from '../gql/queries/getActivities';

interface ActivitiesProps extends RouteComponentProps {
  me?: any;
}

const dateFns = new DateFnsAdapter();

const getColumns: any = () => [
  {
    title: 'Utilizator',
    field: 'createdBy.email',
  },
  {
    title: 'Acţiune',
    field: 'action',
    render: ({ action }: any) => {
      switch (action) {
        case 'CREATE_USER':
          return 'a creat utilizatorul: ';
        case 'UPDATE_USER':
          return 'a editat utilizatorul: ';
        case 'CHANGE_USER_PASSWORD':
          return 'a schimbat parola utilizatorului: ';
        case 'DELETE_USER':
          return 'a şters utilizatorul: ';

        case 'CREATE_NOT_REGISTERED_ANIMALS':
          return 'a creat fişă pentru animalul neînregistrat: ';
        case 'UPDATE_NOT_REGISTERED_ANIMALS':
          return 'a editat fişa animalului neînregistrat: ';
        case 'DELETE_NOT_REGISTERED_ANIMALS':
          return 'a şters fişa animalului neînregistrat: ';

        case 'CREATE_ANIMAL':
          return 'a creat fişă pentru animalul: ';
        case 'UPDATE_ANIMAL':
          return 'a editat fişa animalului: ';
        case 'DELETE_ANIMAL':
          return 'a şters fişa animalului: ';

        case 'CREATE_SUPPLIER':
          return 'a creat furnizorul: ';
        case 'UPDATE_SUPPLIER':
          return 'a editat furnizorul: ';
        case 'DELETE_SUPPLIER':
          return 'a şters furnizorul: ';

        case 'CREATE_ADOPTION':
          return 'a creat adopţie: ';
        case 'UPDATE_ADOPTION':
          return 'a editat adopţie: ';
        case 'DELETE_ADOPTION':
          return 'a şters adopţia: ';

        case 'CREATE_PRODUCT':
          return 'a creat produsul: ';
        case 'UPDATE_PRODUCT':
          return 'a editat produsul: ';
        case 'DELETE_PRODUCT':
          return 'a şters fişa produsului: ';

        case 'CREATE_INVENTORY':
          return 'a creat inventarul: ';
        case 'UPDATE_INVENTORY':
          return 'a editat inventarul: ';
        case 'DELETE_INVENTORY':
          return 'a şters inventarul: ';
      }
    },
  },
  {
    title: 'Valoare',
    field: 'value',
    render: ({ values }: any) => {
      if (!values) return '';

      const {
        user,
        animalRegNumber,
        animalSheetDate,
        animalSterilizationLocation,
        animalSpecies,
        animalSex,
        // supplier,
      } = values;
      if (user) {
        return user;
      } else {
        const newDate = new Date(animalSheetDate);
        const renderedSheetDate = dateFns.formatByString(newDate, 'dd/MM/yyyy');
        const renderedAnimalSpecies = () => {
          switch (animalSpecies) {
            case 'cat':
              return 'pisica';
            case 'dog':
              return 'caine';
          }
        };
        const renderedAnimalSex = () => {
          switch (animalSex) {
            case 'female':
              return 'femela';
            case 'male':
              return 'mascul';
          }
        };

        return `${animalRegNumber}, ${renderedSheetDate}, ${
          animalSterilizationLocation?.location
        }, ${renderedAnimalSpecies()}, ${renderedAnimalSex()}`;
      }
    },
  },
  {
    title: 'Data',
    field: 'createdAt',
    render: ({ createdAt }: any) => {
      const newDate = new Date(createdAt);
      return dateFns.formatByString(newDate, 'HH:mm:ss - dd/MM/yyyy');
    },
  },
];

export const Activities: React.FC<ActivitiesProps> = ({ me }) => {
  const { data, loading, error } = useQuery(GET_ACTIVITIES);

  if (loading) return <Loading />;
  if (error) return <p>Error</p>;

  const getActivities = data?.getActivities;
  const reversedActivities = getActivities.slice().reverse();
  const activities = reversedActivities.map((o: any) => ({
    ...o,
  }));

  return (
    <MainContainer maxWidth="xl">
      <Header />
      <Container maxWidth="xl">
        <MaterialTable
          title="Istoric acţiuni"
          columns={getColumns()}
          data={activities}
          components={{
            Pagination: PatchedPagination,
          }}
          options={{ exportButton: true, filtering: true }}
          localization={{
            toolbar: {
              searchPlaceholder: 'Caută',
              searchTooltip: 'Caută',
              exportTitle: 'Exportă',
              exportPDFName: 'Exportă ca PDF',
              exportCSVName: 'Exportă ca CSV',
            },
            pagination: {
              previousTooltip: 'Pagina precedentă',
              nextTooltip: 'Pagina următoare',
              firstTooltip: 'Prima pagină',
              lastTooltip: 'Ultima pagină',
              labelRowsSelect: 'Rânduri',
            },
          }}
        />
      </Container>
    </MainContainer>
  );
};
