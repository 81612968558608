import React, { useEffect } from 'react';
import { Router, navigate, Redirect } from '@reach/router';

import { useQuery, ApolloClient, useApolloClient } from '@apollo/client';
// import { IS_USER_LOGGED_IN } from '../gql/queries/IsUserLoggedIn';
import { GET_CURRENT_USER } from '../gql/queries/getCurrentUser';
// import { NAVIGATION_ACTION } from '../gql/queries/navigationAction';

import { Loading } from '../components/Loading';
import { Nav } from '../components/Nav';
import { FloatingMenuButton } from '../components/FloatingMenuButton';

import { SignIn } from './SignIn';
import { SignOut, signout } from './SignOut';
import { Home } from './Home';
import { AllAnimals } from './AllAnimals';
import { Adoptions } from './Adoptions';
import { Results } from './Results';
import { NotRegAnimalsWithTreatment } from './NotRegAnimalsWithTreatment';
import { AllUsers } from './AllUsers';
import { Activities } from './Activities';
import { Profile } from './Profile';
import { ChangePassword } from './ChangePassword';
import { Inventory } from './Inventory';

export function UserPages() {
  const client: ApolloClient<any> = useApolloClient();
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_CURRENT_USER);

  const me = userData?.getCurrentUser;
  useEffect(() => {}, [me]);

  if (userLoading) return <Loading />;
  if (userError) {
    signout(client);
    return <p>Error</p>;
  }

  return (
    <>
      <Router>
        <Home path="/" user={me} />
        <AllAnimals path="all-animals" me={me} />
        <Adoptions path="adoptions" me={me} />
        <Results path="results" me={me} />
        <NotRegAnimalsWithTreatment
          path="not-registered-animals-with-treatment"
          me={me}
        />
        <AllUsers path="users" me={me} />
        <Activities path="activities" me={me} />
        <Profile path="profile" me={me} />
        <ChangePassword path="change-password" me={me} />
        <Inventory path="inventory" me={me} />

        <SignOut path="signout" />
      </Router>
      <Nav me={me} navigate={navigate} />
      <FloatingMenuButton me={me} navigate={navigate} />
    </>
  );
}

export function GuestPages() {
  return (
    <>
      <Router>
        <Redirect from="/" to="signin" noThrow />
        <SignIn path="signin" />
      </Router>
    </>
  );
}
