import React, { useState } from 'react';
import {
  useQuery,
  useMutation,
  ApolloClient,
  useApolloClient,
} from '@apollo/client';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
  Link,
} from '@mui/material';

import { Loading } from './Loading';
import { AddSupplierDialog } from './AddSupplierDialog';

import { GET_SUPPLIERS } from '../gql/queries/getSuppliers';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { CREATE_SUPPLIER } from '../gql/mutations/CreateSupplier';

interface AddProductDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
}

export const AddProductDialog: React.FC<AddProductDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
}) => {
  const client: ApolloClient<any> = useApolloClient();
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [name, setName] = useState<any>();
  const [sku, setSku] = useState<any>();
  const [uom, setUom] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [supplierId, setSupplierId] = useState<any>();

  const { data, loading, error } = useQuery(GET_SUPPLIERS);

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function onNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setName(value);
  }

  function onSkuChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSku(value);
  }

  function onUomChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setUom(value);
  }

  function onDescriptionChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setDescription(value);
  }

  function onSupplierChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSupplierId(value);
  }

  const variables = {
    name,
    sku,
    uom,
    description,
    supplierId,
  };

  const [
    createSupplier,
    { loading: createSupplierLoading, error: createSupplierError },
  ] = useMutation(CREATE_SUPPLIER, {
    update(cache, { data: { createSupplier } }: any) {
      const { getSuppliers }: any = cache.readQuery({
        query: GET_SUPPLIERS,
      });

      cache.writeQuery({
        query: GET_SUPPLIERS,
        data: {
          getSuppliers: [...getSuppliers, createSupplier],
        },
      });
    },
    refetchQueries: [
      {
        query: GET_SUPPLIERS,
      },
    ],
    awaitRefetchQueries: true,
  });

  async function registerSupplier(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.name)
        throw new Error('Numele furnizorului nu este completat.');

      await createSupplier({ variables });

      return onCloseAddDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  if (loading || createSupplierLoading) return <Loading />;
  if (error || createSupplierError) return <p>{`Error: ${error}`}</p>;

  const suppliers = data?.getSuppliers.map((o: any) => ({
    ...o,
  }));

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="xs"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <form
            noValidate
            id="register-product-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="name"
                  label="Nume produs"
                  fullWidth
                  autoFocus
                  onChange={(e) => onNameChange(e)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="sku"
                  label="SKU"
                  fullWidth
                  autoFocus
                  onChange={(e) => onSkuChange(e)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="uom"
                  label="Unitate de Măsură"
                  fullWidth
                  autoFocus
                  select
                  onChange={(e) => onUomChange(e)}
                  required
                >
                  <MenuItem value="DROPPER">Pipetă</MenuItem>
                  <MenuItem value="TABLET">Tabletă</MenuItem>
                  <MenuItem value="VIAL">Flacon</MenuItem>
                  <MenuItem value="PIECE">Bucată</MenuItem>
                  <MenuItem value="AMPOULE">Fiolă</MenuItem>
                  <MenuItem value="DOSE">Doză</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="description"
                  label="Descriere"
                  fullWidth
                  autoFocus
                  onChange={(e) => onDescriptionChange(e)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="supplierId"
                  label="Furnizor"
                  fullWidth
                  autoFocus
                  select
                  onChange={(e) => onSupplierChange(e)}
                  required
                >
                  {suppliers.map((supplier: any) => {
                    return (
                      <MenuItem value={supplier.id}>{supplier.name}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => openAdd()}
                >
                  Adaugă un furnizor nou
                </Link>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-product-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
      <AddSupplierDialog
        title="Adaugă un furnizor nou"
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={registerSupplier}
      />
    </>
  );
};
