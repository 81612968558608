import React, { useState } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';

import { SignInForm } from '../components/SignInForm';
import { Loading } from '../components/Loading';

import { SIGNIN } from '../gql/mutations/Signin';
import { IS_USER_LOGGED_IN } from '../gql/queries/IsUserLoggedIn';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { NAVIGATION_ACTION } from '../gql/queries/navigationAction';
import { GET_CURRENT_USER } from 'src/gql/queries/getCurrentUser';

interface SignInProps extends RouteComponentProps {}

export const SignIn: React.FC<SignInProps> = () => {
  const client: ApolloClient<any> = useApolloClient();
  const [submitted, setSubmitted] = useState(false);

  function onSubmittedChange(value: boolean) {
    setSubmitted(value);
  }

  const [signIn, { loading, error }] = useMutation(SIGNIN, {
    async onCompleted({ signIn }) {
      if (!signIn) return null;

      const { user, token } = signIn;

      if (!user) return null;

      localStorage.setItem('token', token);
      localStorage.setItem('type', user.type);

      client.writeQuery({
        query: IS_USER_LOGGED_IN,
        data: {
          isLoggedIn: true,
          type: user.type,
        },
      });
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'success',
          alertMessage: `Ai fost autentificat cu succes!`,
        },
      });
      client.writeQuery({
        query: NAVIGATION_ACTION,
        data: {
          navigationAction: '/',
        },
      });
      client.writeQuery({
        query: GET_CURRENT_USER,
        data: {
          getCurrentUser: user,
        },
      });

      return navigate('/');
    },
    onError(error) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `Datele introduse nu sunt corecte.`,
        },
      });
      onSubmittedChange(false);
    },
  });

  if (loading) return <Loading />;
  if (error) return <p>Error</p>;

  return (
    <>
      <SignInForm
        signIn={signIn}
        submitted={submitted}
        onSubmittedChange={onSubmittedChange}
      />
    </>
  );
};
