import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $type: UserType!
    $firstName: String
    $lastName: String
    $location: LocationInput
    $phone: String
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        type: $type
        firstName: $firstName
        lastName: $lastName
        location: $location
        phone: $phone
      }
    ) {
      id
      isEnabled
      email
      type
      firstName
      lastName
      location {
        lat
        lng
        location
      }
      phone
      title
      createdBy {
        id
        email
        type
        firstName
        lastName
      }
      createdAt
      updatedBy {
        id
        email
        type
        firstName
        lastName
      }
      lastUpdate
    }
  }
`;
