import type React from 'react';
import { navigate } from '@reach/router';

import {
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  InputBase,
  alpha,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBack, Search } from '@mui/icons-material';

interface HeaderProps {
  title?: string;
  backButton?: boolean;
  searchBar?: boolean;
  me?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

export const Header: React.FC<HeaderProps> = ({
  title = '',
  backButton = false,
  searchBar = false,
  me,
}) => {
  const classes = useStyles();

  function onBackClick() {
    navigate(-1);
  }

  // const menuId = 'primary-search-account-menu';
  const renderBackButton = (
    <IconButton edge="start" color="inherit" onClick={() => onBackClick()}>
      <ArrowBack />
    </IconButton>
  );
  const renderSearchBar = (
    <div className={classes.search}>
      {searchBar && (
        <>
          <div className={classes.searchIcon}>
            <Search />
          </div>
          <InputBase
            placeholder={'Caută...'}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </>
      )}
    </div>
  );
  const renderTitle = (
    <Typography className={classes.title} variant="h6" noWrap>
      {title}
    </Typography>
  );
  const renderAppBar = (
    <AppBar color="inherit" position="static" elevation={0}>
      <Toolbar>
        {backButton && renderBackButton}
        {renderTitle}
        {renderSearchBar}
      </Toolbar>
    </AppBar>
  );

  return <div className={classes.root}>{renderAppBar}</div>;
};
