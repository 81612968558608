import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import {
  ApolloClient,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';

import { Container, TextField, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MaterialTable from 'material-table';
import { DeleteOutline, EditOutlined, Add } from '@mui/icons-material';

import DateFnsAdapter from '@date-io/date-fns';

import { DatePicker } from '@mui/x-date-pickers';

import { Loading } from '../components/Loading';
import { MainContainer } from '../components/MainContainer';
import { Header } from '../components/Header';
import { FloatingButton } from '../components/FloatingButton';
import { AddAnimalDialog } from '../components/AddAnimalDialog';
import { EditAnimalDialog } from '../components/EditAnimalDialog';
import { AreYouSureDialog } from '../components/AreYouSureDialog';

import { PatchedPagination } from '../utils/PatchedPagination';

import { GET_ALL_ANIMALS } from '../gql/queries/getAllAnimals';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { CREATE_ANIMAL } from '../gql/mutations/CreateAnimal';
import { UPDATE_ANIMAL } from '../gql/mutations/UpdateAnimal';
import { DELETE_ANIMAL } from '../gql/mutations/DeleteAnimal';

interface AllAnimalsProps extends RouteComponentProps {
  me?: any;
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
}));

const dateFns = new DateFnsAdapter();

const getColumns: any = ({ me }: any) => [
  {
    title: 'Nr. Crt.',
    field: 'regNumber',
  },
  {
    title: 'Data fisei',
    field: 'sheetDate',
    render: ({ sheetDate }: any) => {
      const newDate = new Date(sheetDate);
      return dateFns.formatByString(newDate, 'dd/MM/yyyy');
    },
  },
  {
    title: 'Locatie sterilizare',
    field: 'sterilizationLocation.location',
  },
  {
    title: 'Specie',
    field: 'animalSpecies',
    render: ({ animalSpecies }: any) => {
      switch (animalSpecies) {
        case 'dog':
          return 'caine';
        case 'cat':
          return 'pisica';
        case 'other':
          return 'alta specie';
      }
    },
    lookup: {
      dog: 'caine',
      cat: 'pisica',
      other: 'alta specie',
    },
  },
  {
    title: 'Sex',
    field: 'animalSex',
    render: ({ animalSex }: any) => {
      switch (animalSex) {
        case 'male':
          return 'mascul';
        case 'female':
          return 'femela';
      }
    },
    lookup: {
      male: 'mascul',
      female: 'femela',
    },
  },
  {
    title: 'Microcip',
    field: 'animalMicrochipStatus',
    render: ({ animalMicrochipStatus }: any) => {
      switch (animalMicrochipStatus) {
        case 'eduxanima':
          return 'Eduxanima';
        case 'other':
          return 'Alt cabinet';
        case 'no':
          return 'Nu';
      }
    },
    lookup: {
      eduxanima: 'Eduxanima',
      other: 'Alt cabinet',
      no: 'Nu',
    },
  },
  {
    title: 'Nr. microcip',
    field: 'animalMicrochipNumber',
  },
  {
    title: 'Sterilizat',
    field: 'animalSterilization',
    render: ({ animalSterilization }: any) => {
      switch (animalSterilization) {
        case 'eduxanima':
          return 'Eduxanima';
        case 'other':
          return 'Alt cabinet';
        case 'no':
          return 'Nu';
        case 'unknown':
          return 'Nu se stie';
      }
    },
    lookup: {
      eduxanima: 'Eduxanima',
      other: 'Alt cabinet',
      no: 'Nu',
      unknown: 'Nu se stie',
    },
  },
  {
    title: 'Veterinar',
    field: 'vetName',
  },
  {
    title: 'Nume proprietar',
    field: 'ownerName',
  },
  {
    title: 'Nume insotitor',
    field: 'attendantName',
  },
  {
    title: 'Fisa creata de',
    field: 'createdBy.email',
  },
  {
    title: 'La data de',
    field: 'createdAt',
    render: ({ createdAt }: any) => {
      const newDate = new Date(createdAt);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
  {
    title: 'Modificat de',
    field: 'updatedBy.email',
  },
  {
    title: 'La data de',
    field: 'lastUpdate',
    render: ({ lastUpdate }: any) => {
      const newDate = new Date(lastUpdate);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
];

export const AllAnimals: React.FC<AllAnimalsProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();
  const classes = useStyles();
  const today = new Date();
  const dateFns = new DateFnsAdapter();
  const newDate = dateFns.formatByString(today, 'yyyy-MM-dd');
  const initialDate = new Date('2021-01-01');
  const monthBeginning = dateFns.formatByString(today, 'yyyy-MM-01');

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedAnimal, setSelectedAnimal] = useState<any>();
  const [startDate, setStartDate] = useState<any>(monthBeginning);
  const [endDate, setEndDate] = useState<any>(newDate);

  const title = 'Eşti sigur că vrei să ştergi această înregistrare?';
  const content =
    'Ştergerea este definitivă şi această înregistrare nu mai poate fi adusă înapoi.';

  const yes = 'Şterge';
  const no = 'Închide';

  const { data, loading, error } = useQuery(GET_ALL_ANIMALS, {
    variables: {
      startDate,
      endDate,
    },
  });

  function onStartDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setStartDate(newValue);
  }

  function onEndDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setEndDate(newValue);
  }

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function onCloseEditDialog() {
    setOpenEditDialog(false);
  }

  function onCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  function removeAnimalDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedAnimal(rowData);
    setOpenDeleteDialog(true);
  }

  function editAnimalDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedAnimal(rowData);
    setOpenEditDialog(true);
  }

  const [
    createAnimal,
    { loading: createAnimalLoading, error: createAnimalError },
  ] = useMutation(CREATE_ANIMAL, {
    update(cache, { data: { createAnimal } }: any) {
      const { allAnimals }: any = cache.readQuery({
        query: GET_ALL_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
      });
      cache.writeQuery({
        query: GET_ALL_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
        data: {
          allAnimals: [...allAnimals, createAnimal],
        },
      });
    },
  });

  const [
    updateAnimal,
    { loading: updateAnimalLoading, error: updateAnimalError },
  ] = useMutation(UPDATE_ANIMAL, {
    update(cache, { data: { updateAnimal } }: any) {
      const { allAnimals }: any = cache.readQuery({
        query: GET_ALL_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
      });
      cache.writeQuery({
        query: GET_ALL_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
        data: {
          allAnimals: [
            ...allAnimals.filter(
              (animal: any) => animal.id !== updateAnimal.id
            ),
            updateAnimal,
          ],
        },
      });
    },
  });

  const [
    deleteAnimal,
    { loading: deleteAnimalLoading, error: deleteAnimalError },
  ] = useMutation(DELETE_ANIMAL, {
    update(cache, { data: deleteAnimal }: any) {
      const { allAnimals }: any = cache.readQuery({
        query: GET_ALL_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
      });
      cache.writeQuery({
        query: GET_ALL_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
        data: {
          allAnimals: allAnimals.filter(
            (animal: any) => animal.id !== selectedAnimal?.id
          ),
        },
      });
    },
  });

  async function onRegisterAnimal(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.regNumber)
        throw new Error('Nr. înregistrare nu este completat');
      if (!variables.sheetDate)
        throw new Error('Data fişei nu este completată.');
      if (!variables.sterilizationLocation)
        throw new Error('Locaţia nu este completată.');
      if (typeof variables.animalOwnerStatus === 'undefined')
        throw new Error('Are proprietar? nu este completat.');
      // if (variables.animalOwnerStatus && !variables.ownerName)
      //   throw new Error('Nume proprietar nu este completat.');
      // if (variables.animalOwnerStatus && !variables.ownerAddress)
      //   throw new Error('Adresa proprietarului nu este completată.');
      if (!variables.animalSpecies)
        throw new Error('Specia animalului nu este completată.');
      if (!variables.animalSex)
        throw new Error('Sexul animalului nu este completat.');
      // if (!variables.animalBirthday)
      //   throw new Error('Ziua de naştere a animalului nu este completată.');
      // if (!variables.animalDetails)
      //   throw new Error('Semnalmentele animalului nu sunt completate.');
      // if (typeof variables.animalTatooStatus === 'undefined')
      //   throw new Error('Tatuaj nu este completat.');
      // if (typeof variables.animalMicrochipStatus === 'undefined')
      //   throw new Error('Microchip nu este completat.');
      // if (
      //   variables.animalMicrochipNumber &&
      //   (variables.animalMicrochipNumber.length < 15 ||
      //     variables.animalMicrochipNumber.length > 15)
      // ) {
      //   throw new Error('Numărul microcipului trebuie să aibă 15 caractere.');
      // }
      // if (!variables.animalSterilization)
      //   throw new Error('Sterilizare nu este completat.');
      // if (!variables.vetName)
      //   throw new Error('Numele veterinarului nu este completat.');
      if (typeof variables.animalSurgeryStatus === 'undefined')
        throw new Error(
          'S-au efectuat alte interventii chirurgicale? nu este completat.'
        );

      await createAnimal({
        variables,
      });

      return onCloseAddDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onEditAnimal(
    event: React.FormEvent<HTMLFormElement>,
    input: any
  ) {
    event.preventDefault();

    try {
      if (!input) throw new Error('Nu există variabile.');
      if (!input.regNumber)
        throw new Error('Nr. înregistrare nu este completat');
      if (!input.sheetDate) throw new Error('Data fişei nu este completată.');
      if (!input.sterilizationLocation)
        throw new Error('Locaţia nu este completată.');
      if (typeof input.animalOwnerStatus === 'undefined')
        throw new Error('Are proprietar? nu este completat.');
      if (input.animalOwnerStatus && !input.ownerName)
        if (!input.animalSpecies)
          //   throw new Error('Nume proprietar nu este completat.');
          // if (input.animalOwnerStatus && !input.ownerAddress)
          //   throw new Error('Adresa proprietarului nu este completată.');
          throw new Error('Specia animalului nu este completată.');
      if (!input.animalSex)
        throw new Error('Sexul animalului nu este completat.');
      // if (!input.animalBirthday)
      //   throw new Error('Ziua de naştere a animalului nu este completată.');
      // if (!input.animalDetails)
      //   throw new Error('Semnalmentele animalului nu sunt completate.');
      // if (typeof input.animalTatooStatus === 'undefined')
      //   throw new Error('Tatuaj nu este completat.');
      // if (typeof input.animalMicrochipStatus === 'undefined')
      //   throw new Error('Microcip nu este completat.');
      // if (
      //   input.animalMicrochipNumber &&
      //   (input.animalMicrochipNumber.length < 15 ||
      //     input.animalMicrochipNumber.length > 15)
      // ) {
      //   throw new Error('Numărul microcipului trebuie să aibă 15 caractere.');
      // }
      // if (!input.animalSterilization)
      //   throw new Error('Sterilizare nu este completat.');
      // if (!input.vetName)
      //   throw new Error('Numele veterinarului nu este completat.');
      if (typeof input.animalSurgeryStatus === 'undefined')
        throw new Error(
          'S-au efectuat alte interventii chirurgicale? nu este completat.'
        );

      const variables: any = { id: selectedAnimal?.id, ...input };
      await updateAnimal({ variables });

      return onCloseEditDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function removeAnimal() {
    const variables: any = { id: selectedAnimal?.id };
    await deleteAnimal({ variables });
    setOpenDeleteDialog(false);
  }

  if (
    loading ||
    createAnimalLoading ||
    deleteAnimalLoading ||
    updateAnimalLoading
  )
    return <Loading />;
  if (error || createAnimalError || deleteAnimalError || updateAnimalError) {
    return <p>Error</p>;
  }

  const allAnimals = data?.allAnimals.map((o: any) => ({
    ...o,
  }));

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />
      <Container className={classes.cardGrid} maxWidth="xl">
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="Începând cu"
                value={startDate}
                onChange={onStartDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Până la"
                value={endDate}
                onChange={onEndDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <MaterialTable
          title="Animale"
          columns={getColumns({ me })}
          data={allAnimals}
          components={{
            Pagination: PatchedPagination,
          }}
          options={{
            exportButton: true,
            filtering: true,
          }}
          actions={[
            {
              icon: DeleteOutline,
              tooltip: 'Şterge înregistrarea',
              onClick: (event, rowData) => removeAnimalDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
            {
              icon: EditOutlined,
              tooltip: 'Editează înregistrarea',
              onClick: (event, rowData) => editAnimalDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
          ]}
          localization={{
            toolbar: {
              searchPlaceholder: 'Caută',
              searchTooltip: 'Caută',
              exportTitle: 'Exportă',
              exportPDFName: 'Exportă ca PDF',
              exportCSVName: 'Exportă ca CSV',
            },
            pagination: {
              previousTooltip: 'Pagina precedentă',
              nextTooltip: 'Pagina următoare',
              firstTooltip: 'Prima pagină',
              lastTooltip: 'Ultima pagină',
              labelRowsSelect: 'Rânduri',
            },
            header: {
              actions: 'Acţiuni',
            },
          }}
        />
      </Container>
      {me.type !== 'VIEWER' && (
        <FloatingButton icon={<Add />} onClick={() => openAdd()} />
      )}
      <AddAnimalDialog
        title={'Fişă animal'}
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={onRegisterAnimal}
      />
      <EditAnimalDialog
        title={'Editează fişă animal'}
        open={openEditDialog}
        onClose={onCloseEditDialog}
        onSave={onEditAnimal}
        animal={selectedAnimal}
        startDate={startDate}
        endDate={endDate}
      />
      <AreYouSureDialog
        title={title}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        onYes={removeAnimal}
      />
    </MainContainer>
  );
};
