import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query getProducts {
    getProducts {
      id
      name
      sku
      uom
      description
      supplier {
        id
        name
        description
        location {
          location
          lat
          lng
        }
        phone
      }
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
    }
  }
`;
