import { gql } from '@apollo/client';

export const GET_NOT_REGISTERED_ANIMALS_WITH_TREATMENT = gql`
  query getNotRegisteredAnimalsWithTreatment(
    $startDate: String
    $endDate: String
  ) {
    getNotRegisteredAnimalsWithTreatment(
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      animalSpecies
      animalSex
      animalOwnerStatus
      fieldName
      total
    }
  }
`;
