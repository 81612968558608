import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

interface AddAdoptionDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
}

const useStyles = makeStyles(() => ({
  uploadArea: {
    minHeight: '50px!important',
  },
}));

export const AddAdoptionDialog: React.FC<AddAdoptionDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
}) => {
  const [regNumber, setRegNumber] = useState<any>();
  const [animalEnteredDate, setAnimalEnteredDate] = useState<any>();
  const [animalStatus, setAnimalStatus] = useState({
    entered: false,
    adopted: false,
    intermediated: false,
  });
  const [animalSpecies, setAnimalSpecies] = useState<any>();
  const [animalSex, setAnimalSex] = useState<any>();
  const [animalName, setAnimalName] = useState<any>();
  const [animalMicrochipStatus, setAnimalMicrochipStatus] = useState<any>();
  const [animalMicrochipNumber, setAnimalMicrochipNumber] = useState<any>();
  const [animalLocation, setAnimalLocation] = useState<any>();
  const [animalLeaveDate, setAnimalLeaveDate] = useState<any>();
  const [animalOtherObs, setAnimalOtherObs] = useState<any>();

  function onRegNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setRegNumber(value);
  }

  function onAnimalEnteredDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalEnteredDate(value);
  }

  function onAnimalStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const name = (event.target as HTMLInputElement).name;
    const checked = (event.target as HTMLInputElement).checked;
    switch (name) {
      case 'entered':
        return setAnimalStatus({
          entered: checked,
          adopted: !checked,
          intermediated: !checked,
        });

      case 'adopted':
        return setAnimalStatus({
          entered: !checked,
          adopted: checked,
          intermediated: !checked,
        });

      case 'intermediated':
        return setAnimalStatus({
          entered: !checked,
          adopted: !checked,
          intermediated: checked,
        });
    }
  }

  function onAnimalSpeciesChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSpecies(value);
  }

  function onAnimalSexChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSex(value);
  }

  function onAnimalNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalName(value);
  }

  function onAnimalMicrochipStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipStatus(value);
  }

  function onAnimalMicrochipNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipNumber(value);
  }

  function onAnimalLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalLocation(value);
  }

  function onAnimalLeaveDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalLeaveDate(value);
  }

  function onAnimalOtherObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalOtherObs(value);
  }

  const { entered, adopted, intermediated } = animalStatus;

  const variables = {
    regNumber,
    animalEnteredDate,
    animalStatus,
    animalSpecies,
    animalSex,
    animalName,
    animalMicrochipStatus,
    animalMicrochipNumber,
    animalLocation,
    animalLeaveDate,
    animalOtherObs,
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <br />
          <br />
          <form
            noValidate
            id="register-animal-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="regNumber"
                  label="Nr. Crt."
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => onRegNumberChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="animalName"
                  label="Nume animal"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalNameChange(e)}
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="animalMicrochipStatus"
                  label="Microcip"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalMicrochipStatusChange(e)}
                >
                  <MenuItem value="eduxanima">Eduxanima</MenuItem>
                  <MenuItem value="other">Alt cabinet</MenuItem>
                  <MenuItem value="no">Nu</MenuItem>
                </TextField>
              </Grid>
              {animalMicrochipStatus && animalMicrochipStatus !== 'no' && (
                <Grid item xs={8} sm={4}>
                  <TextField
                    id="animalMicrochipNumber"
                    label="Număr microcip"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnimalMicrochipNumberChange(e)}
                    InputProps={{ inputProps: { min: 15, max: 15 } }}
                    required={
                      animalMicrochipStatus && animalMicrochipStatus !== 'no'
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={8}>
                <TextField
                  id="animalLocation"
                  label="De unde"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalLocationChange(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="animalEnteredDate"
                  label="Dată intrare"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                  onChange={(e) => onAnimalEnteredDateChange(e)}
                />
              </Grid>
              <br />

              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalSpecies"
                  label="Specie"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalSpeciesChange(e)}
                >
                  <MenuItem value="dog">Caine</MenuItem>
                  <MenuItem value="cat">Pisica</MenuItem>
                  <MenuItem value="other">Alta specie</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalSex"
                  label="Sex"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalSexChange(e)}
                >
                  <MenuItem value="male">Mascul</MenuItem>
                  <MenuItem value="female">Femela</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalLeaveDate"
                  label="Dată ieşire"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => onAnimalLeaveDateChange(e)}
                  required={adopted || intermediated}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={entered}
                        onChange={(e) => onAnimalStatusChange(e)}
                        name="entered"
                        // disabled
                      />
                    }
                    label="intrat"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={adopted}
                        onChange={(e) => onAnimalStatusChange(e)}
                        name="adopted"
                      />
                    }
                    label="adoptat"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={intermediated}
                        onChange={(e) => onAnimalStatusChange(e)}
                        name="intermediated"
                      />
                    }
                    label="intermediat"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="animalOtherObs"
                  label="Observaţii"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalOtherObsChange(e)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-animal-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
