import { promisify } from './promisify';

interface LoadScriptConfig {
  src?: string;
  snippet?: string;
  id?: string;
  async?: boolean;
  root?: any;
  removeFirst?: boolean;
  crossOrigin?: string;
}

function removeScript(scriptId: string) {
  // console.log(`removeScript ${scriptId}`)

  if (document.querySelector(`#${scriptId}`)) {
    const element = document.getElementById(scriptId);
    element?.remove();
    // console.log(`removeScript ${scriptId} removed`)
  }
}

export function loadScriptCallback(
  {
    src,
    snippet,
    id,
    async,
    root = document.body,
    removeFirst = false,
    crossOrigin,
  }: LoadScriptConfig,
  callback: any
) {
  if (id && !removeFirst && document.querySelector(`#${id}`)) {
    return callback(new Error(`${id} already exists`));
  }
  let scriptJS = document.createElement('script');
  if (snippet) scriptJS.innerHTML = snippet;
  else if (src) scriptJS.src = src;
  if (id) {
    if (removeFirst) removeScript(id);
    scriptJS.id = id;
  }
  if (async !== undefined) scriptJS.async = async;
  if (crossOrigin) scriptJS.crossOrigin = crossOrigin;
  scriptJS.onload = () => callback(null, scriptJS);
  scriptJS.onerror = () =>
    callback(new Error(`Script load error for ${snippet}`));
  root.appendChild(scriptJS);
}

export const loadScript = promisify(loadScriptCallback);
