import { gql } from '@apollo/client';

export const SIGNIN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      token
      user {
        id
        email
        firstName
        lastName
        isEnabled
        type
        location {
          location
          lat
          lng
        }
        phone
      }
    }
  }
`;
