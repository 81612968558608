import React, { useState, useEffect } from 'react';
import type { RouteComponentProps } from '@reach/router';
import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';

import { Container, Grid, TextField, Paper, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MainContainer } from '../components/MainContainer';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';

import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { UPDATE_USER } from '../gql/mutations/UpdateUser';

interface ProfileProps extends RouteComponentProps {
  me: any;
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const Profile: React.FC<ProfileProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();

  const classes = useStyles();
  const [email, setEmail] = useState<any>(me?.email);
  const [type, setType] = useState<any>(me?.type);
  const [firstName, setFirstName] = useState<any>(me?.firstName);
  const [lastName, setLastName] = useState<any>(me?.lastName);
  const [location, setLocation] = useState<any>(me?.location);
  const [locationLoc, setLocationLoc] = useState<any>();
  const [phone, setPhone] = useState<any>(me?.phone);

  useEffect(() => {
    setEmail(me?.email);
    setType(me?.type);
    setFirstName(me?.firstName);
    setLastName(me?.lastName);
    setLocation(me?.location);
    setLocationLoc(me?.location.location);
    setPhone(me?.phone);
  }, [me]);

  function onFirstNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setFirstName(value);
  }
  function onLastNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setLastName(value);
  }
  function onLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setLocationLoc(value);
  }
  function onPhoneChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setPhone(value);
  }

  const [updateUser, { loading: updateUserLoading, error: updateUserError }] =
    useMutation(UPDATE_USER);

  async function onSave(event: React.FormEvent<HTMLFormElement>, input: any) {
    event.preventDefault();

    try {
      const variables = { id: me.id, ...input };

      await updateUser({
        variables,
      });
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  const input = {
    email,
    type,
    firstName,
    lastName,
    location: {
      location: locationLoc,
    },
    phone,
  };

  if (updateUserLoading) return <Loading />;
  if (updateUserError) return <p>Error</p>;

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />
      <Container maxWidth="sm" className={classes.cardGrid}>
        <Paper elevation={10} className={classes.paper}>
          <form
            noValidate
            id="update-user-form"
            onSubmit={(e) => onSave(e, input)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="firstName"
                  label="Prenume"
                  fullWidth
                  autoFocus
                  onChange={(e) => onFirstNameChange(e)}
                  value={firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="lastName"
                  label="Nume"
                  fullWidth
                  autoFocus
                  onChange={(e) => onLastNameChange(e)}
                  value={lastName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="location"
                  label="Locaţie"
                  fullWidth
                  autoFocus
                  onChange={(e) => onLocationChange(e)}
                  value={location?.location}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="phone"
                  label="Telefon"
                  fullWidth
                  autoFocus
                  onChange={(e) => onPhoneChange(e)}
                  value={phone}
                />
              </Grid>
            </Grid>
          </form>
          <Box display="flex" justifyContent="flex-end" padding="12px 0">
            <Button color="secondary" type="submit" form="update-user-form">
              Salvează
            </Button>
          </Box>
        </Paper>
      </Container>
    </MainContainer>
  );
};
