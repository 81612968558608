import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
} from '@mui/material';

import { Loading } from './Loading';

import { GET_SUPPLIERS } from '../gql/queries/getSuppliers';

interface EditProductDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
  product: any;
}

export const EditProductDialog: React.FC<EditProductDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
  product,
}) => {
  const [name, setName] = useState<any>();
  const [sku, setSku] = useState<any>();
  const [uom, setUom] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [supplierId, setSupplierId] = useState<any>();

  const { data, loading, error } = useQuery(GET_SUPPLIERS);

  useEffect(() => {
    setName(product?.name);
    setSku(product?.sku);
    setUom(product?.uom);
    setDescription(product?.description);
    setSupplierId(product?.supplier.id);
  }, [product]);

  function onNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setName(value);
  }

  function onSkuChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSku(value);
  }

  function onUomChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setUom(value);
  }

  function onDescriptionChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setDescription(value);
  }

  function onSupplierChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSupplierId(value);
  }

  const variables = {
    name,
    sku,
    uom,
    description,
    supplierId,
  };

  if (loading) return <Loading />;
  if (error) return <p>{`Error: ${error}`}</p>;

  const suppliers = data?.getSuppliers.map((o: any) => ({
    ...o,
  }));

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="xs"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <form
            noValidate
            id="register-product-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="name"
                  label="Nume produs"
                  fullWidth
                  autoFocus
                  onChange={(e) => onNameChange(e)}
                  required
                  value={name}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="sku"
                  label="SKU"
                  fullWidth
                  autoFocus
                  onChange={(e) => onSkuChange(e)}
                  value={sku}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="uom"
                  label="Unitate de Măsură"
                  fullWidth
                  autoFocus
                  select
                  onChange={(e) => onUomChange(e)}
                  required
                  value={uom}
                >
                  <MenuItem value="DROPPER">Pipetă</MenuItem>
                  <MenuItem value="TABLET">Tabletă</MenuItem>
                  <MenuItem value="VIAL">Flacon</MenuItem>
                  <MenuItem value="PIECE">Bucată</MenuItem>
                  <MenuItem value="AMPOULE">Fiolă</MenuItem>
                  <MenuItem value="DOSE">Doză</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="description"
                  label="Descriere"
                  fullWidth
                  autoFocus
                  onChange={(e) => onDescriptionChange(e)}
                  value={description}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="supplierId"
                  label="Furnizor"
                  fullWidth
                  autoFocus
                  select
                  onChange={(e) => onSupplierChange(e)}
                  required
                  value={supplierId}
                >
                  {suppliers.map((supplier: any) => {
                    return (
                      <MenuItem value={supplier.id}>{supplier.name}</MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-product-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
