import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $email: String!
    $type: UserType!
    $firstName: String
    $lastName: String
    $location: LocationInput
    $phone: String
  ) {
    updateUser(
      id: $id
      input: {
        email: $email
        type: $type
        firstName: $firstName
        lastName: $lastName
        location: $location
        phone: $phone
      }
    ) {
      id
      isEnabled
      email
      type
      firstName
      lastName
      location {
        lat
        lng
        location
      }
      phone
      title
      createdBy {
        id
        email
        type
        firstName
        lastName
      }
      createdAt
      updatedBy {
        id
        email
        type
        firstName
        lastName
      }
      lastUpdate
    }
  }
`;
