export function promisify(fn: any) {
  return function (...args: any) {
    // return  wrapper function
    return new Promise((resolve, reject) => {
      function callback(err: any, result: any) {
        // our custom callback for fn
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      }
      args.push(callback); // append custom callback at the end of the fn arguments
      // @ts-ignore
      fn.call(this, ...args); // call the original function
    });
  };
}
