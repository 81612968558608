import { gql } from '@apollo/client';

export const GET_ACTIVITIES = gql`
  query getActivities {
    getActivities {
      id
      type
      objectId
      action
      values {
        user
        animalRegNumber
        animalSheetDate
        animalSterilizationLocation {
          location
        }
        animalSpecies
        animalSex
      }
      description
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
    }
  }
`;
