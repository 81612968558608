import { gql } from '@apollo/client';

export const GET_SUPPLIERS = gql`
  query getSuppliers {
    getSuppliers {
      id
      name
      description
      location {
        location
        lat
        lng
      }
      phone
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
    }
  }
`;
