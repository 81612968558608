import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

interface EditAdoptionDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
  animal: any;
  startDate?: any;
  endDate?: any;
}

const useStyles = makeStyles(() => ({
  uploadArea: {
    minHeight: '50px!important',
  },
}));

export const EditAdoptionDialog: React.FC<EditAdoptionDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
  animal,
  startDate,
  endDate,
}) => {
  const [regNumber, setRegNumber] = useState<any>(animal?.regNumber);
  const [animalEnteredDate, setAnimalEnteredDate] = useState<any>(
    animal?.animalEnteredDate
  );
  const [animalStatus, setAnimalStatus] = useState({
    entered: animal?.animalStatus.entered,
    adopted: animal?.animalStatus.adopted,
    intermediated: animal?.animalStatus.intermediated,
  });
  const [animalSpecies, setAnimalSpecies] = useState<any>(
    animal?.animalSpecies
  );
  const [animalSex, setAnimalSex] = useState<any>(animal?.animalSex);
  const [animalName, setAnimalName] = useState<any>(animal?.animalName);
  const [animalMicrochipStatus, setAnimalMicrochipStatus] = useState<any>(
    animal?.animalMicrochipStatus
  );
  const [animalMicrochipNumber, setAnimalMicrochipNumber] = useState<any>(
    animal?.animalMicrochipNumber
  );
  const [animalLocation, setAnimalLocation] = useState<any>(
    animal?.animalLocation
  );
  const [animalLeaveDate, setAnimalLeaveDate] = useState<any>(
    animal?.animalLeaveDate
  );
  const [animalOtherObs, setAnimalOtherObs] = useState<any>(
    animal?.animalOtherObs
  );

  useEffect(() => {
    setRegNumber(animal?.regNumber);
    setAnimalEnteredDate(animal?.animalEnteredDate);
    setAnimalStatus({
      entered: animal?.animalStatus.entered,
      adopted: animal?.animalStatus.adopted,
      intermediated: animal?.animalStatus.intermediated,
    });
    setAnimalSpecies(animal?.animalSpecies);
    setAnimalSex(animal?.animalSex);
    setAnimalName(animal?.animalName);
    setAnimalMicrochipStatus(animal?.animalMicrochipStatus);
    setAnimalMicrochipNumber(animal?.animalMicrochipNumber);
    setAnimalLocation(animal?.animalLocation);
    setAnimalLeaveDate(animal?.animalLeaveDate);
    setAnimalOtherObs(animal?.animalOtherObs);
  }, [animal]);

  function onRegNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setRegNumber(value);
  }

  function onAnimalEnteredDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalEnteredDate(value);
  }

  function onAnimalStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const name = (event.target as HTMLInputElement).name;
    const checked = (event.target as HTMLInputElement).checked;
    switch (name) {
      case 'entered':
        return setAnimalStatus({
          entered: checked,
          adopted: !checked,
          intermediated: !checked,
        });

      case 'adopted':
        return setAnimalStatus({
          entered: !checked,
          adopted: checked,
          intermediated: !checked,
        });

      case 'intermediated':
        return setAnimalStatus({
          entered: !checked,
          adopted: !checked,
          intermediated: checked,
        });
    }

    if (
      animalStatus.adopted === false ||
      animalStatus.intermediated === false
    ) {
      setAnimalLeaveDate('');
    }
  }

  function onAnimalSpeciesChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSpecies(value);
  }

  function onAnimalSexChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSex(value);
  }

  function onAnimalNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalName(value);
  }

  function onAnimalMicrochipStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipStatus(value);
  }

  function onAnimalMicrochipNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipNumber(value);
  }

  function onAnimalLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalLocation(value);
  }

  function onAnimalLeaveDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalLeaveDate(value);
  }

  function onAnimalOtherObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalOtherObs(value);
  }

  const { entered, adopted, intermediated } = animalStatus;

  const variables = {
    regNumber,
    animalEnteredDate,
    animalStatus,
    animalSpecies,
    animalSex,
    animalName,
    animalMicrochipStatus,
    animalMicrochipNumber,
    animalLocation,
    animalLeaveDate,
    animalOtherObs,
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <br />
          <br />
          <form
            noValidate
            id="register-animal-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="regNumber"
                  label="Nr. Crt."
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => onRegNumberChange(e)}
                  value={regNumber}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="animalName"
                  label="Nume animal"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalNameChange(e)}
                  value={animalName}
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="animalMicrochipStatus"
                  label="Microcip"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalMicrochipStatusChange(e)}
                  value={animalMicrochipStatus}
                >
                  <MenuItem value="eduxanima">Eduxanima</MenuItem>
                  <MenuItem value="other">Alt cabinet</MenuItem>
                  <MenuItem value="no">Nu</MenuItem>
                </TextField>
              </Grid>
              {animalMicrochipStatus && animalMicrochipStatus !== 'no' && (
                <Grid item xs={8} sm={4}>
                  <TextField
                    id="animalMicrochipNumber"
                    label="Număr microcip"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnimalMicrochipNumberChange(e)}
                    InputProps={{ inputProps: { min: 15, max: 15 } }}
                    required={
                      animalMicrochipStatus && animalMicrochipStatus !== 'no'
                    }
                    value={animalMicrochipNumber}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={8}>
                <TextField
                  id="animalLocation"
                  label="De unde"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalLocationChange(e)}
                  required
                  value={animalLocation}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="animalEnteredDate"
                  label="Dată intrare"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                  onChange={(e) => onAnimalEnteredDateChange(e)}
                  value={animalEnteredDate}
                />
              </Grid>
              <br />

              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalSpecies"
                  label="Specie"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalSpeciesChange(e)}
                  value={animalSpecies}
                >
                  <MenuItem value="dog">Câine</MenuItem>
                  <MenuItem value="cat">Pisică</MenuItem>
                  <MenuItem value="other">Altă specie</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalSex"
                  label="Sex"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalSexChange(e)}
                  value={animalSex}
                >
                  <MenuItem value="male">Mascul</MenuItem>
                  <MenuItem value="female">Femela</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalLeaveDate"
                  label="Dată ieşire"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => onAnimalLeaveDateChange(e)}
                  required={adopted || intermediated}
                  value={animalLeaveDate}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={entered}
                        onChange={(e) => onAnimalStatusChange(e)}
                        name="entered"
                        value={animalStatus.entered}
                      />
                    }
                    label="intrat"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={adopted}
                        onChange={(e) => onAnimalStatusChange(e)}
                        name="adopted"
                        value={animalStatus.adopted}
                      />
                    }
                    label="adoptat"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={intermediated}
                        onChange={(e) => onAnimalStatusChange(e)}
                        name="intermediated"
                        value={animalStatus.intermediated}
                      />
                    }
                    label="intermediat"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="animalOtherObs"
                  label="Observaţii"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalOtherObsChange(e)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-animal-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
