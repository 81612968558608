import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import {
  ApolloClient,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';

import { Container, TextField, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MaterialTable from 'material-table';
import { DeleteOutline, EditOutlined, Add } from '@mui/icons-material';

import DateFnsAdapter from '@date-io/date-fns';

import { DatePicker } from '@mui/x-date-pickers';

import { Loading } from '../components/Loading';
import { MainContainer } from '../components/MainContainer';
import { Header } from '../components/Header';
import { FloatingButton } from '../components/FloatingButton';
import { AddAdoptionDialog } from '../components/AddAdoptionDialog';
import { EditAdoptionDialog } from '../components/EditAdoptionDialog';
import { AreYouSureDialog } from '../components/AreYouSureDialog';

import { PatchedPagination } from '../utils/PatchedPagination';

// import { GET_ADOPTIONS } from '../gql/queries/getAdoptions';
import { GET_ANIMALS_ENTERED } from 'src/gql/queries/getAnimalsEntered';
import { GET_ANIMALS_ADOPTED } from '../gql/queries/getAnimalsAdopted';
import { GET_ANIMALS_INTERMEDIATED } from '../gql/queries/getAnimalsIntermediated';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { CREATE_ADOPTION } from '../gql/mutations/CreateAdoption';
import { UPDATE_ADOPTION } from '../gql/mutations/UpdateAdoption';
import { DELETE_ADOPTION } from '../gql/mutations/DeleteAdoption';

interface AdoptionsProps extends RouteComponentProps {
  me?: any;
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
}));

const dateFns = new DateFnsAdapter();

const getColumns: any = ({ me }: any) => [
  {
    title: 'Nr. Crt.',
    field: 'regNumber',
  },
  {
    title: 'Specie',
    field: 'animalSpecies',
    render: ({ animalSpecies }: any) => {
      switch (animalSpecies) {
        case 'dog':
          return 'caine';
        case 'cat':
          return 'pisica';
        case 'other':
          return 'alta specie';
      }
    },
    lookup: {
      dog: 'caine',
      cat: 'pisica',
      other: 'alta specie',
    },
  },
  {
    title: 'Sex',
    field: 'animalSex',
    render: ({ animalSex }: any) => {
      switch (animalSex) {
        case 'male':
          return 'mascul';
        case 'female':
          return 'femela';
      }
    },
    lookup: {
      male: 'mascul',
      female: 'femela',
    },
  },
  {
    title: 'Nume',
    field: 'animalName',
  },
  {
    title: 'Microcip',
    field: 'animalMicrochipStatus',
    render: ({ animalMicrochipStatus }: any) => {
      switch (animalMicrochipStatus) {
        case 'eduxanima':
          return 'Eduxanima';
        case 'other':
          return 'Alt cabinet';
        case 'no':
          return 'Nu';
      }
    },
    lookup: {
      eduxanima: 'Eduxanima',
      other: 'Alt cabinet',
      no: 'Nu',
    },
  },
  {
    title: 'Nr. microcip',
    field: 'animalMicrochipNumber',
  },
  {
    title: 'De unde',
    field: 'animalLocation',
  },
  {
    title: 'Data intrare',
    field: 'animalEnteredDate',
    render: ({ animalEnteredDate }: any) => {
      const newDate = new Date(animalEnteredDate);
      return dateFns.formatByString(newDate, 'dd/MM/yyyy');
    },
  },
  {
    title: 'Data iesire',
    field: 'animalLeaveDate',
    render: ({ animalLeaveDate }: any) => {
      if (animalLeaveDate) {
        const newDate = new Date(animalLeaveDate);
        return dateFns.formatByString(newDate, 'dd/MM/yyyy');
      } else return '';
    },
  },
  // {
  //   title: 'Fişă creată de',
  //   field: 'createdBy.email',
  // },
  // {
  //   title: 'La data de',
  //   field: 'createdAt',
  //   render: ({ createdAt }: any) => {
  //     const newDate = new Date(createdAt);
  //     return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
  //   },
  // },
  // {
  //   title: 'Modificat de',
  //   field: 'updatedBy.email',
  // },
  // {
  //   title: 'La data de',
  //   field: 'lastUpdate',
  //   render: ({ lastUpdate }: any) => {
  //     const newDate = new Date(lastUpdate);
  //     return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
  //   },
  // },
];

const getColumnsAnimalsAdopted: any = ({ me }: any) => [
  {
    title: 'Nr. Crt.',
    field: 'regNumber',
  },
  {
    title: 'Specie',
    field: 'animalSpecies',
    render: ({ animalSpecies }: any) => {
      switch (animalSpecies) {
        case 'dog':
          return 'caine';
        case 'cat':
          return 'pisica';
        case 'other':
          return 'alta specie';
      }
    },
    lookup: {
      dog: 'caine',
      cat: 'pisica',
      other: 'alta specie',
    },
  },
  {
    title: 'Nume',
    field: 'animalName',
  },
  {
    title: 'Microcip',
    field: 'animalMicrochipStatus',
    render: ({ animalMicrochipStatus }: any) => {
      switch (animalMicrochipStatus) {
        case 'eduxanima':
          return 'Eduxanima';
        case 'other':
          return 'Alt cabinet';
        case 'no':
          return 'Nu';
      }
    },
    lookup: {
      eduxanima: 'Eduxanima',
      other: 'Alt cabinet',
      no: 'Nu',
    },
  },
  {
    title: 'Nr. microcip',
    field: 'animalMicrochipNumber',
  },
  {
    title: 'Data iesire',
    field: 'animalLeaveDate',
    render: ({ animalLeaveDate }: any) => {
      const newDate = new Date(animalLeaveDate);
      return dateFns.formatByString(newDate, 'dd/MM/yyyy');
    },
  },
  {
    title: 'Sex',
    field: 'animalSex',
    render: ({ animalSex }: any) => {
      switch (animalSex) {
        case 'male':
          return 'mascul';
        case 'female':
          return 'femela';
      }
    },
    lookup: {
      male: 'mascul',
      female: 'femela',
    },
  },
  {
    title: 'Fisa creata de',
    field: 'createdBy.email',
  },
  {
    title: 'La data de',
    field: 'createdAt',
    render: ({ createdAt }: any) => {
      const newDate = new Date(createdAt);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
  {
    title: 'Modificat de',
    field: 'updatedBy.email',
  },
  {
    title: 'La data de',
    field: 'lastUpdate',
    render: ({ lastUpdate }: any) => {
      const newDate = new Date(lastUpdate);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
];

export const Adoptions: React.FC<AdoptionsProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();
  const classes = useStyles();
  const today = new Date();
  const dateFns = new DateFnsAdapter();
  const newDate = dateFns.formatByString(today, 'yyyy-MM-dd');
  const initialDate = new Date('2021-01-01');
  const monthBeginning = dateFns.formatByString(today, 'yyyy-MM-01');

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedAdoption, setSelectedAdoption] = useState<any>();
  const [startDate, setStartDate] = useState<any>(monthBeginning);
  const [endDate, setEndDate] = useState<any>(newDate);

  const title = 'Eşti sigur că vrei să ştergi această înregistrare?';
  const content =
    'Ştergerea este definitivă şi această înregistrare nu mai poate fi adusă înapoi.';

  const yes = 'Şterge';
  const no = 'Închide';

  const { data, loading, error } = useQuery(GET_ANIMALS_ENTERED, {
    variables: {
      startDate,
      endDate,
    },
  });

  const {
    data: adoptedAnimalsData,
    loading: adoptedAnimalsLoading,
    error: adoptedAnimalsError,
  } = useQuery(GET_ANIMALS_ADOPTED, {
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: intermediatedAnimalsData,
    loading: intermediatedAnimalsLoading,
    error: intermediatedAnimalsError,
  } = useQuery(GET_ANIMALS_INTERMEDIATED, {
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
  });

  function onStartDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setStartDate(newValue);
  }

  function onEndDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setEndDate(newValue);
  }

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function onCloseEditDialog() {
    setOpenEditDialog(false);
  }

  function onCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  function removeAdoptionDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedAdoption(rowData);
    setOpenDeleteDialog(true);
  }

  function editAdoptionDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedAdoption(rowData);
    setOpenEditDialog(true);
  }

  const [
    createAdoption,
    { loading: createAdoptionLoading, error: createAdoptionError },
  ] = useMutation(CREATE_ADOPTION, {
    refetchQueries: [
      {
        query: GET_ANIMALS_ENTERED,
        variables: {
          startDate,
          endDate,
        },
      },
      {
        query: GET_ANIMALS_ADOPTED,
        variables: {
          startDate,
          endDate,
        },
      },
      {
        query: GET_ANIMALS_INTERMEDIATED,
        variables: {
          startDate,
          endDate,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    updateAdoption,
    { loading: updateAdoptionLoading, error: updateAdoptionError },
  ] = useMutation(UPDATE_ADOPTION, {
    refetchQueries: [
      {
        query: GET_ANIMALS_ENTERED,
        variables: {
          startDate,
          endDate,
        },
      },
      {
        query: GET_ANIMALS_ADOPTED,
        variables: {
          startDate,
          endDate,
        },
      },
      {
        query: GET_ANIMALS_INTERMEDIATED,
        variables: {
          startDate,
          endDate,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    deleteAdoption,
    { loading: deleteAdoptionLoading, error: deleteAdoptionError },
  ] = useMutation(DELETE_ADOPTION, {
    refetchQueries: [
      {
        query: GET_ANIMALS_ADOPTED,
        variables: {
          startDate,
          endDate,
        },
      },
      {
        query: GET_ANIMALS_INTERMEDIATED,
        variables: {
          startDate,
          endDate,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  async function onRegisterAdoption(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.regNumber)
        throw new Error('Nr. înregistrare nu este completat');
      if (!variables.animalEnteredDate)
        throw new Error('Data de intrare nu este completată.');
      if (!variables.animalLocation)
        throw new Error('Locaţia nu este completată.');
      if (!variables.animalSpecies)
        throw new Error('Specia animalului nu este completată.');
      if (!variables.animalSex)
        throw new Error('Sexul animalului nu este completat.');
      if (typeof variables.animalMicrochipStatus === 'undefined')
        throw new Error('Microchip nu este completat.');
      if (
        variables.animalMicrochipNumber &&
        (variables.animalMicrochipNumber.length < 15 ||
          variables.animalMicrochipNumber.length > 15)
      ) {
        throw new Error('Numărul microcipului trebuie să aibă 15 caractere.');
      }
      if (
        (variables.animalStatus.adopted ||
          variables.animalStatus.intermediated) &&
        !variables.animalLeaveDate
      )
        throw new Error('Data de ieşire nu este completată');

      await createAdoption({
        variables,
      });

      return onCloseAddDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onEditAdoption(
    event: React.FormEvent<HTMLFormElement>,
    input: any
  ) {
    event.preventDefault();

    try {
      if (!input) throw new Error('Nu există variabile.');
      if (!input.regNumber)
        throw new Error('Nr. înregistrare nu este completat');
      if (!input.animalEnteredDate)
        throw new Error('Data de intrare nu este completată.');
      if (!input.animalLocation) throw new Error('Locaţia nu este completată.');
      if (!input.animalSpecies)
        throw new Error('Specia animalului nu este completată.');
      if (!input.animalSex)
        throw new Error('Sexul animalului nu este completat.');
      if (typeof input.animalMicrochipStatus === 'undefined')
        throw new Error('Microchip nu este completat.');
      if (
        input.animalMicrochipNumber &&
        (input.animalMicrochipNumber.length < 15 ||
          input.animalMicrochipNumber.length > 15)
      ) {
        throw new Error('Numărul microcipului trebuie să aibă 15 caractere.');
      }
      if (
        (input.animalStatus.adopted || input.animalStatus.intermediated) &&
        !input.animalLeaveDate
      )
        throw new Error('Data de ieşire nu este completată');

      const variables: any = { id: selectedAdoption?.id, ...input };
      await updateAdoption({ variables });

      return onCloseEditDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function removeAdoption() {
    const variables: any = { id: selectedAdoption?.id };
    await deleteAdoption({ variables });
    setOpenDeleteDialog(false);
  }

  if (
    loading ||
    adoptedAnimalsLoading ||
    intermediatedAnimalsLoading ||
    createAdoptionLoading ||
    updateAdoptionLoading ||
    deleteAdoptionLoading
  )
    return <Loading />;
  if (
    error ||
    adoptedAnimalsError ||
    intermediatedAnimalsError ||
    createAdoptionError ||
    updateAdoptionError ||
    deleteAdoptionError
  ) {
    return <p>Error</p>;
  }

  const animalsEntered = data?.getAnimalsEntered.map((o: any) => ({
    ...o,
  }));

  const animalsAdopted = adoptedAnimalsData?.getAnimalsAdopted.map(
    (o: any) => ({
      ...o,
    })
  );

  const animalsIntermediated =
    intermediatedAnimalsData?.getAnimalsIntermediated.map((o: any) => ({
      ...o,
    }));

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />
      <Container className={classes.cardGrid} maxWidth="xl">
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="Începând cu"
                value={startDate}
                onChange={onStartDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Până la"
                value={endDate}
                onChange={onEndDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
          </Grid>
        </Container>

        <br />

        <MaterialTable
          title="Animale intrate"
          columns={getColumns({ me })}
          data={animalsEntered}
          components={{
            Pagination: PatchedPagination,
          }}
          options={{
            exportButton: true,
            filtering: true,
          }}
          actions={[
            {
              icon: DeleteOutline,
              tooltip: 'Şterge înregistrarea',
              onClick: (event, rowData) => removeAdoptionDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
            {
              icon: EditOutlined,
              tooltip: 'Editează înregistrarea',
              onClick: (event, rowData) => editAdoptionDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
          ]}
          localization={{
            toolbar: {
              searchPlaceholder: 'Caută',
              searchTooltip: 'Caută',
              exportTitle: 'Exportă',
              exportPDFName: 'Exportă ca PDF',
              exportCSVName: 'Exportă ca CSV',
            },
            pagination: {
              previousTooltip: 'Pagina precedentă',
              nextTooltip: 'Pagina următoare',
              firstTooltip: 'Prima pagină',
              lastTooltip: 'Ultima pagină',
              labelRowsSelect: 'Rânduri',
            },
            header: {
              actions: 'Acţiuni',
            },
          }}
        />
        <br />

        <MaterialTable
          title="Animale adoptate"
          columns={getColumnsAnimalsAdopted({ me })}
          data={animalsAdopted}
          components={{
            Pagination: PatchedPagination,
          }}
          options={{
            exportButton: true,
            filtering: true,
          }}
          actions={[
            {
              icon: DeleteOutline,
              tooltip: 'Şterge înregistrarea',
              onClick: (event, rowData) => removeAdoptionDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
            {
              icon: EditOutlined,
              tooltip: 'Editează înregistrarea',
              onClick: (event, rowData) => editAdoptionDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
          ]}
          localization={{
            toolbar: {
              searchPlaceholder: 'Caută',
              searchTooltip: 'Caută',
              exportTitle: 'Exportă',
              exportPDFName: 'Exportă ca PDF',
              exportCSVName: 'Exportă ca CSV',
            },
            pagination: {
              previousTooltip: 'Pagina precedentă',
              nextTooltip: 'Pagina următoare',
              firstTooltip: 'Prima pagină',
              lastTooltip: 'Ultima pagină',
              labelRowsSelect: 'Rânduri',
            },
            header: {
              actions: 'Acţiuni',
            },
          }}
        />

        <br />

        <MaterialTable
          title="Animale intermediate"
          columns={getColumns({ me })}
          data={animalsIntermediated}
          components={{
            Pagination: PatchedPagination,
          }}
          options={{
            exportButton: true,
            filtering: true,
          }}
          actions={[
            {
              icon: DeleteOutline,
              tooltip: 'Şterge înregistrarea',
              onClick: (event, rowData) => removeAdoptionDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
            {
              icon: EditOutlined,
              tooltip: 'Editează înregistrarea',
              onClick: (event, rowData) => editAdoptionDialog(event, rowData),
              disabled: me.type === 'VIEWER',
            },
          ]}
          localization={{
            toolbar: {
              searchPlaceholder: 'Caută',
              searchTooltip: 'Caută',
              exportTitle: 'Exportă',
              exportPDFName: 'Exportă ca PDF',
              exportCSVName: 'Exportă ca CSV',
            },
            pagination: {
              previousTooltip: 'Pagina precedentă',
              nextTooltip: 'Pagina următoare',
              firstTooltip: 'Prima pagină',
              lastTooltip: 'Ultima pagină',
              labelRowsSelect: 'Rânduri',
            },
            header: {
              actions: 'Acţiuni',
            },
          }}
        />
      </Container>
      {me.type !== 'VIEWER' && (
        <FloatingButton icon={<Add />} onClick={() => openAdd()} />
      )}
      <AddAdoptionDialog
        title={'Fişă animal'}
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={onRegisterAdoption}
      />
      <EditAdoptionDialog
        title={'Editează fişă animal'}
        open={openEditDialog}
        onClose={onCloseEditDialog}
        onSave={onEditAdoption}
        animal={selectedAdoption}
        startDate={startDate}
        endDate={endDate}
      />
      <AreYouSureDialog
        title={title}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        onYes={removeAdoption}
      />
    </MainContainer>
  );
};
