import React from 'react';

import { Menu, MenuItem, Divider, IconButton, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MoreVert } from '@mui/icons-material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

interface FloatingMenuButtonProps {
  me?: any;
  navigate?: any;
}

const theme = createTheme();

const StyledIconButton = styled(IconButton)(() => ({
  position: 'fixed',
  top: 30,
  right: -35,
  transform: 'translate(-50%, -50%)',
  zIndex: 2000,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  backgroundColor: 'rgba(0, 0, 0, 0.87)',
}));

const StyledMenu = styled(Menu)(() => ({
  zIndex: '2050!important' as any,
}));

export const FloatingMenuButton: React.FC<FloatingMenuButtonProps> = ({
  me,
  navigate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  function onMenuOpen(event: any) {
    setAnchorEl(event.currentTarget);
  }
  function onMenuClose() {
    setAnchorEl(null);
  }
  // function onLangClick(lang: string) {
  //   onMenuClose();
  // }
  function onLogout() {
    navigate('/signout');
  }

  function onProfile() {
    navigate('/profile');
    onMenuClose();
  }

  function onChangePassword() {
    navigate('/change-password');
    onMenuClose();
  }

  function onLogin() {
    navigate('/signin');
    onMenuClose();
  }

  // function onRegister() {
  //   navigate('/register');
  //   onMenuClose();
  // }

  const menuId = 'floating-menu';
  const renderMenu = (
    <StyledMenu
      elevation={2}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={() => onMenuClose()}
    >
      {me && [
        <MenuItem key={`profile`} onClick={() => onProfile()}>
          {'Profil'}
        </MenuItem>,
        <MenuItem key={`change-password`} onClick={() => onChangePassword()}>
          {`Schimbă parola`}
        </MenuItem>,
        <MenuItem key={`logout`} onClick={() => onLogout()}>
          {'Deconectează-te'}
        </MenuItem>,
        <Divider key={`divider`} />,
      ]}
      {!me && [
        <MenuItem key={`login`} onClick={() => onLogin()}>
          {'Autentifică-te'}
        </MenuItem>,
        // <MenuItem key={`sign-up`} onClick={() => onRegister()}>
        //   {t('Sign Up')}
        // </MenuItem>,
      ]}
    </StyledMenu>
  );
  const renderMenuButton = (
    <StyledIconButton
      aria-label="floating menu"
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={(e) => onMenuOpen(e)}
      color="inherit"
    >
      <StyledAvatar>
        <MoreVert />
      </StyledAvatar>
    </StyledIconButton>
  );

  return (
    <>
      {renderMenuButton}
      {renderMenu}
    </>
  );
};
