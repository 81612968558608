import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import {
  useQuery,
  ApolloClient,
  useApolloClient,
  useMutation,
} from '@apollo/client';

import { Container, TextField, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  // DeleteOutline,
  // EditOutlined,
  Add,
  Remove,
} from '@mui/icons-material';
import MaterialTable from 'material-table';
import DateFnsAdapter from '@date-io/date-fns';

import { MainContainer } from './MainContainer';
import { Header } from './Header';
import { Loading } from './Loading';
import { AreYouSureDialog } from './AreYouSureDialog';
import { FloatingButton } from './FloatingButton';
// import { AddProductDialog } from './AddProductDialog';
import { EditProductDialog } from './EditProductDialog';
import { InventoryDialog } from './InventoryDialog';

import { PatchedPagination } from '../utils/PatchedPagination';

import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { GET_PRODUCTS } from '../gql/queries/getProducts';
import { GET_INVENTORIES } from '../gql/queries/getInventories';
import { GET_INVENTORY } from '../gql/queries/getInventory';
import { GET_ORDERS } from '../gql/queries/getOrders';
import { GET_INVENTORIES_TOTAL } from '../gql/queries/getInventoriesTotal';
import { CREATE_PRODUCT } from '../gql/mutations/CreateProduct';
import { CREATE_INVENTORY } from '../gql/mutations/CreateInventory';
import { DELETE_INVENTORY } from '../gql/mutations/DeleteInventory';
import { CREATE_ORDER } from '../gql/mutations/CreateOrder';
import { DELETE_ORDER } from '../gql/mutations/DeleteOrder';
import { UPDATE_PRODUCT } from '../gql/mutations/UpdateProduct';
import { DELETE_PRODUCT } from '../gql/mutations/DeleteProduct';

interface InventoryTableProps extends RouteComponentProps {
  me?: any;
}

// const useStyles = makeStyles((theme) => ({
//   cardGrid: {
//     backgroundColor: theme.palette.background.paper,
//     paddingTop: theme.spacing(0),
//     paddingBottom: theme.spacing(8),
//   },
// }));

const dateFns = new DateFnsAdapter();

const getColumns: any = ({ me }: any) => [
  {
    title: 'Denumire produs',
    field: 'product.name',
  },
  {
    title: 'U.M.',
    field: 'product.uom',
    render: ({ product }: any) => {
      switch (product.uom) {
        case 'DROPPER':
          return 'Pipetă';
        case 'TABLET':
          return 'Tabletă';
        case 'VIAL':
          return 'Flacon';
        case 'PIECE':
          return 'Bucată';
        case 'AMPOULE':
          return 'Fiolă';
        case 'DOSE':
          return 'Doză';
      }
    },
  },
  {
    title: 'Cantitate',
    field: 'quantity',
  },
  {
    title: 'Furnizor',
    field: 'product.supplier.name',
  },
  {
    title: 'Data',
    field: 'date',
    render: ({ date }: any) => {
      const newDate = new Date(date);
      return dateFns.formatByString(newDate, 'dd/MM/yyyy');
    },
  },
  {
    title: 'Nr. factură',
    field: 'invoiceNumber',
  },
  {
    title: 'Detalii',
    field: 'details',
  },
  {
    title: 'Creat de',
    field: 'createdBy.email',
  },
  {
    title: 'La data de',
    field: 'createdAt',
    render: ({ createdAt }: any) => {
      const newDate = new Date(createdAt);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
  // {
  //   title: 'Modificat de',
  //   field: 'updatedBy.email',
  // },
  // {
  //   title: 'La data de',
  //   field: 'lastUpdate',
  //   render: ({ lastUpdate }: any) => {
  //     const newDate = new Date(lastUpdate);
  //     return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
  //   },
  // },
];

const getColumnsTotal: any = ({ me }: any) => [
  {
    title: 'Denumire produs',
    field: 'product.name',
  },
  {
    title: 'U.M.',
    field: 'product.uom',
    render: ({ product }: any) => {
      switch (product.uom) {
        case 'DROPPER':
          return 'Pipetă';
        case 'TABLET':
          return 'Tabletă';
        case 'VIAL':
          return 'Flacon';
        case 'PIECE':
          return 'Bucată';
        case 'AMPOULE':
          return 'Fiolă';
        case 'DOSE':
          return 'Doză';
      }
    },
  },
  {
    title: 'Cantitate totală',
    field: 'totalQuantity',
  },
  {
    title: 'Furnizor',
    field: 'product.supplier.name',
  },
];

// const getOrdersColumns: any = ({ me }: any) => [
//   {
//     title: 'Denumire produs',
//     field: 'product.name',
//   },
//   {
//     title: 'U.M.',
//     field: 'product.uom',
//     render: ({ product }: any) => {
//       switch (product.uom) {
//         case 'DROPPER':
//           return 'Pipetă';
//         case 'TABLET':
//           return 'Tabletă';
//         case 'VIAL':
//           return 'Flacon';
//         case 'PIECE':
//           return 'Bucată';
//         case 'AMPOULE':
//           return 'Fiolă';
//         case 'DOSE':
//           return 'Doză';
//       }
//     },
//   },
//   {
//     title: 'Cantitate',
//     field: 'quantity',
//   },
//   {
//     title: 'Furnizor',
//     field: 'product.supplier.name',
//   },
//   {
//     title: 'Data',
//     field: 'date',
//     render: ({ date }: any) => {
//       const newDate = new Date(date);
//       return dateFns.formatByString(newDate, 'dd/MM/yyyy');
//     },
//   },
//   {
//     title: 'Detalii',
//     field: 'details',
//   },
//   {
//     title: 'Creat de',
//     field: 'createdBy.email',
//   },
//   {
//     title: 'La data de',
//     field: 'createdAt',
//     render: ({ createdAt }: any) => {
//       const newDate = new Date(createdAt);
//       return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
//     },
//   },
// {
//   title: 'Modificat de',
//   field: 'updatedBy.email',
// },
// {
//   title: 'La data de',
//   field: 'lastUpdate',
//   render: ({ lastUpdate }: any) => {
//     const newDate = new Date(lastUpdate);
//     return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
//   },
// },
// ];
export const InventoryTable: React.FC<InventoryTableProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();
  // const classes = useStyles();
  const today = new Date();
  const dateFns = new DateFnsAdapter();
  const newDate = dateFns.formatByString(today, 'yyyy-MM-dd');
  const monthBeginning = dateFns.formatByString(today, 'yyyy-MM-01');

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openRemoveFromInventoryDialog, setOpenRemoveFromInventoryDialog] =
    useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openDeleteInventoryDialog, setOpenDeleteInventoryDialog] =
    useState<boolean>(false);
  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] =
    useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedInventory, setSelectedInventory] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [startDate, setStartDate] = useState<any>(monthBeginning);
  const [endDate, setEndDate] = useState<any>(newDate);
  // const [inventoryExists, setInventoryExists] = useState<any>();

  const title = 'Eşti sigur că vrei să ştergi această înregistrare?';
  const content =
    'Ştergerea este definitivă şi această înregistrare nu mai poate fi adusă înapoi.';

  const yes = 'Şterge';
  const no = 'Închide';

  const { data, loading, error } = useQuery(GET_INVENTORIES, {
    variables: {
      startDate,
      endDate,
    },
  });

  const {
    data: inventoriesTotal,
    loading: inventoriesTotalLoading,
    error: inventoriesTotalError,
  } = useQuery(GET_INVENTORIES_TOTAL, {
    fetchPolicy: 'network-only',
  });

  const {
    data: ordersData,
    loading: ordersDataLoading,
    error: ordersDataError,
  } = useQuery(GET_ORDERS, {
    variables: {
      startDate,
      endDate,
    },
  });

  function onStartDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setStartDate(value);
  }

  function onEndDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setEndDate(value);
  }

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function openRemove() {
    setOpenRemoveFromInventoryDialog(true);
  }

  function onCloseRemoveFromInventoryDialog() {
    setOpenRemoveFromInventoryDialog(false);
  }

  function removeInventory(event: any, rowData: any) {
    event.preventDefault();

    setSelectedInventory(rowData);
    setOpenDeleteInventoryDialog(true);
  }

  function removeOrder(event: any, rowData: any) {
    event.preventDefault();

    setSelectedOrder(rowData);
    setOpenDeleteOrderDialog(true);
  }

  function editSupplierDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedProduct(rowData);
    setOpenEditDialog(true);
  }

  function onCloseEditDialog() {
    setOpenEditDialog(false);
  }

  // function onCloseDeleteDialog() {
  //   setOpenDeleteDialog(false);
  // }

  function onCloseDeleteInventoryDialog() {
    setOpenDeleteInventoryDialog(false);
  }

  function onCloseDeleteOrderDialog() {
    setOpenDeleteOrderDialog(false);
  }

  const [
    createProduct,
    { loading: createProductLoading, error: createProductError },
  ] = useMutation(CREATE_PRODUCT, {
    update(cache, { data: { createSupplier } }: any) {
      const { getProducts }: any = cache.readQuery({
        query: GET_PRODUCTS,
      });

      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          getProducts: [...getProducts, createSupplier],
        },
      });
    },
  });

  const [
    updateProduct,
    { loading: updateProductLoading, error: updateProductError },
  ] = useMutation(UPDATE_PRODUCT, {
    update(cache, { data: { updateProduct } }: any) {
      const { getProducts }: any = cache.readQuery({
        query: GET_PRODUCTS,
      });
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          getProducts: [
            ...getProducts.filter(
              (product: any) => product.id !== updateProduct.id
            ),
            updateProduct,
          ],
        },
      });
    },
  });

  const [
    deleteProduct,
    { loading: deleteProductLoading, error: deleteProductError },
  ] = useMutation(DELETE_PRODUCT, {
    update(cache, { data: deleteSupplier }: any) {
      const { getProducts }: any = cache.readQuery({
        query: GET_PRODUCTS,
      });
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          getProducts: getProducts.filter(
            (product: any) => product.id !== selectedProduct?.id
          ),
        },
      });
    },
  });

  function GetInventory({ productId }: any) {
    const {
      data: inventoryData,
      loading: inventoryDataLoading,
      error: inventoryDataError,
    } = useQuery(GET_INVENTORY, {
      variables: {
        productId,
      },
    });

    // setInventoryExists(inventoryData);
  }

  const [
    createInventory,
    { loading: createInventoryLoading, error: createInventoryError },
  ] = useMutation(CREATE_INVENTORY, {
    update(cache, { data: { createInventory } }: any) {
      const { getInventories }: any = cache.readQuery({
        query: GET_INVENTORIES,
        variables: {
          startDate,
          endDate,
        },
      });

      cache.writeQuery({
        query: GET_INVENTORIES,
        variables: {
          startDate,
          endDate,
        },
        data: {
          getInventories: [...getInventories, createInventory],
        },
      });
    },
    refetchQueries: [
      {
        query: GET_INVENTORIES_TOTAL,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    deleteInventory,
    { loading: deleteInventoryLoading, error: deleteInventoryError },
  ] = useMutation(DELETE_INVENTORY, {
    update(cache, { data: deleteInventory }: any) {
      const { getInventories }: any = cache.readQuery({
        query: GET_INVENTORIES,
      });
      cache.writeQuery({
        query: GET_INVENTORIES,
        data: {
          getInventories: getInventories.filter(
            (inventory: any) => inventory.id !== selectedInventory?.id
          ),
        },
      });
    },
  });

  const [
    createOrder,
    { loading: createOrderLoading, error: createOrderError },
  ] = useMutation(CREATE_ORDER, {
    update(cache, { data: { createOrder } }: any) {
      const { getOrders }: any = cache.readQuery({
        query: GET_ORDERS,
        variables: {
          startDate,
          endDate,
        },
      });

      cache.writeQuery({
        query: GET_ORDERS,
        variables: {
          startDate,
          endDate,
        },
        data: {
          getOrders: [...getOrders, createOrder],
        },
      });
    },
    refetchQueries: [
      {
        query: GET_INVENTORIES_TOTAL,
      },
    ],
    awaitRefetchQueries: true,
  });

  const [
    deleteOrder,
    { loading: deleteOrderLoading, error: deleteOrderError },
  ] = useMutation(DELETE_ORDER, {
    update(cache, { data: deleteOrder }: any) {
      const { getOrders }: any = cache.readQuery({
        query: GET_ORDERS,
      });
      cache.writeQuery({
        query: GET_ORDERS,
        data: {
          getOrders: getOrders.filter(
            (order: any) => order.id !== selectedOrder?.id
          ),
        },
      });
    },
  });

  async function registerInventory(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.productId)
        throw new Error('Medicamentul nu este completat.');
      if (!variables.date) throw new Error('Data nu este completată.');
      if (!variables.quantity)
        throw new Error('Cantitatea nu este completată.');

      await createInventory({ variables });

      return onCloseAddDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function registerOrder(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');

      const { productId, date, quantity } = variables;

      if (!productId) throw new Error('Medicamentul nu este completat.');
      if (!date) throw new Error('Data nu este completată.');
      if (!quantity) throw new Error('Cantitatea nu este completată.');

      await createOrder({ variables });

      return onCloseRemoveFromInventoryDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onEditProduct(
    event: React.FormEvent<HTMLFormElement>,
    input: any
  ) {
    event.preventDefault();
    try {
      if (!input) throw new Error('Nu există variabile.');
      if (!input.name)
        throw new Error('Numele furnizorului nu este completat.');

      const variables: any = {
        id: selectedProduct?.id,
        ...input,
      };

      await updateProduct({ variables });

      return onCloseEditDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onRemoveProduct() {
    const variables: any = { id: selectedProduct?.id };
    await deleteProduct({ variables });
    setOpenDeleteDialog(false);
  }

  async function onRemoveInventory() {
    const variables: any = { id: selectedInventory?.id };
    await deleteInventory({ variables });
    setOpenDeleteInventoryDialog(false);
  }

  async function onRemoveOrder() {
    const variables: any = { id: selectedOrder?.id };
    await deleteOrder({ variables });
    setOpenDeleteOrderDialog(false);
  }

  if (
    loading ||
    createInventoryLoading ||
    deleteInventoryLoading ||
    createProductLoading ||
    updateProductLoading ||
    deleteProductLoading ||
    inventoriesTotalLoading ||
    createOrderLoading ||
    deleteOrderLoading ||
    ordersDataLoading
  )
    return <Loading />;
  if (
    error ||
    createInventoryError ||
    deleteInventoryError ||
    createProductError ||
    updateProductError ||
    deleteProductError ||
    createOrderError ||
    deleteOrderError ||
    inventoriesTotalError ||
    ordersDataError
  )
    return <p>Error</p>;

  const inventories = data?.getInventories.map((o: any) => ({
    ...o,
  }));

  const inventoriesTotalData = inventoriesTotal.getInventoriesTotal.map(
    (o: any) => ({
      ...o,
    })
  );

  const orders = ordersData?.getOrders.map((o: any) => ({
    ...o,
  }));

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />

      <MaterialTable
        title={`Inventar`}
        columns={getColumnsTotal({ me })}
        data={inventoriesTotalData}
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.totalQuantity < 10
                ? rowData.totalQuantity < 5
                  ? rowData.totalQuantity === 0
                    ? '#ff0000'
                    : '#e15520'
                  : '#fcff3d'
                : '',
          }),
          exportButton: true,
          filtering: true,
        }}
      />
      <br />

      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="startDate"
              label="Începând cu"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              value={startDate}
              onChange={(e) => onStartDateChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="endDate"
              label="Până la"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              value={endDate}
              onChange={(e) => onEndDateChange(e)}
            />
          </Grid>
        </Grid>
      </Container>
      <br />

      <MaterialTable
        title={`Adăugate în Inventar`}
        columns={getColumns({ me })}
        data={inventories}
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          exportButton: true,
          filtering: true,
        }}
        // actions={[
        //   {
        //     icon: DeleteOutline,
        //     tooltip: 'Şterge înregistrarea',
        //     onClick: (event, rowData) => removeInventory(event, rowData),
        //     disabled: me.type !== ('ADMIN' || 'SUPERADMIN'),
        //   },
        //   {
        //     icon: EditOutlined,
        //     tooltip: 'Editează înregistrarea',
        //     onClick: (event, rowData) => editSupplierDialog(event, rowData),
        //     disabled: me.type === 'VIEWER',
        //   },
        // ]}
      />
      <br />

      <MaterialTable
        title={`Scoase din Inventar`}
        columns={getColumns({ me })}
        data={orders}
        components={{
          Pagination: PatchedPagination,
        }}
        options={{
          exportButton: true,
          filtering: true,
        }}
        // actions={[
        //   {
        //     icon: DeleteOutline,
        //     tooltip: 'Şterge înregistrarea',
        //     onClick: (event, rowData) => removeOrder(event, rowData),
        //     disabled: me.type !== ('ADMIN' || 'SUPERADMIN'),
        //   },
        //   {
        //     icon: EditOutlined,
        //     tooltip: 'Editează înregistrarea',
        //     onClick: (event, rowData) => editSupplierDialog(event, rowData),
        //     disabled: me.type === 'VIEWER',
        //   },
        // ]}
      />

      <FloatingButton icon={<Add />} onClick={() => openAdd()} />
      <FloatingButton
        isRemove={true}
        icon={<Remove />}
        onClick={() => openRemove()}
      />
      <InventoryDialog
        title="Adaugă în inventar"
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={registerInventory}
        added={true}
      />
      <InventoryDialog
        title="Scoate din inventar"
        open={openRemoveFromInventoryDialog}
        onClose={onCloseRemoveFromInventoryDialog}
        onSave={registerOrder}
        added={false}
      />
      <EditProductDialog
        title="Editează inventarierea"
        open={openEditDialog}
        onClose={onCloseEditDialog}
        onSave={onEditProduct}
        product={selectedProduct}
      />
      <AreYouSureDialog
        title={title}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteInventoryDialog}
        onClose={onCloseDeleteInventoryDialog}
        onYes={onRemoveInventory}
      />
      <AreYouSureDialog
        title={title}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteOrderDialog}
        onClose={onCloseDeleteOrderDialog}
        onYes={onRemoveOrder}
      />
    </MainContainer>
  );
};
