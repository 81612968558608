import { TablePagination, TablePaginationProps } from '@mui/material';

export function PatchedPagination(props: TablePaginationProps) {
  const {
    // ActionsComponent,
    onPageChange,
    onRowsPerPageChange,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      // ActionsComponent={(subprops) => {
      //   const { onPageChange, ...actionsComponentProps } = subprops;
      //   return (
      //     <ActionsComponent
      //       {...actionsComponentProps}
      //       onPageChange={onPageChange}
      //     />
      //   );
      // }}
    />
  );
}
