import { gql } from '@apollo/client';

export const GET_STERILIZED_ANIMALS = gql`
  query GetSterilizedAnimals(
    $startDate: String
    $endDate: String
    $location: String
  ) {
    getSterilizedAnimals(
      input: { startDate: $startDate, endDate: $endDate, location: $location }
    ) {
      animalSpecies
      animalSex
      animalOwnerStatus
      fieldName
      total
    }
  }
`;
