import type React from 'react';

import { Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert as MuiAlert } from '@mui/lab';

interface AlertProps {
  open: boolean;
  onClose: any;
  children: any;
  severity?: any;
}

const useStyles = makeStyles(() => ({
  alertAction: {
    alignItems: 'start',
  },
}));

export const Alert: React.FC<AlertProps> = ({
  open,
  onClose,
  children,
  severity = 'error',
}) => {
  const classes = useStyles();

  if (!open) return <></>;

  return (
    <Snackbar open={open} onClose={onClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={severity}
        onClose={onClose}
        classes={{
          action: classes.alertAction,
        }}
      >
        {children}
      </MuiAlert>
    </Snackbar>
  );
};

export {};
