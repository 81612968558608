import React, { useEffect } from 'react';
import { Container, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
  Legend,
  Font,
  Title,
  Subtitle,
} from 'devextreme-react/pie-chart';
import 'devextreme/dist/css/dx.light.css';

const useStyles = makeStyles(() => ({
  pieChart: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0',
  },
}));

interface AnimalsResultsChartProps {
  animals: any;
  title: any;
  subtitle?: any;
  maxWidth: any;
}

export const AnimalsResultsChart: React.FC<AnimalsResultsChartProps> = ({
  animals,
  title,
  subtitle,
  maxWidth,
}) => {
  useEffect(() => {}, [animals]);
  const classes = useStyles();

  function formatText(arg: any) {
    switch (arg.argumentText) {
      case 'ownedFemaleDogs':
        return `Owned Female Dogs - ${arg.value}`;
      case 'strayFemaleDogs':
        return `Stray Female Dogs - ${arg.value}`;
      case 'ownedMaleDogs':
        return `Owned Male Dogs - ${arg.value}`;
      case 'strayMaleDogs':
        return `Stray Male Dogs - ${arg.value}`;
      case 'ownedFemaleCats':
        return `Owned Female Cats - ${arg.value}`;
      case 'strayFemaleCats':
        return `Stray Female Cats - ${arg.value}`;
      case 'ownedMaleCats':
        return `Owned Male Cats - ${arg.value}`;
      case 'strayMaleCats':
        return `Stray Male Cats - ${arg.value}`;
    }
  }

  function customText(arg: any) {
    switch (arg.pointName) {
      case 'ownedFemaleDogs':
        return 'Owned Female Dogs';
      case 'strayFemaleDogs':
        return 'Stray Female Dogs';
      case 'ownedMaleDogs':
        return 'Owned Male Dogs';
      case 'strayMaleDogs':
        return 'Stray Male Dogs';
      case 'ownedFemaleCats':
        return 'Owned Female Cats';
      case 'strayFemaleCats':
        return 'Stray Female Cats';
      case 'ownedMaleCats':
        return 'Owned Male Cats';
      case 'strayMaleCats':
        return 'Stray Male Cats';
    }
  }

  return (
    <Container maxWidth={maxWidth}>
      <Paper elevation={10} className={classes.pieChart}>
        <PieChart id="pie" dataSource={animals} palette="Bright">
          <Title text={title}>
            <Subtitle text={subtitle} />
          </Title>
          <Legend
            customizeText={customText}
            orientation="horizontal"
            itemTextPosition="right"
            horizontalAlignment="center"
            verticalAlignment="bottom"
            columnCount={4}
          />
          <Series argumentField="fieldName" valueField="total">
            <Label visible={true} customizeText={formatText}>
              <Font size={16} />
              <Connector visible={true} width={1} />
            </Label>
          </Series>

          <Size width={500} />
          <Export enabled={true} />
        </PieChart>
      </Paper>
    </Container>
  );
};
