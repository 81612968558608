import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@mui/material';

interface AddSupplierDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
}

export const AddSupplierDialog: React.FC<AddSupplierDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [location, setLocation] = useState<any>();
  const [phone, setPhone] = useState<any>();

  function onNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setName(value);
  }
  function onDescriptionChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setDescription(value);
  }

  function onLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setLocation(value);
  }
  function onPhoneChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setPhone(value);
  }

  const variables = {
    name,
    description,
    location: {
      location,
    },
    phone,
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="xs"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <form
            noValidate
            id="register-supplier-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="name"
                  label="Nume furnizor"
                  fullWidth
                  autoFocus
                  onChange={(e) => onNameChange(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="description"
                  label="Descriere"
                  fullWidth
                  autoFocus
                  onChange={(e) => onDescriptionChange(e)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="location"
                  label="Locaţie"
                  fullWidth
                  autoFocus
                  onChange={(e) => onLocationChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="phone"
                  label="Telefon"
                  fullWidth
                  autoFocus
                  onChange={(e) => onPhoneChange(e)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-supplier-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
