import type React from 'react';

import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface MainContainerProps {
  children: any;
  maxWidth?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(16),
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
  },
}));

export const MainContainer: React.FC<MainContainerProps> = ({
  children,
  maxWidth = 'md',
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth={maxWidth} className={classes.root} disableGutters>
      {children}
    </Container>
  );
};
