import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import {
  ApolloClient,
  useApolloClient,
  useQuery,
  useMutation,
} from '@apollo/client';

import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EditOutlined, DeleteOutline, Add } from '@mui/icons-material';
import MaterialTable from 'material-table';
import isEmail from 'validator/lib/isEmail';

import { Loading } from '../components/Loading';
import { MainContainer } from '../components/MainContainer';
import { Header } from '../components/Header';
import { OutlinedTextField } from '../components/OutlinedTextField';
import { FloatingButton } from '../components/FloatingButton';
import { AddUserDialog } from '../components/AddUserDialog';
import { EditUserDialog } from '../components/EditUserDialog';
import { AreYouSureDialog } from '../components/AreYouSureDialog';

import { PatchedPagination } from '../utils/PatchedPagination';

import { GET_ALL_USERS } from '../gql/queries/getAllUsers';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { CREATE_USER } from '../gql/mutations/CreateUser';
import { UPDATE_USER } from '../gql/mutations/UpdateUser';
import { DELETE_USER } from '../gql/mutations/DeleteUser';

interface AllUsersProps extends RouteComponentProps {
  me?: any;
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
}));

const getColumns: any = ({ me }: any) => [
  {
    title: 'Email',
    field: 'email',
    editComponent: (props: any) => <OutlinedTextField {...props} />,
  },
  {
    title: 'Prenume',
    field: 'firstName',
    editComponent: (props: any) => <OutlinedTextField {...props} />,
  },
  {
    title: 'Nume',
    field: 'lastName',
    editComponent: (props: any) => <OutlinedTextField {...props} />,
  },
  {
    title: 'Funcţie utilizator',
    field: 'type',
    render: ({ type }: any) => {
      switch (type) {
        case 'SUPERADMIN':
          return 'Superadministrator';
        case 'ADMIN':
          return 'Administrator';
        case 'EDITOR':
          return 'Editor';
        case 'VIEWER':
          return 'Privitor';
      }
    },
    editComponent: (props: any) => <OutlinedTextField {...props} />,
  },
];

export const AllUsers: React.FC<AllUsersProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();
  const classes = useStyles();
  const content =
    'Ştergerea este definitivă şi această înregistrare nu mai poate fi adusă înapoi.';

  const yes = 'Şterge';
  const no = 'Închide';

  const [selectedUser, setSelectedUser] = useState<any>();
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { data, loading, error } = useQuery(GET_ALL_USERS);

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function onCloseEditDialog() {
    setOpenEditDialog(false);
  }

  function onCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  function editUserDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedUser(rowData);
    setOpenEditDialog(true);
  }

  function removeUserDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedUser(rowData);
    setOpenDeleteDialog(true);
  }

  const [createUser, { loading: createUserLoading, error: createUserError }] =
    useMutation(CREATE_USER, {
      update(cache, { data: { createUser } }: any) {
        const { getAllUsers }: any = cache.readQuery({
          query: GET_ALL_USERS,
        });
        cache.writeQuery({
          query: GET_ALL_USERS,
          data: {
            getAllUsers: [...getAllUsers, createUser],
          },
        });
      },
    });

  const [updateUser, { loading: updateUserLoading, error: updateUserError }] =
    useMutation(UPDATE_USER);

  const [deleteUser, { loading: deleteUserLoading, error: deleteUserError }] =
    useMutation(DELETE_USER, {
      update(cache, { data: deleteUser }: any) {
        const { getAllUsers }: any = cache.readQuery({ query: GET_ALL_USERS });
        cache.writeQuery({
          query: GET_ALL_USERS,
          data: {
            getAllUsers: getAllUsers.filter(
              (user: any) => user.id !== selectedUser?.id
            ),
          },
        });
      },
    });

  async function onRegisterUser(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables.email) {
        throw new Error('Adresa de email lipseşte');
      }
      if (!isEmail(variables.email)) {
        throw new Error('Formatul adresei de email este incorect.');
      }
      if (!variables.password) {
        throw new Error('Parola lipseşte');
      }
      if (!variables.confirmPassword) {
        throw new Error('Confirmarea parolei lipseşte.');
      }
      if (variables.password !== variables.confirmPassword) {
        throw new Error('Parolele nu se potrivesc.');
      }
      if (!variables.type) {
        throw new Error('Funcţia utilizatorului lipseşte.');
      }

      const { email, password, type, firstName, lastName, location, phone } =
        variables;

      const createUserVariables = {
        email,
        password,
        type,
        firstName,
        lastName,
        location,
        phone,
      };

      await createUser({
        variables: createUserVariables,
      });

      return onCloseAddDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onEditUser(
    event: React.FormEvent<HTMLFormElement>,
    input: any
  ) {
    event.preventDefault();

    try {
      if (!input.email) {
        throw new Error('Adresa de email lipseşte');
      }
      if (!isEmail(input.email)) {
        throw new Error('Formatul adresei de email este incorect.');
      }

      if (!input.type) {
        throw new Error('Funcţia utilizatorului lipseşte.');
      }

      const variables = { id: selectedUser?.id, ...input };

      await updateUser({
        variables,
      });

      return onCloseEditDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function removeUser() {
    const variables: any = { id: selectedUser?.id };
    await deleteUser({ variables });

    return setOpenDeleteDialog(false);
  }

  if (loading || createUserLoading || updateUserLoading || deleteUserLoading)
    return <Loading />;
  if (error || createUserError || updateUserError || deleteUserError)
    return <p>Error</p>;

  const allUsers = data?.getAllUsers.map((o: any) => {
    return { ...o };
  });

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />
      <Container className={classes.cardGrid} maxWidth="xl">
        <MaterialTable
          title="Utilizatori"
          columns={getColumns({ me })}
          data={allUsers}
          components={{
            Pagination: PatchedPagination,
          }}
          options={{
            exportButton: true,
            filtering: true,
          }}
          actions={[
            (rowData: any) => ({
              icon: DeleteOutline,
              tooltip: 'Şterge înregistrarea',
              onClick: (event, rowData) => removeUserDialog(event, rowData),
              disabled: rowData.type === 'SUPERADMIN',
            }),
            (rowData: any) => ({
              icon: EditOutlined,
              tooltip: 'Editează înregistrarea',
              onClick: (event, rowData) => editUserDialog(event, rowData),
              disabled: rowData.type === 'SUPERADMIN',
            }),
          ]}
          localization={{
            toolbar: {
              searchPlaceholder: 'Caută',
              searchTooltip: 'Caută',
              exportTitle: 'Exportă',
              exportPDFName: 'Exportă ca PDF',
              exportCSVName: 'Exportă ca CSV',
            },
            pagination: {
              previousTooltip: 'Pagina precedentă',
              nextTooltip: 'Pagina următoare',
              firstTooltip: 'Prima pagină',
              lastTooltip: 'Ultima pagină',
              labelRowsSelect: 'Rânduri',
            },
            header: {
              actions: 'Acţiuni',
            },
          }}
        />
      </Container>
      <FloatingButton icon={<Add />} onClick={() => openAdd()} />
      <AddUserDialog
        title={'Adaugă un utilizator nou'}
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={onRegisterUser}
      />
      <EditUserDialog
        title={'editeaza utilizatorul'}
        open={openEditDialog}
        onClose={onCloseEditDialog}
        onSave={onEditUser}
        user={selectedUser}
      />
      <AreYouSureDialog
        title={`Eşti sigur că vrei să ştergi utilizatorul ${selectedUser?.email}?`}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        onYes={removeUser}
      />
    </MainContainer>
  );
};
