import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  // gql,
  createHttpLink,
  // NormalizedCacheObject,
  useApolloClient,
  ApolloLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';

import DateFnsUtils from '@date-io/date-fns';

import { resolvers, typeDefs } from './resolvers';

import { IS_USER_LOGGED_IN } from './gql/queries/IsUserLoggedIn';

import { GuestPages, UserPages } from './pages';

// import { Nav } from './components/Nav';
import { Alert } from './components/Alert';
import { Loading } from './components/Loading';
import { Action } from './components/Nav';

import { ANY_ALERT_AVAILABLE } from './gql/queries/anyAlertAvailable';
import { NAVIGATION_ACTION } from './gql/queries/navigationAction';

import 'devextreme/dist/css/dx.light.css';

import type { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const SNOWPACK_PUBLIC_APOLLO_GRAPH_URI =
  process.env.NODE_ENV === 'production'
    ? 'https://graph.eduxanima.ro/graphql'
    : 'http://localhost:4000/graphql';

const cache = new InMemoryCache({
  possibleTypes: {
    User: ['AdminUser', 'EditorUser'],
  },
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        feed: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

// TODO: Monkey-patching in a fix for an open issue suggesting that
// `readQuery` should return null or undefined if the query is not yet in the
// cache: https://github.com/apollographql/apollo-feature-requests/issues/1
// @ts-ignore
cache.originalReadQuery = cache.readQuery;
cache.readQuery = (...args) => {
  try {
    // @ts-ignore
    return cache.originalReadQuery(...args);
  } catch (err: any) {
    return undefined;
  }
};

const urlParams = new URLSearchParams(window.location.search);
const qsToken = urlParams.get('token');
const qsType = urlParams.get('type');

if (qsToken && qsType) {
  localStorage.setItem('token', qsToken);
  localStorage.setItem('type', qsType.toUpperCase());
}

const httpLink = createHttpLink({
  uri: SNOWPACK_PUBLIC_APOLLO_GRAPH_URI,
});

const uploadLink = createUploadLink({
  uri: SNOWPACK_PUBLIC_APOLLO_GRAPH_URI,
});

// const authLink = setContext((_, { headers }) => {
//   const token = localStorage.getItem('token');

//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `${token}` : '',
//     },
//   };
// });

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  const token = localStorage.getItem('token');

  operation.setContext({
    headers: {
      authorization: token ? `${token}` : '',
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const link = ApolloLink.from([authLink, uploadLink, httpLink]);

const client = new ApolloClient({
  cache,
  link: link,
  // link: uploadLink,
  resolvers,
  typeDefs,
});

cache.writeQuery({
  query: IS_USER_LOGGED_IN,
  data: {
    isLoggedIn: !!localStorage.getItem('token'),
    type: localStorage.getItem('type'),
  },
});

cache.writeQuery({
  query: ANY_ALERT_AVAILABLE,
  data: {
    alertOpen: false,
    alertSeverity: 'succcess',
    alertMessage: '',
  },
});

let navigationAction;

if (window.location.pathname.startsWith(Action.Home))
  navigationAction = Action.Home;
else if (window.location.pathname.startsWith(Action.AllAnimals))
  navigationAction = Action.AllAnimals;

cache.writeQuery({
  query: NAVIGATION_ACTION,
  data: {
    navigationAction,
  },
});

function IsUserLoggedIn() {
  const { data } = useQuery(IS_USER_LOGGED_IN);

  return data?.isLoggedIn ? <UserPages /> : <GuestPages />;
}

function AnyAlertAvailable() {
  const client: ApolloClient<any> = useApolloClient();
  const { data } = useQuery(ANY_ALERT_AVAILABLE);

  function onClose() {
    client.writeQuery({
      query: ANY_ALERT_AVAILABLE,
      data: {
        alertOpen: false,
      },
    });
  }

  return (
    (data.alertOpen && (
      <Alert
        open={data.alertOpen}
        onClose={() => onClose()}
        severity={data.alertSeverity}
      >
        {data.alertMessage || ''}
      </Alert>
    )) || <></>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#24297a',
    },
    secondary: {
      main: '#e15520',
    },
  },
  // overrides: {
  //   MuiBottomNavigation: {
  //     root: {
  //       backgroundColor: 'transparent',
  //     },
  //   },
  //   MuiCard: {
  //     root: {
  //       height: '100%',
  //       display: 'flex',
  //       flexDirection: 'column',
  //     },
  //   },
  //   MuiCardMedia: {
  //     root: {
  //       paddingTop: '56.25%',
  //       backgroundColor: '#000',
  //     },
  //   },
  //   MuiCardContent: {
  //     root: {
  //       flexGrow: 1,
  //     },
  //   },
  // },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loading />}>
          <React.StrictMode>
            <IsUserLoggedIn />
          </React.StrictMode>
        </Suspense>
        <AnyAlertAvailable />
      </ThemeProvider>
    </LocalizationProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
