import { gql } from '@apollo/client';

export const CREATE_NOT_REGISTERED_ANIMALS = gql`
  mutation createNotRegisteredAnimals(
    $sheetDate: String!
    $animalSex: String!
    $animalSpecies: String!
    $animalTreatmentStatus: Boolean
    $animalSurgeryStatus: Boolean
    $animalSterilization: String
    $animalOwnerStatus: Boolean
    $fieldName: String
    $total: Int
  ) {
    createNotRegisteredAnimals(
      input: {
        sheetDate: $sheetDate
        animalSex: $animalSex
        animalSpecies: $animalSpecies
        animalTreatmentStatus: $animalTreatmentStatus
        animalSurgeryStatus: $animalSurgeryStatus
        animalSterilization: $animalSterilization
        animalOwnerStatus: $animalOwnerStatus
        fieldName: $fieldName
        total: $total
      }
    ) {
      id
      sheetDate
      animalSex
      animalSpecies
      animalTreatmentStatus
      animalSurgeryStatus
      animalSterilization
      animalOwnerStatus
      fieldName
      total
    }
  }
`;
