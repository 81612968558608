import React, { useState, useEffect } from 'react';
import type { RouteComponentProps } from '@reach/router';
import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';

import { Container, Grid, TextField, Paper, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MainContainer } from '../components/MainContainer';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';

import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { CHANGE_PASSWORD } from '../gql/mutations/ChangeUserPassword';

interface ChangePasswordProps extends RouteComponentProps {
  me: any;
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const ChangePassword: React.FC<ChangePasswordProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();

  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState<any>();
  const [currentPassword, setCurrentPassword] = useState<any>();
  const [newPassword, setNewPassword] = useState<any>();
  const [confirmNewPassword, setConfirmNewPassword] = useState<any>();

  useEffect(() => {
    setCurrentUser(me);
  }, [me]);

  function onCurrentPasswordChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setCurrentPassword(value);
  }
  function onNewPasswordChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setNewPassword(value);
  }
  function onConfirmNewPasswordChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setConfirmNewPassword(value);
  }

  const [
    changeUserPassword,
    { loading: changePasswordLoading, error: changePasswordError },
  ] = useMutation(CHANGE_PASSWORD);

  async function onSave(event: React.FormEvent<HTMLFormElement>, input: any) {
    event.preventDefault();

    const { currentPassword, newPassword, confirmNewPassword } = input;

    try {
      if (!currentPassword) {
        throw new Error('Parola curentă lipseşte.');
      }

      if (!newPassword) {
        throw new Error('Parola nouă lipseşte.');
      }

      if (currentPassword === newPassword) {
        throw new Error('Noua parolă trebuie să fie diferită.');
      }

      if (!confirmNewPassword) {
        throw new Error('Confirmarea parolei noi lipseşte.');
      }

      if (newPassword !== confirmNewPassword) {
        throw new Error('Parola nouă şi confirmarea ei nu se potrivesc.');
      }

      const variables = { id: currentUser.id, ...input };

      await changeUserPassword({
        variables,
      });
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  const input = {
    currentPassword,
    newPassword,
    confirmNewPassword,
  };

  if (changePasswordLoading) return <Loading />;
  if (changePasswordError) return <p>{`Error: ${changePasswordError}`}</p>;

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />
      <Container maxWidth="sm" className={classes.cardGrid}>
        <Paper elevation={10} className={classes.paper}>
          <form
            noValidate
            id="change-password-form"
            onSubmit={(e) => onSave(e, input)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="currentPassword"
                  label="Parola curentă"
                  type="password"
                  id="currentPassword"
                  autoComplete="current-password"
                  onChange={(e) => onCurrentPasswordChange(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="Parola nouă"
                  type="password"
                  id="newPassword"
                  autoComplete="new-password"
                  onChange={(e) => onNewPasswordChange(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmNewPassword"
                  label="Confirmă parola nouă"
                  type="password"
                  id="confirmNewPassword"
                  autoComplete="confirm-new-password"
                  onChange={(e) => onConfirmNewPasswordChange(e)}
                />
              </Grid>
            </Grid>
          </form>
          <Box display="flex" justifyContent="flex-end" padding="12px 0">
            <Button color="secondary" type="submit" form="change-password-form">
              Salvează
            </Button>
          </Box>
        </Paper>
      </Container>
    </MainContainer>
  );
};
