import { gql } from '@apollo/client';

export const UPDATE_ANIMAL = gql`
  mutation updateAnimal(
    $id: ID!
    $images: [Upload]
    $imagesUrls: [ImageInput]
    $regNumber: String
    $sheetDate: String
    $sterilizationLocation: LocationInput
    $cageNumber: String
    $cageLocation: LocationInput
    $animalOwnerStatus: Boolean
    $ownerName: String
    $ownerCNP: String
    $ownerAddress: String
    $animalAddress: String
    $ownerPhone: String
    $attendantName: String
    $originAddress: String
    $attendantPhone: String
    $animalSpecies: String
    $animalSex: String
    $animalPregnancy: Boolean
    $animalBirthday: String
    $animalDetails: String
    $animalWeight: String
    $animalHeight: String
    $animalTemper: String
    $animalTatooStatus: Boolean
    $animalEartag: String
    $animalMicrochipStatus: String
    $animalMicrochipNumber: String
    $animalCard: String
    $animalPassportStatus: Boolean
    $animaleLeaveDate: String
    $animalSterilization: String
    $animalSterilizationDate: String
    $animalInternalDisinfestationStatus: Boolean
    $animalInternalDisinfestationDetails: String
    $animalExternalDisinfestationStatus: Boolean
    $animalExternalDisinfestationDetails: String
    $animalVaccineStatus: Boolean
    $animalVaccineDetails: String
    $animalSurgeryStatus: Boolean
    $animalSurgeryDetails: String
    $animalTreatmentStatus: Boolean
    $animalDiagnostic: String
    $animalTreatmentDetails: String
    $animalOtherObs: String
    $vetName: String
    $holdingObs: String
    $donatedByEduxanima: DonationsInput
  ) {
    updateAnimal(
      id: $id
      input: {
        images: $images
        imagesUrls: $imagesUrls
        regNumber: $regNumber
        sheetDate: $sheetDate
        sterilizationLocation: $sterilizationLocation
        cageNumber: $cageNumber
        cageLocation: $cageLocation
        animalOwnerStatus: $animalOwnerStatus
        ownerName: $ownerName
        ownerCNP: $ownerCNP
        ownerAddress: $ownerAddress
        animalAddress: $animalAddress
        ownerPhone: $ownerPhone
        attendantName: $attendantName
        originAddress: $originAddress
        attendantPhone: $attendantPhone
        animalSpecies: $animalSpecies
        animalSex: $animalSex
        animalPregnancy: $animalPregnancy
        animalBirthday: $animalBirthday
        animalDetails: $animalDetails
        animalWeight: $animalWeight
        animalHeight: $animalHeight
        animalTemper: $animalTemper
        animalTatooStatus: $animalTatooStatus
        animalEartag: $animalEartag
        animalMicrochipStatus: $animalMicrochipStatus
        animalMicrochipNumber: $animalMicrochipNumber
        animalCard: $animalCard
        animalPassportStatus: $animalPassportStatus
        animalLeaveDate: $animaleLeaveDate
        animalSterilization: $animalSterilization
        animalSterilizationDate: $animalSterilizationDate
        animalInternalDisinfestationStatus: $animalInternalDisinfestationStatus
        animalInternalDisinfestationDetails: $animalInternalDisinfestationDetails
        animalExternalDisinfestationStatus: $animalExternalDisinfestationStatus
        animalExternalDisinfestationDetails: $animalExternalDisinfestationDetails
        animalVaccineStatus: $animalVaccineStatus
        animalVaccineDetails: $animalVaccineDetails
        animalSurgeryStatus: $animalSurgeryStatus
        animalSurgeryDetails: $animalSurgeryDetails
        animalTreatmentStatus: $animalTreatmentStatus
        animalDiagnostic: $animalDiagnostic
        animalTreatmentDetails: $animalTreatmentDetails
        animalOtherObs: $animalOtherObs
        vetName: $vetName
        holdingObs: $holdingObs
        donatedByEduxanima: $donatedByEduxanima
      }
    ) {
      id
      imagesUrls {
        url
        fileName
      }
      regNumber
      sheetDate
      sterilizationLocation {
        location
        lat
        lng
      }
      cageNumber
      cageLocation {
        location
        lat
        lng
      }
      animalOwnerStatus
      ownerName
      ownerCNP
      ownerAddress
      animalAddress
      ownerPhone
      attendantName
      originAddress
      attendantPhone
      animalSpecies
      animalSex
      animalPregnancy
      animalBirthday
      animalDetails
      animalWeight
      animalHeight
      animalTemper
      animalTatooStatus
      animalEartag
      animalMicrochipStatus
      animalMicrochipNumber
      animalCard
      animalPassportStatus
      animalLeaveDate
      animalSterilization
      animalSterilizationDate
      animalInternalDisinfestationStatus
      animalInternalDisinfestationDetails
      animalExternalDisinfestationStatus
      animalExternalDisinfestationDetails
      animalVaccineStatus
      animalVaccineDetails
      animalSurgeryStatus
      animalSurgeryDetails
      animalTreatmentStatus
      animalDiagnostic
      animalTreatmentDetails
      animalOtherObs
      vetName
      holdingObs
      donatedByEduxanima {
        cage
        chain
        harness
        slidingWire
        others
        othersDetails
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
    }
  }
`;
