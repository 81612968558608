import React, { useState } from 'react';
import {
  useQuery,
  useMutation,
  ApolloClient,
  useApolloClient,
} from '@apollo/client';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Link,
} from '@mui/material';

import { Autocomplete } from '@mui/lab';

import { Loading } from './Loading';
import { AddProductDialog } from './AddProductDialog';

import { GET_PRODUCTS } from '../gql/queries/getProducts';
import { CREATE_PRODUCT } from '../gql/mutations/CreateProduct';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';

interface InventoryDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
  added: boolean;
}

export const InventoryDialog: React.FC<InventoryDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
  added,
}) => {
  const client: ApolloClient<any> = useApolloClient();
  const [productId, setProductId] = useState<any>();
  // const [status, setStatus] = useState<any>();
  const [date, setDate] = useState<any>();
  const [quantity, setQuantity] = useState<number>();
  const [details, setDetails] = useState<any>();
  const [invoiceNumber, setInvoiceNumber] = useState<any>();

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);

  const { data, loading, error } = useQuery(GET_PRODUCTS);

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function onProductChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    option: any
  ) {
    // const value = (event.target as HTMLInputElement).value;
    setProductId(option?.id);
  }

  function onDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setDate(value);
  }

  function onQuantityChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const floatValue = +parseFloat(value).toFixed(2);
    setQuantity(floatValue);
  }

  function onDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setDetails(value);
  }

  function onInvoiceNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setInvoiceNumber(value);
  }

  const variables = {
    productId,
    // status,
    date,
    quantity,
    details,
    invoiceNumber,
  };

  const [
    createProduct,
    { loading: createProductLoading, error: createProductError },
  ] = useMutation(CREATE_PRODUCT, {
    update(cache, { data: { createProduct } }: any) {
      const { getProducts }: any = cache.readQuery({
        query: GET_PRODUCTS,
      });

      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          getProducts: [...getProducts, createProduct],
        },
      });
    },
    refetchQueries: [
      {
        query: GET_PRODUCTS,
      },
    ],
    awaitRefetchQueries: true,
  });

  async function registerProduct(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.name)
        throw new Error('Numele produsului nu este completat.');
      if (!variables.uom)
        throw new Error('Unitatea de măsură nu este completată.');
      if (!variables.supplierId)
        throw new Error('Furnizorul nu este completat.');

      await createProduct({ variables });

      return onCloseAddDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  if (loading || createProductLoading) return <Loading />;
  if (error || createProductError) return <p>{`Error: ${error}`}</p>;

  const products = data?.getProducts.map((o: any) => ({
    ...o,
  }));

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="xs"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <form
            noValidate
            id="register-inventory-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Autocomplete
                  id="products-list"
                  options={products}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Produs" variant="outlined" />
                  )}
                  isOptionEqualToValue={(option: any) => option.id}
                  onChange={(e: any, option: any) => onProductChange(e, option)}
                />
              </Grid>
              {added && (
                <Grid item sm={12}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => openAdd()}
                  >
                    Adaugă un produs nou
                  </Link>
                </Grid>
              )}

              {/* <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="status"
                  label="Status"
                  fullWidth
                  autoFocus
                  onChange={(e) => onStatusChange(e)}
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="date"
                  label="Data"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  autoFocus
                  onChange={(e) => onDateChange(e)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="quantity"
                  label="Cantitate"
                  fullWidth
                  autoFocus
                  onChange={(e) => onQuantityChange(e)}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="invoiceNumber"
                  label="Nr. factură"
                  fullWidth
                  autoFocus
                  onChange={(e) => onInvoiceNumberChange(e)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="details"
                  label="Detalii"
                  fullWidth
                  autoFocus
                  onChange={(e) => onDetailsChange(e)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            type="submit"
            form="register-inventory-form"
          >
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
      <AddProductDialog
        title="Adaugă un produs nou"
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={registerProduct}
      />
    </>
  );
};
