import type React from 'react';

import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    height: '100%',
    maxHeight: '600px',
  },
}));

interface ImageDialogProps {
  open: any;
  onClose: any;
  imageUrl: any;
  imageName: any;
}
export const ImageDialog: React.FC<ImageDialogProps> = ({
  open,
  onClose,
  imageUrl,
  imageName,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="md"
      >
        <DialogContent className={classes.imageContainer}>
          <img className={classes.image} src={imageUrl} alt={imageName} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
