import type React from 'react';

import {
  Box,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import {
  Pets,
  Home,
  VolunteerActivism,
  Assignment,
  People,
  History,
  Storage,
} from '@mui/icons-material';

import { NAVIGATION_ACTION } from '../gql/queries/navigationAction';
import { ApolloClient, useApolloClient, useQuery } from '@apollo/client';

export enum Action {
  Home = '/',
  AllAnimals = '/all-animals',
  Adoptions = '/adoptions',
  Results = '/results',
  AllUsers = '/users',
  Activities = '/activities',
  Inventory = '/inventory',
}

interface NavProps {
  me?: any;
  navigate?: any;
}

export const Nav: React.FC<NavProps> = ({ me, navigate }) => {
  const client: ApolloClient<any> = useApolloClient();
  const { data } = useQuery(NAVIGATION_ACTION);

  function onChange(event: any, newValue: any) {
    client.writeQuery({
      query: NAVIGATION_ACTION,
      data: {
        navigationAction: newValue,
      },
    });

    navigate(newValue);
  }

  const ACTIONS = [
    {
      label: 'Acasă',
      value: Action.Home,
      icon: Home,
      roles: ['SUPERADMIN', 'ADMIN', 'EDITOR', 'VIEWER'],
    },
    {
      label: 'Animale',
      value: Action.AllAnimals,
      icon: Pets,
      roles: ['SUPERADMIN', 'ADMIN', 'EDITOR', 'VIEWER'],
    },
    {
      label: 'Adopţii',
      value: Action.Adoptions,
      icon: VolunteerActivism,
      roles: ['SUPERADMIN', 'ADMIN', 'EDITOR'],
    },
    {
      label: 'Rezultate',
      value: Action.Results,
      icon: Assignment,
      roles: ['SUPERADMIN', 'ADMIN', 'EDITOR', 'VIEWER'],
    },
    {
      label: 'Inventar',
      value: Action.Inventory,
      icon: Storage,
      roles: ['SUPERADMIN', 'ADMIN', 'EDITOR'],
    },
    {
      label: 'Utilizatori',
      value: Action.AllUsers,
      icon: People,
      roles: ['SUPERADMIN', 'ADMIN'],
    },
    {
      label: 'Istoric',
      value: Action.Activities,
      icon: History,
      roles: ['SUPERADMIN', 'ADMIN'],
    },
  ];

  const renderNav = (
    <BottomNavigation
      value={data.navigationAction}
      onChange={onChange}
      showLabels
    >
      {ACTIONS.map(({ label, value, icon: Icon, roles }) => {
        if (!roles.some((role) => me?.type?.includes(role)))
          return <div key={value}></div>;

        return (
          <BottomNavigationAction
            key={value}
            label={label}
            value={value}
            icon={<Icon />}
          />
        );
      })}
    </BottomNavigation>
  );

  return (
    <Box position="fixed" bottom={0} width="100%" zIndex={99}>
      <Paper elevation={16}>{renderNav}</Paper>
    </Box>
  );
};
