import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Divider,
  Typography,
  ImageList,
  ImageListItem,
  IconButton,
  ImageListItemBar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Delete } from '@mui/icons-material';

import { DropzoneArea } from 'react-mui-dropzone';

import { ImageDialog } from '../components/ImageDialog';
import { AreYouSureDialog } from '../components/AreYouSureDialog';
import { Loading } from '../components/Loading';

import { GoogleMapsAutocomplete } from './GoogleMapsAutocomplete';

import { DELETE_ANIMAL_IMAGE } from '../gql/mutations/DeleteAnimalImage';
import { UPDATE_ANIMAL } from '../gql/mutations/UpdateAnimal';
interface EditAnimalDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
  animal: any;
  startDate?: any;
  endDate?: any;
}

const useStyles = makeStyles((theme) => ({
  uploadArea: {
    minHeight: '50px!important',
  },
  image: {
    cursor: 'pointer',
  },
  iconBtn: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  requiredFields: {
    backgroundColor: 'rgba(30,0,0,0.2)',
  },
}));

export const EditAnimalDialog: React.FC<EditAnimalDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
  animal,
  startDate,
  endDate,
}) => {
  const yes = 'Şterge';
  const no = 'Închide';
  // TODO: Upload images
  const [imagesUrls, setImagesUrls] = useState<any>(animal?.imagesUrls);
  const [imagesToBeUploaded, setImagesToBeUploaded] = useState<any>();
  const [regNumber, setRegNumber] = useState<any>(animal?.regNumber);
  const [sheetDate, setSheetDate] = useState<any>(animal?.sheetDate);
  const [sterilizationLocation, setSterilizationLocation] = useState<any>(
    animal?.sterilizationLocation
  );
  const [cageNumber, setCageNumber] = useState<any>(animal?.cageNumber);
  const [cageLocation, setCageLocation] = useState<any>(animal?.cageLocation);
  const [animalOwnerStatus, setAnimalOwnerStatus] = useState<boolean>(
    animal?.animalOwnerStatus
  );
  const [ownerName, setOwnerName] = useState<any>(animal?.ownerName);
  const [ownerCNP, setOwnerCNP] = useState<any>(animal?.ownerCNP);
  const [ownerAddress, setOwnerAddress] = useState<any>(animal?.ownerAddress);
  const [animalAddress, setAnimalAddress] = useState<any>(
    animal?.animalAddress
  );
  const [ownerPhone, setOwnerPhone] = useState<any>(animal?.ownerPhone);
  const [attendantName, setAttendantName] = useState<any>(
    animal?.attendantName
  );
  const [originAddress, setOriginAddress] = useState<any>(
    animal?.originAddress
  );
  const [attendantPhone, setAttendantPhone] = useState<any>(
    animal?.attendantPhone
  );
  const [animalSpecies, setAnimalSpecies] = useState<any>(
    animal?.animalSpecies
  );
  const [animalSex, setAnimalSex] = useState<any>(animal?.animalSex);
  const [animalPregnancy, setAnimalPregnancy] = useState<any>(
    animal?.animalPregnancy
  );
  const [animalBirthday, setAnimalBirthday] = useState<any>(
    animal?.animalBirthday
  );
  const [animalDetails, setAnimalDetails] = useState<any>(
    animal?.animalDetails
  );
  const [animalWeight, setAnimalWeight] = useState<any>(animal?.animalWeight);
  const [animalHeight, setAnimalHeight] = useState<any>(animal?.animalHeight);
  const [animalTemper, setAnimalTemper] = useState<any>(animal?.animalTemper);
  const [animalTatooStatus, setAnimalTatooStatus] = useState<any>(
    animal?.animalTatooStatus
  );
  const [animalEartag, setAnimalEartag] = useState<any>(animal?.animalEartag);
  const [animalMicrochipStatus, setAnimalMicrochipStatus] = useState<any>(
    animal?.animalMicrochipStatus
  );
  const [animalMicrochipNumber, setAnimalMicrochipNumber] = useState<any>(
    animal?.animalMicrochipNumber
  );
  const [animalCard, setAnimalCard] = useState<any>(animal?.animalCard);
  const [animalPassportStatus, setAnimalPassportStatus] = useState<any>(
    animal?.animalPassportStatus
  );
  const [animalLeaveDate, setAnimalLeaveDate] = useState<any>(
    animal?.animalLeaveDate
  );
  const [animalSterilization, setAnimalSterilization] = useState<any>(
    animal?.animalSterilization
  );
  const [animalSterilizationDate, setAnimalSterilizationDate] = useState<any>(
    animal?.animalSterilizationDate
  );
  const [animalSterilizationObs, setAnimalSterilizationObs] = useState<any>(
    animal?.animalSterilizationObs
  );
  const [
    animalInternalDisinfestationStatus,
    setAnimalInternalDisinfestationStatus,
  ] = useState<boolean>(animal?.animalInternalDisinfestationStatus);
  const [
    animalInternalDisinfestationDetails,
    setAnimalInternalDisinfestationDetails,
  ] = useState<any>(animal?.animalInternalDisinfestationDetails);
  const [
    animalExternalDisinfestationStatus,
    setAnimalExternalDisinfestationStatus,
  ] = useState<boolean>(animal?.animalExternalDisinfestationStatus);
  const [
    animalExternalDisinfestationDetails,
    setAnimalExternalDisinfestationDetails,
  ] = useState<any>(animal?.animalExternalDisinfestationDetails);
  const [animalVaccineStatus, setAnimalVaccineStatus] = useState<boolean>(
    animal?.animalVaccineStatus
  );
  const [animalVaccineDetails, setAnimalVaccineDetails] = useState<any>(
    animal?.animalVaccineDetails
  );
  const [animalSurgeryStatus, setAnimalSurgeryStatus] = useState<boolean>(
    animal?.animalSurgeryStatus
  );
  const [animalSurgeryDetails, setAnimalSurgeryDetails] = useState<any>(
    animal?.animalSurgeryDetails
  );
  const [animalTreatmentStatus, setAnimalTreatmentStatus] = useState<any>(
    animal?.animalTreatmentStatus
  );
  const [animalDiagnostic, setAnimalDiagnostic] = useState<any>(
    animal?.animalDiagnostic
  );
  const [animalTreatmentDetails, setAnimalTreatmentDetails] = useState<any>(
    animal?.animalTreatmentDetails
  );
  const [animalOtherObs, setAnimalOtherObs] = useState<any>(
    animal?.animalOtherObs
  );
  const [vetName, setVetName] = useState<any>(animal?.vetName);
  const [holdingObs, setHoldingObs] = useState<any>(animal?.holdingObs);
  const [donatedByEduxanima, setDonatedByEduxanima] = useState({
    chain: animal?.donatedByEduxanima.chain,
    harness: animal?.donatedByEduxanima.harness,
    collar: animal?.donatedByEduxanima.collar,
    slidingWire: animal?.donatedByEduxanima.slidingWire,
    cage: animal?.donatedByEduxanima.cage,
    food: animal?.donatedByEduxanima.food,
    others: animal?.donatedByEduxanima.others,
    othersDetails: animal?.donatedByEduxanima.othersDetails,
  });

  const [openImage, setOpenImage] = useState<boolean>(false);
  const [imageToBeOpen, setImageToBeOpen] = useState<any>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedImageToBeDeleted, setSelectedImageToBeDeleted] =
    useState<any>();

  useEffect(() => {
    setImagesUrls(animal?.imagesUrls);
    setRegNumber(animal?.regNumber);
    setSheetDate(animal?.sheetDate);
    setSterilizationLocation(animal?.sterilizationLocation);
    setCageNumber(animal?.cageNumber);
    setCageLocation(animal?.cageLocation);
    setAnimalOwnerStatus(animal?.animalOwnerStatus);
    setOwnerName(animal?.ownerName);
    setOwnerCNP(animal?.ownerCNP);
    setOwnerAddress(animal?.ownerAddress);
    setAnimalAddress(animal?.animalAddress);
    setOwnerPhone(animal?.ownerPhone);
    setAttendantName(animal?.attendantName);
    setOriginAddress(animal?.originAddress);
    setAttendantPhone(animal?.attendantPhone);
    setAnimalSpecies(animal?.animalSpecies);
    setAnimalSex(animal?.animalSex);
    setAnimalPregnancy(animal?.animalPregnancy);
    setAnimalBirthday(animal?.animalBirthday);
    setAnimalDetails(animal?.animalDetails);
    setAnimalWeight(animal?.animalWeight);
    setAnimalHeight(animal?.animalHeight);
    setAnimalTemper(animal?.animalTemper);
    setAnimalTatooStatus(animal?.animalTatooStatus);
    setAnimalEartag(animal?.animalEartag);
    setAnimalMicrochipStatus(animal?.animalMicrochipStatus);
    setAnimalMicrochipNumber(animal?.animalMicrochipNumber);
    setAnimalCard(animal?.animalCard);
    setAnimalPassportStatus(animal?.animalPassportStatus);
    setAnimalLeaveDate(animal?.animalLeaveDate);
    setAnimalSterilization(animal?.animalSterilization);
    setAnimalSterilizationDate(animal?.animalSterilizationDate);
    setAnimalSterilizationObs(animal?.animalSterilizationObs);
    setAnimalInternalDisinfestationStatus(
      animal?.animalInternalDisinfestationStatus
    );
    setAnimalInternalDisinfestationDetails(
      animal?.animalInternalDisinfestationDetails
    );
    setAnimalExternalDisinfestationStatus(
      animal?.animalExternalDisinfestationStatus
    );
    setAnimalExternalDisinfestationDetails(
      animal?.animalExternalDisinfestationDetails
    );
    setAnimalVaccineStatus(animal?.animalVaccineStatus);
    setAnimalVaccineDetails(animal?.animalVaccineDetails);
    setAnimalSurgeryStatus(animal?.animalSurgeryStatus);
    setAnimalSurgeryDetails(animal?.animalSurgeryDetails);
    setAnimalTreatmentStatus(animal?.animalTreatmentStatus);
    setAnimalDiagnostic(animal?.animalDiagnostic);
    setAnimalTreatmentDetails(animal?.animalTreatmentDetails);
    setAnimalOtherObs(animal?.animalOtherObs);
    setVetName(animal?.vetName);
    setHoldingObs(animal?.holdingObs);
    setDonatedByEduxanima({
      chain: animal?.donatedByEduxanima.chain,
      harness: animal?.donatedByEduxanima.harness,
      collar: animal?.donatedByEduxanima.collar,
      slidingWire: animal?.donatedByEduxanima.slidingWire,
      cage: animal?.donatedByEduxanima.cage,
      food: animal?.donatedByEduxanima.food,
      others: animal?.donatedByEduxanima.others,
      othersDetails: animal?.donatedByEduxanima.othersDetails,
    });
  }, [animal]);

  function onImagesChange(files: any) {
    setImagesToBeUploaded([...files]);
  }

  function onRegNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setRegNumber(value);
  }

  function onSheetDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSheetDate(value);
  }

  function onSterilizationLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSterilizationLocation({
      location: value,
    });
  }

  function onCageNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setCageNumber(value);
  }

  function onCageLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setCageLocation({
      location: value,
    });
  }

  function onAnimalOwnerStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newHasOwnerState = value === '0' ? false : true;
    setAnimalOwnerStatus(newHasOwnerState);
  }

  function onOwnerNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerName(value);
  }

  function onOwnerCNPChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerCNP(value);
  }

  function onOwnerAddressChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerAddress(value);
  }

  function onAnimalAddressChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalAddress(value);
  }

  function onOwnerPhoneChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerPhone(value);
  }

  function onAttendantNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAttendantName(value);
  }

  function onOriginAddressChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOriginAddress(value);
  }

  function onAttendantPhoneChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAttendantPhone(value);
  }

  function onAnimalSpeciesChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSpecies(value);
  }

  function onAnimalSexChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSex(value);
  }

  function onAnimalPregnancyChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newAnimalPregnancy = value === '0' ? false : true;
    setAnimalPregnancy(newAnimalPregnancy);
  }

  function onAnimalBirthdayChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalBirthday(value);
  }

  function onAnimalDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalDetails(value);
  }

  function onAnimalWeightChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalWeight(value);
  }

  function onAnimalHeightChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalHeight(value);
  }

  function onAnimalTemperChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalTemper(value);
  }

  function onAnimalTatooStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newAnimalTatooStatus = value === '0' ? false : true;
    setAnimalTatooStatus(newAnimalTatooStatus);
  }

  function onAnimalEartagChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalEartag(value);
  }

  function onAnimalMicrochipStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipStatus(value);
  }

  function onAnimalMicrochipNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipNumber(value);
  }

  function onAnimalCardChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalCard(value);
  }

  function onAnimalPassportStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newAnimalPassportStatus = value === '0' ? false : true;
    setAnimalPassportStatus(newAnimalPassportStatus);
  }

  function onAnimalLeaveDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const date = new Date(value);
    setAnimalLeaveDate(date.toISOString());
  }

  function onAnimalSterilizationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSterilization(value);
  }

  function onAnimalSterilizationDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const date = new Date(value);
    setAnimalSterilizationDate(date.toISOString());
  }

  function onAnimalSterilizationObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSterilizationObs(value);
  }

  function onAnimalInternalDisinfestationStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newInternalDisinfestationStatus = value === '0' ? false : true;
    setAnimalInternalDisinfestationStatus(newInternalDisinfestationStatus);
  }
  function onAnimalInternalDisinfestationDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalInternalDisinfestationDetails(value);
  }

  function onAnimalExternalDisinfestationStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newExternalDisinfestationStatus = value === '0' ? false : true;
    setAnimalExternalDisinfestationStatus(newExternalDisinfestationStatus);
  }
  function onAnimalExternalDisinfestationDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalExternalDisinfestationDetails(value);
  }
  function onAnimalVaccineStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newVaccineStatus = value === '0' ? false : true;
    setAnimalVaccineStatus(newVaccineStatus);
  }
  function onAnimalVaccineDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalVaccineDetails(value);
  }

  function onAnimalSurgeryStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newSurgeryStatus = value === '0' ? false : true;
    setAnimalSurgeryStatus(newSurgeryStatus);
  }

  function onAnimalSurgeryDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSurgeryDetails(value);
  }

  function onAnimalTreatmentStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newTreatmentStatus = value === '0' ? false : true;
    setAnimalTreatmentStatus(newTreatmentStatus);
  }

  function onAnimalDiagnosticChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalDiagnostic(value);
  }

  function onAnimalTreatmentDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalTreatmentDetails(value);
  }

  function onAnimalOtherObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalOtherObs(value);
  }

  function onVetNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setVetName(value);
  }

  function onHoldingObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setHoldingObs(value);
  }

  function onDonatedByEduxanimaChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const name = (event.target as HTMLInputElement).name;
    const checked = (event.target as HTMLInputElement).checked;
    setDonatedByEduxanima({
      ...donatedByEduxanima,
      [name]: checked,
    });

    if (name === 'others' && !checked) {
      setDonatedByEduxanima({
        ...donatedByEduxanima,
        [name]: checked,
        othersDetails: '',
      });
    }
  }

  function onDonationOthers(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;

    setDonatedByEduxanima({
      ...donatedByEduxanima,
      othersDetails: value,
    });
  }

  function onOpenDeleteDialog(event: any, image: any) {
    event.preventDefault();

    setSelectedImageToBeDeleted(image);
    setOpenDeleteDialog(true);
  }

  function onCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  function showImages(imagesUrls: any) {
    return (
      <Grid item xs={12}>
        <ImageList rowHeight={160} cols={2}>
          {imagesUrls.map((image: any) => {
            return (
              <ImageListItem key={image.fileName} className={classes.image}>
                <img
                  src={image.url}
                  alt={image.fileName}
                  onClick={() => handleOpenImage(image)}
                />
                <ImageListItemBar
                  actionIcon={
                    <IconButton
                      className={classes.iconBtn}
                      aria-label="delete"
                      onClick={(event) => onOpenDeleteDialog(event, image)}
                    >
                      <Delete fontSize="medium" />
                    </IconButton>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Grid>
    );
  }

  function onOpenImage() {
    setOpenImage(true);
  }

  function onCloseImage() {
    setOpenImage(false);
  }

  function handleOpenImage(image: any) {
    setImageToBeOpen(image);
    onOpenImage();
  }

  const {
    chain,
    harness,
    collar,
    slidingWire,
    cage,
    food,
    others,
    othersDetails,
  } = donatedByEduxanima;

  const variables = {
    images: imagesToBeUploaded,
    imagesUrls: imagesUrls,
    regNumber,
    sheetDate,
    sterilizationLocation: {
      location: sterilizationLocation?.location,
    },
    cageNumber,
    cageLocation: {
      location: cageLocation?.location,
    },
    animalOwnerStatus,
    ownerName,
    ownerCNP,
    ownerAddress,
    animalAddress,
    ownerPhone,
    attendantName,
    originAddress,
    attendantPhone,
    animalSpecies,
    animalSex,
    animalPregnancy,
    animalBirthday,
    animalDetails,
    animalWeight,
    animalHeight,
    animalTemper,
    animalTatooStatus,
    animalEartag,
    animalMicrochipStatus,
    animalMicrochipNumber,
    animalCard,
    animalPassportStatus,
    animalLeaveDate,
    animalSterilization,
    animalSterilizationDate,
    animalSterilizationObs,
    animalInternalDisinfestationStatus,
    animalInternalDisinfestationDetails,
    animalExternalDisinfestationStatus,
    animalExternalDisinfestationDetails,
    animalVaccineStatus,
    animalVaccineDetails,
    animalSurgeryStatus,
    animalSurgeryDetails,
    animalTreatmentStatus,
    animalDiagnostic,
    animalTreatmentDetails,
    animalOtherObs,
    vetName,
    holdingObs,
    donatedByEduxanima,
  };

  const [
    deleteAnimalImage,
    { loading: loadingeDeleteAnimalImage, error: errorDeleteAnimalImage },
  ] = useMutation(DELETE_ANIMAL_IMAGE);

  const [
    updateAnimal,
    { loading: loadingUpdateAnimal, error: errorUpdateAnimal },
  ] = useMutation(UPDATE_ANIMAL);

  async function deleteImage() {
    const deleteVariables: any = {
      fileName: selectedImageToBeDeleted.fileName,
    };

    const newImagesUrls = imagesUrls.filter(
      (image: any) => image.fileName !== selectedImageToBeDeleted.fileName
    );

    setImagesUrls(newImagesUrls);
    await deleteAnimalImage({ variables: deleteVariables });
    setOpenDeleteDialog(false);
  }

  const classes = useStyles();

  if (loadingeDeleteAnimalImage || loadingUpdateAnimal) return <Loading />;
  if (errorDeleteAnimalImage || errorUpdateAnimal) return <p>Error</p>;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <form
            noValidate
            id="register-animal-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              {/* ADD IMAGE FILE UPLOADS */}
              {imagesUrls && showImages(imagesUrls)}
              {imagesUrls?.length < 2 && (
                <Grid item xs={12}>
                  <DropzoneArea
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                    showPreviewsInDropzone={true}
                    showFileNames={true}
                    maxFileSize={5000000}
                    initialFiles={imagesUrls}
                    filesLimit={imagesUrls.length > 0 ? 1 : 2}
                    dropzoneText={`Încarcă ${
                      imagesUrls.length > 0 ? 'fotografie' : 'fotografii'
                    }`}
                    dropzoneClass={classes.uploadArea}
                    onChange={(files) => onImagesChange(files)}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  id="regNumber"
                  label="Nr. Crt."
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => onRegNumberChange(e)}
                  value={regNumber}
                  className={classes.requiredFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="sheetDate"
                  label="Dată fişă"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                  onChange={(e) => onSheetDateChange(e)}
                  value={sheetDate}
                  className={classes.requiredFields}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  id="sterilizationLocation"
                  label="Locaṭie sterilizare / alt act medical"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => onSterilizationLocationChange(e)}
                  value={sterilizationLocation?.location}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <GoogleMapsAutocomplete
                  onChange={(e: any, newValue: any) =>
                    onSterilizationLocationChange(newValue)
                  }
                  value={sterilizationLocation?.location}
                  required={true}
                  className={classes.requiredFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="cageNumber"
                  label="Cuşcă Nr. maşină de transport"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onCageNumberChange(e)}
                  value={cageNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="cageLocation"
                  label="Staţionare animal (cuşcă / locaţie)"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onCageLocationChange(e)}
                  value={cageLocation?.location}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalOwnerStatus"
                  label="Are proprietar?"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  className={classes.requiredFields}
                  onChange={(e) => onAnimalOwnerStatusChange(e)}
                  value={animalOwnerStatus ? '1' : '0'}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>

              <br />

              {animalOwnerStatus && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Date despre proprietar
                    </Typography>
                  </Grid>
                  <Divider />

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerName"
                      label="Nume şi Prenume proprietar"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerNameChange(e)}
                      // required={animalOwnerStatus}
                      value={ownerName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerCNP"
                      label="CNP"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerCNPChange(e)}
                      value={ownerCNP}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerAddress"
                      label="Adresă proprietar"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerAddressChange(e)}
                      // required={animalOwnerStatus}
                      value={ownerAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="animalAddress"
                      label="Adresa unde stă animalul"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onAnimalAddressChange(e)}
                      value={animalAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerPhone"
                      label="Telefon proprietar"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerPhoneChange(e)}
                      value={ownerPhone}
                    />
                  </Grid>
                </>
              )}
              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Date despre persoana care aduce / însoţeşte animalul
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={6}>
                <TextField
                  id="attendantName"
                  label="Nume şi Prenume însoţitor"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAttendantNameChange(e)}
                  value={attendantName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="originAddress"
                  label="Adresa de provenienţă"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onOriginAddressChange(e)}
                  value={originAddress}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="attendantPhone"
                  label="Telefon însoţitor"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAttendantPhoneChange(e)}
                  value={attendantPhone}
                />
              </Grid>

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Date despre animal
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalSpecies"
                  label="Specie"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  className={classes.requiredFields}
                  onChange={(e) => onAnimalSpeciesChange(e)}
                  value={animalSpecies}
                >
                  <MenuItem value="dog">Caine</MenuItem>
                  <MenuItem value="cat">Pisica</MenuItem>
                  <MenuItem value="other">Alta specie</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalSex"
                  label="Sex"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  className={classes.requiredFields}
                  onChange={(e) => onAnimalSexChange(e)}
                  value={animalSex}
                >
                  <MenuItem value="male">Mascul</MenuItem>
                  <MenuItem value="female">Femela</MenuItem>
                </TextField>
              </Grid>
              {animalSex === 'female' && (
                <Grid item xs={6} sm={3}>
                  <TextField
                    id="animalPregnancy"
                    label="Gestantă?"
                    variant="outlined"
                    select
                    fullWidth
                    onChange={(e) => onAnimalPregnancyChange(e)}
                    value={animalPregnancy ? '1' : '0'}
                  >
                    <MenuItem value="0">Nu</MenuItem>
                    <MenuItem value="1">Da</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalBirthday"
                  label="Data naşterii"
                  variant="outlined"
                  fullWidth
                  // required
                  onChange={(e) => onAnimalBirthdayChange(e)}
                  value={animalBirthday}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalDetails"
                  label="Semnalmente (culoare, talie, semne particulare)"
                  variant="outlined"
                  multiline
                  fullWidth
                  rows={2}
                  // required
                  onChange={(e) => onAnimalDetailsChange(e)}
                  value={animalDetails}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalWeight"
                  label="Greutate"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalWeightChange(e)}
                  value={animalWeight}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalHeight"
                  label="Înălţime (până la greabăn)"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalHeightChange(e)}
                  value={animalHeight}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalTemper"
                  label="Temperament"
                  variant="outlined"
                  multiline
                  rows={2}
                  fullWidth
                  onChange={(e) => onAnimalTemperChange(e)}
                  value={animalTemper}
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="animalTatooStatus"
                  label="Tatuaj"
                  variant="outlined"
                  select
                  fullWidth
                  // required
                  onChange={(e) => onAnimalTatooStatusChange(e)}
                  value={animalTatooStatus ? '1' : '0'}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  id="animalEartag"
                  label="Crotal"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalEartagChange(e)}
                  value={animalEartag}
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="animalMicrochipStatus"
                  label="Microcip"
                  variant="outlined"
                  select
                  fullWidth
                  // required
                  onChange={(e) => onAnimalMicrochipStatusChange(e)}
                  value={animalMicrochipStatus}
                >
                  <MenuItem value="eduxanima">Eduxanima</MenuItem>
                  <MenuItem value="other">Alt cabinet</MenuItem>
                  <MenuItem value="no">Nu</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  id="animalMicrochipNumber"
                  label="Număr microcip"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalMicrochipNumberChange(e)}
                  value={animalMicrochipNumber}
                  // required={
                  //   animalMicrochipStatus && animalMicrochipStatus !== 'no'
                  // }
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <TextField
                  id="animalCard"
                  label="Serie şi nr. carnet"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalCardChange(e)}
                  value={animalCard}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalPassportStatus"
                  label="Paşaport"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalPassportStatusChange(e)}
                  value={animalPassportStatus ? '1' : '0'}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalLeaveDate"
                  label="Poate pleca începând cu data"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => onAnimalLeaveDateChange(e)}
                  value={animalLeaveDate}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <TextField
                  id="animalSterilization"
                  label="Sterilizare"
                  variant="outlined"
                  select
                  fullWidth
                  // required
                  onChange={(e) => onAnimalSterilizationChange(e)}
                  value={animalSterilization}
                >
                  <MenuItem value="eduxanima">Eduxanima</MenuItem>
                  <MenuItem value="other">Alt cabinet</MenuItem>
                  <MenuItem value="no">Nu</MenuItem>
                  <MenuItem value="unknown">Nu se ştie</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  id="animalSterilizationDate"
                  label="Dată sterilizare"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => onAnimalSterilizationDateChange(e)}
                  value={animalSterilizationDate}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalSterilizationObs"
                  label="Observaţii sterilizare"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalSterilizationObsChange(e)}
                  value={animalSterilizationObs}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalInternalDisinfestationStatus"
                  label="Deparazitare internă"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) =>
                    onAnimalInternalDisinfestationStatusChange(e)
                  }
                  value={animalInternalDisinfestationStatus ? '1' : '0'}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalInternalDisinfestationStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalInternalDisinfestationDetails"
                    label="Detalii deparazitare internă"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      onAnimalInternalDisinfestationDetailsChange(e)
                    }
                    value={animalInternalDisinfestationDetails}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalExternalDisinfestationStatus"
                  label="Deparazitare externă"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) =>
                    onAnimalExternalDisinfestationStatusChange(e)
                  }
                  value={animalExternalDisinfestationDetails ? '1' : '0'}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalExternalDisinfestationStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalExternalDisinfestationDetails"
                    label="Detalii deparazitare externă"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      onAnimalExternalDisinfestationDetailsChange(e)
                    }
                    value={animalExternalDisinfestationStatus ? '1' : '0'}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalVaccineStatus"
                  label="Vaccinări"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalVaccineStatusChange(e)}
                  value={animalVaccineStatus ? '1' : '0'}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalVaccineStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalVaccineDetails"
                    label="Detalii vaccinări"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnimalVaccineDetailsChange(e)}
                    value={animalVaccineDetails}
                  />
                </Grid>
              )}

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Alte intervenţii chirurgicale
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalSurgeryStatus"
                  label="S-au efectuat alte intervenţii chirurgicale?"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalSurgeryStatusChange(e)}
                  value={animalSurgeryStatus ? '1' : '0'}
                  required
                  className={classes.requiredFields}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalSurgeryStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalSurgeryDetails"
                    label="Operaţii chirurgicale efectuate"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnimalSurgeryDetailsChange(e)}
                    value={animalSurgeryDetails}
                  />
                </Grid>
              )}

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Alte tratamente
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={4}>
                <TextField
                  id="animalTreatmentStatus"
                  label="S-au efectuat tratamente medicale?"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalTreatmentStatusChange(e)}
                  value={animalTreatmentStatus ? '1' : '0'}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id="animalDiagnostic"
                  label="Diagnostic"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalDiagnosticChange(e)}
                  value={animalDiagnostic}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalTreatmentDetails"
                  label="Tratamente efectuate şi observaţii"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalTreatmentDetailsChange(e)}
                  value={animalTreatmentDetails}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalOtherObs"
                  label="Alte observaţii"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalOtherObsChange(e)}
                  value={animalOtherObs}
                />
              </Grid>

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Medic veterinar
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={6}>
                <TextField
                  id="vetName"
                  label="Nume şi prenume medic veterinar"
                  variant="outlined"
                  fullWidth
                  // required
                  onChange={(e) => onVetNameChange(e)}
                  value={vetName}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="holdingObs"
                  label="Observaţii referitoare la condiţiile de deţinere"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onHoldingObsChange(e)}
                  value={holdingObs}
                />
              </Grid>

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Donate de Eduxanima
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chain}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="chain"
                        value={donatedByEduxanima.chain}
                      />
                    }
                    label="lanţ"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={harness}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="harness"
                      />
                    }
                    label="ham"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={collar}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="collar"
                      />
                    }
                    label="zgardă"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={slidingWire}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="slidingWire"
                      />
                    }
                    label="sârmă culisantă"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cage}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="cage"
                      />
                    }
                    label="cuşcă"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={food}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="food"
                      />
                    }
                    label="hrană"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={others}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="others"
                      />
                    }
                    label="altele"
                  />
                </FormGroup>
              </Grid>
              {others && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="othersDetails"
                    label="Detalii pentru alte donaţii"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(e) => onDonationOthers(e)}
                    value={othersDetails}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-animal-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
      <ImageDialog
        open={openImage}
        onClose={onCloseImage}
        imageUrl={imageToBeOpen?.url}
        imageName={imageToBeOpen?.fileName}
      />
      <AreYouSureDialog
        title="Eşti sigur/ă că vrei să ştergi această imagine?"
        content=""
        open={openDeleteDialog}
        yes={yes}
        no={no}
        onClose={onCloseDeleteDialog}
        onYes={deleteImage}
      />
    </>
  );
};
