import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation createOrder(
    $productId: ID!
    $date: String!
    $quantity: Float!
    $details: String
  ) {
    createOrder(
      input: {
        productId: $productId
        date: $date
        quantity: $quantity
        details: $details
      }
    ) {
      id
      product {
        id
        name
        type
        sku
        uom
        description
        active
        status
        supplier {
          id
          name
          description
          location {
            location
            lat
            lng
          }
          phone
        }
      }
      date
      quantity
      details
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
    }
  }
`;
