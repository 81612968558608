import React, { useEffect, useState } from 'react';
import { Container, Paper, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import DateFnsAdapter from '@date-io/date-fns';

import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
  Legend,
  Font,
  Title,
  Subtitle,
} from 'devextreme-react/pie-chart';
import 'devextreme/dist/css/dx.light.css';

const useStyles = makeStyles(() => ({
  pieChart: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface AllAnimalsChartProps {
  animalsTotal: any;
  title: any;
  subtitle?: any;
}

export const AllAnimalsChart: React.FC<AllAnimalsChartProps> = ({
  animalsTotal,
  title,
  subtitle,
}) => {
  useEffect(() => {}, [animalsTotal]);
  const classes = useStyles();

  function customizeText(arg: any) {
    switch (arg.argumentText) {
      case 'dog':
        return `${arg.value > 1 ? `${arg.value} Câini` : `${arg.value} Câine`}`;
      case 'cat':
        return `${
          arg.value > 1 ? `${arg.value} Pisici` : `${arg.value} Pisică`
        }`;
    }
  }

  function customText(arg: any) {
    switch (arg.pointName) {
      case 'dog':
        return 'Câini';
      case 'cat':
        return 'Pisici';
    }
  }

  return (
    <Container maxWidth="sm">
      <Paper elevation={10} className={classes.pieChart}>
        <PieChart id="pie" dataSource={animalsTotal} type="doughnut">
          <Title text={title}>
            <Subtitle text={subtitle} />
          </Title>
          <Legend
            customizeText={customText}
            orientation="horizontal"
            itemTextPosition="right"
            horizontalAlignment="center"
            verticalAlignment="bottom"
            columnCount={4}
          />
          <Series argumentField="animalSpecies" valueField="total">
            <Label visible={true} customizeText={customizeText}>
              <Font size={16} />
              <Connector visible={true} width={1} />
            </Label>
          </Series>

          <Size width={500} />
          <Export enabled={true} />
        </PieChart>
      </Paper>
    </Container>
  );
};
