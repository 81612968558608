import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
} from '@mui/material';

interface AddNotRegisteredAnimalsProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
}

export const AddNotRegisteredAnimalsDialog: React.FC<
  AddNotRegisteredAnimalsProps
> = ({ title, open, onClose, onSave }) => {
  const [sheetDate, setSheetDate] = useState<any>();
  const [animalSex, setAnimalSex] = useState<any>();
  const [animalSpecies, setAnimalSpecies] = useState<any>();
  // const [animalTreatmentStatus, setAnimalTreatmentStatus] =
  //   useState<boolean>();
  // const [animalSurgeryStatus, setAnimalSurgeryStatus] = useState<boolean>();
  // const [animalSterilization, setAnimalSterilization] = useState<any>();
  const [animalOwnerStatus, setAnimalOwnerStatus] = useState<boolean>();
  // const [fieldName, setFieldName] = useState<any>();
  const [total, setTotal] = useState<any>();

  function onSheetDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSheetDate(value);
  }

  function onAnimalSexChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSex(value);
  }

  function onAnimalSpeciesChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSpecies(value);
  }

  // function onAnimalTreatmentStatusChange(
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) {
  //   const value = (event.target as HTMLInputElement).value;
  //   const newTreatmentStatus = value === '0' ? false : true;
  //   setAnimalTreatmentStatus(newTreatmentStatus);
  // }

  // function onAnimalSurgeryStatusChange(
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) {
  //   const value = (event.target as HTMLInputElement).value;
  //   const newSurgeryStatus = value === '0' ? false : true;
  //   setAnimalSurgeryStatus(newSurgeryStatus);
  // }

  function onAnimalOwnerStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newHasOwnerState = value === '0' ? false : true;
    setAnimalOwnerStatus(newHasOwnerState);
  }

  // function onFieldNameChange(
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) {
  //   const value = (event.target as HTMLInputElement).value;
  //   setFieldName(value);
  // }

  function onTotalChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setTotal(parseInt(value));
  }

  const variables = {
    sheetDate,
    animalSpecies,
    animalSex,
    animalOwnerStatus,
    total,
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="sm"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <form
            noValidate
            id="create-not-registered-animals"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="sheetDate"
                  label="Dată înregistrare"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                  onChange={(e) => onSheetDateChange(e)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalSpecies"
                  label="Specie"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalSpeciesChange(e)}
                >
                  <MenuItem value="dog">Câine</MenuItem>
                  <MenuItem value="cat">Pisică</MenuItem>
                  {/* <MenuItem value="other">Altă specie</MenuItem> */}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalSex"
                  label="Sex"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalSexChange(e)}
                >
                  <MenuItem value="male">Mascul</MenuItem>
                  <MenuItem value="female">Femela</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalOwnerStatus"
                  label="Are proprietar?"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  onChange={(e) => onAnimalOwnerStatusChange(e)}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextField
                  id="total"
                  label="Total"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => onTotalChange(e)}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            type="submit"
            form="create-not-registered-animals"
          >
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
