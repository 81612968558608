import { gql } from '@apollo/client';

export const GET_ALL_NOT_REGISTERED_ANIMALS = gql`
  query getAllNotRegisteredAnimals($startDate: String, $endDate: String) {
    getAllNotRegisteredAnimals(
      input: { startDate: $startDate, endDate: $endDate }
    ) {
      id
      sheetDate
      animalTreatmentStatus
      animalSurgeryStatus
      animalSterilization
      animalOwnerStatus
      animalSex
      animalSpecies
      fieldName
      total
    }
  }
`;
