import { gql } from '@apollo/client';

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      firstName
      lastName
      type
      location {
        location
        lat
        lng
      }
      phone
    }
  }
`;
