import type React from 'react';

import { TextField } from '@mui/material';

interface OutlinedTextFieldProps {
  value: any;
  onChange: any;
  columnDef: any;
  TextFieldProps: any;
}

export const OutlinedTextField: React.FC<OutlinedTextFieldProps> = ({
  value,
  onChange,
  columnDef: { title },
  TextFieldProps = {},
}) => (
  <TextField
    label={title}
    variant="outlined"
    type="text"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    {...TextFieldProps}
  />
);
