import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Divider,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DropzoneArea } from 'react-mui-dropzone';

import { GoogleMapsAutocomplete } from './GoogleMapsAutocomplete';

interface AddAnimalDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
}

const useStyles = makeStyles(() => ({
  uploadArea: {
    minHeight: '50px!important',
  },
  requiredFields: {
    backgroundColor: 'rgba(30,0,0,0.2)',
  },
}));

export const AddAnimalDialog: React.FC<AddAnimalDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
}) => {
  // TODO: Upload images
  const [images, setImages] = useState<any>();
  const [regNumber, setRegNumber] = useState<any>();
  const [sheetDate, setSheetDate] = useState<any>();
  const [sterilizationLocation, setSterilizationLocation] = useState<any>();
  const [cageNumber, setCageNumber] = useState<any>();
  const [cageLocation, setCageLocation] = useState<any>();
  const [animalOwnerStatus, setAnimalOwnerStatus] = useState<boolean>();
  const [ownerName, setOwnerName] = useState<any>();
  const [ownerCNP, setOwnerCNP] = useState<any>();
  const [ownerAddress, setOwnerAddress] = useState<any>();
  const [animalAddress, setAnimalAddress] = useState<any>();
  const [ownerPhone, setOwnerPhone] = useState<any>();
  const [attendantName, setAttendantName] = useState<any>();
  const [originAddress, setOriginAddress] = useState<any>();
  const [attendantPhone, setAttendantPhone] = useState<any>();
  const [animalSpecies, setAnimalSpecies] = useState<any>();
  const [animalSex, setAnimalSex] = useState<any>();
  const [animalPregnancy, setAnimalPregnancy] = useState<any>();
  const [animalBirthday, setAnimalBirthday] = useState<any>();
  const [animalDetails, setAnimalDetails] = useState<any>();
  const [animalWeight, setAnimalWeight] = useState<any>();
  const [animalHeight, setAnimalHeight] = useState<any>();
  const [animalTemper, setAnimalTemper] = useState<any>();
  const [animalTatooStatus, setAnimalTatooStatus] = useState<any>();
  const [animalEartag, setAnimalEartag] = useState<any>();
  const [animalMicrochipStatus, setAnimalMicrochipStatus] = useState<any>();
  const [animalMicrochipNumber, setAnimalMicrochipNumber] = useState<any>();
  const [animalCard, setAnimalCard] = useState<any>();
  const [animalPassportStatus, setAnimalPassportStatus] = useState<any>();
  const [animaleLeaveDate, setAnimalLeaveDate] = useState<any>();
  const [animalSterilization, setAnimalSterilization] = useState<any>();
  const [animalSterilizationDate, setAnimalSterilizationDate] = useState<any>();
  const [animalSterilizationObs, setAnimalSterilizationObs] = useState<any>();
  const [
    animalInternalDisinfestationStatus,
    setAnimalInternalDisinfestationStatus,
  ] = useState<boolean>();
  const [
    animalInternalDisinfestationDetails,
    setAnimalInternalDisinfestationDetails,
  ] = useState<any>();
  const [
    animalExternalDisinfestationStatus,
    setAnimalExternalDisinfestationStatus,
  ] = useState<boolean>();
  const [
    animalExternalDisinfestationDetails,
    setAnimalExternalDisinfestationDetails,
  ] = useState<any>();
  const [animalVaccineStatus, setAnimalVaccineStatus] = useState<boolean>();
  const [animalVaccineDetails, setAnimalVaccineDetails] = useState<any>();
  const [animalSurgeryStatus, setAnimalSurgeryStatus] = useState<boolean>();
  const [animalSurgeryDetails, setAnimalSurgeryDetails] = useState<any>();
  const [animalTreatmentStatus, setAnimalTreatmentStatus] = useState<any>();
  const [animalDiagnostic, setAnimalDiagnostic] = useState<any>();
  const [animalTreatmentDetails, setAnimalTreatmentDetails] = useState<any>();
  const [animalOtherObs, setAnimalOtherObs] = useState<any>();
  const [vetName, setVetName] = useState<any>();
  const [holdingObs, setHoldingObs] = useState<any>();
  const [donatedByEduxanima, setDonatedByEduxanima] = useState({
    chain: false,
    harness: false,
    collar: false,
    slidingWire: false,
    cage: false,
    food: false,
    others: false,
    othersDetails: '',
  });

  function onImagesChange(files: any) {
    setImages([...files]);
  }

  function onRegNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setRegNumber(value);
  }

  function onSheetDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setSheetDate(value);
  }

  function onSterilizationLocationChange(value: any) {
    setSterilizationLocation(value);
  }

  function onCageNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setCageNumber(value);
  }

  function onCageLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setCageLocation(value);
  }

  function onAnimalOwnerStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newHasOwnerState = value === '0' ? false : true;
    setAnimalOwnerStatus(newHasOwnerState);
  }

  function onOwnerNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerName(value);
  }

  function onOwnerCNPChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerCNP(value);
  }

  function onOwnerAddressChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerAddress(value);
  }

  function onAnimalAddressChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalAddress(value);
  }

  function onOwnerPhoneChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOwnerPhone(value);
  }

  function onAttendantNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAttendantName(value);
  }

  function onOriginAddressChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setOriginAddress(value);
  }

  function onAttendantPhoneChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAttendantPhone(value);
  }

  function onAnimalSpeciesChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSpecies(value);
  }

  function onAnimalSexChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSex(value);
  }

  function onAnimalPregnancyChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newAnimalPregnancy = value === '0' ? false : true;
    setAnimalPregnancy(newAnimalPregnancy);
  }

  function onAnimalBirthdayChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalBirthday(value);
  }

  function onAnimalDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalDetails(value);
  }

  function onAnimalWeightChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalWeight(value);
  }

  function onAnimalHeightChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalHeight(value);
  }

  function onAnimalTemperChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalTemper(value);
  }

  function onAnimalTatooStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newAnimalTatooStatus = value === '0' ? false : true;
    setAnimalTatooStatus(newAnimalTatooStatus);
  }

  function onAnimalEartagChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalEartag(value);
  }

  function onAnimalMicrochipStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipStatus(value);
  }

  function onAnimalMicrochipNumberChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalMicrochipNumber(value);
  }

  function onAnimalCardChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalCard(value);
  }

  function onAnimalPassportStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newAnimalPassportStatus = value === '0' ? false : true;
    setAnimalPassportStatus(newAnimalPassportStatus);
  }

  function onAnimalLeaveDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalLeaveDate(value);
  }

  function onAnimalSterilizationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSterilization(value);
  }

  function onAnimalSterilizationDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSterilizationDate(value);
  }

  function onAnimalSterilizationObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSterilizationObs(value);
  }

  function onAnimalInternalDisinfestationStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newInternalDisinfestationStatus = value === '0' ? false : true;
    setAnimalInternalDisinfestationStatus(newInternalDisinfestationStatus);
  }
  function onAnimalInternalDisinfestationDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalInternalDisinfestationDetails(value);
  }

  function onAnimalExternalDisinfestationStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newExternalDisinfestationStatus = value === '0' ? false : true;
    setAnimalExternalDisinfestationStatus(newExternalDisinfestationStatus);
  }
  function onAnimalExternalDisinfestationDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalExternalDisinfestationDetails(value);
  }
  function onAnimalVaccineStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newVaccineStatus = value === '0' ? false : true;
    setAnimalVaccineStatus(newVaccineStatus);
  }
  function onAnimalVaccineDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalVaccineDetails(value);
  }

  function onAnimalSurgeryStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newSurgeryStatus = value === '0' ? false : true;
    setAnimalSurgeryStatus(newSurgeryStatus);
  }

  function onAnimalSurgeryDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalSurgeryDetails(value);
  }

  function onAnimalTreatmentStatusChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    const newTreatmentStatus = value === '0' ? false : true;
    setAnimalTreatmentStatus(newTreatmentStatus);
  }

  function onAnimalDiagnosticChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalDiagnostic(value);
  }

  function onAnimalTreatmentDetailsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalTreatmentDetails(value);
  }

  function onAnimalOtherObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setAnimalOtherObs(value);
  }

  function onVetNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setVetName(value);
  }

  function onHoldingObsChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setHoldingObs(value);
  }

  function onDonatedByEduxanimaChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const name = (event.target as HTMLInputElement).name;
    const checked = (event.target as HTMLInputElement).checked;
    setDonatedByEduxanima({
      ...donatedByEduxanima,
      [name]: checked,
    });
  }

  function onDonationOthers(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;

    setDonatedByEduxanima({
      ...donatedByEduxanima,
      othersDetails: value,
    });
  }

  const { chain, harness, collar, slidingWire, cage, food, others } =
    donatedByEduxanima;

  const variables = {
    images,
    regNumber,
    sheetDate,
    sterilizationLocation: {
      location: sterilizationLocation,
    },
    cageNumber,
    cageLocation: {
      location: cageLocation,
    },
    animalOwnerStatus,
    ownerName,
    ownerCNP,
    ownerAddress,
    animalAddress,
    ownerPhone,
    attendantName,
    originAddress,
    attendantPhone,
    animalSpecies,
    animalSex,
    animalPregnancy,
    animalBirthday,
    animalDetails,
    animalWeight,
    animalHeight,
    animalTemper,
    animalTatooStatus,
    animalEartag,
    animalMicrochipStatus,
    animalMicrochipNumber,
    animalCard,
    animalPassportStatus,
    animaleLeaveDate,
    animalSterilization,
    animalSterilizationDate,
    animalSterilizationObs,
    animalInternalDisinfestationStatus,
    animalInternalDisinfestationDetails,
    animalExternalDisinfestationStatus,
    animalExternalDisinfestationDetails,
    animalVaccineStatus,
    animalVaccineDetails,
    animalSurgeryStatus,
    animalSurgeryDetails,
    animalTreatmentStatus,
    animalDiagnostic,
    animalTreatmentDetails,
    animalOtherObs,
    vetName,
    holdingObs,
    donatedByEduxanima,
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <br />
          <form
            noValidate
            id="register-animal-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              {/* ADD IMAGE FILE UPLOADS */}
              <Grid item xs={12}>
                <DropzoneArea
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  showPreviewsInDropzone={true}
                  showFileNames={true}
                  maxFileSize={5000000}
                  initialFiles={images}
                  filesLimit={2}
                  dropzoneText="Încarcă fotografii"
                  dropzoneClass={classes.uploadArea}
                  onChange={(files) => onImagesChange(files)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="regNumber"
                  label="Nr. Crt."
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => onRegNumberChange(e)}
                  className={classes.requiredFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="sheetDate"
                  label="Dată fişă"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                  onChange={(e) => onSheetDateChange(e)}
                  className={classes.requiredFields}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  id="sterilizationLocation"
                  label="Locaṭie sterilizare / alt act medical"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => onSterilizationLocationChange(e)}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <GoogleMapsAutocomplete
                  onChange={(e: any, newValue: any) =>
                    onSterilizationLocationChange(newValue)
                  }
                  value={sterilizationLocation}
                  required={true}
                  className={classes.requiredFields}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="cageNumber"
                  label="Cuşcă Nr. maşină de transport"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onCageNumberChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="cageLocation"
                  label="Locaṭie cazare (animale Eduxanima: Triva/foster/sediu etc)"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onCageLocationChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalOwnerStatus"
                  label="Are proprietar?"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  className={classes.requiredFields}
                  onChange={(e) => onAnimalOwnerStatusChange(e)}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>

              <br />

              {animalOwnerStatus && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Date despre proprietar
                    </Typography>
                  </Grid>
                  <Divider />

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerName"
                      label="Nume şi Prenume proprietar"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerNameChange(e)}
                      // required={animalOwnerStatus}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerCNP"
                      label="CNP"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerCNPChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerAddress"
                      label="Adresă proprietar"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerAddressChange(e)}
                      // required={animalOwnerStatus}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="animalAddress"
                      label="Adresa unde stă animalul"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onAnimalAddressChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="ownerPhone"
                      label="Telefon proprietar"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => onOwnerPhoneChange(e)}
                    />
                  </Grid>
                </>
              )}
              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Date despre persoana care aduce / însoţeşte animalul
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={6}>
                <TextField
                  id="attendantName"
                  label="Nume şi Prenume însoţitor"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAttendantNameChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="originAddress"
                  label="Adresa de provenienţă"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onOriginAddressChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="attendantPhone"
                  label="Telefon însoţitor"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAttendantPhoneChange(e)}
                />
              </Grid>

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Date despre animal
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalSpecies"
                  label="Specie"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  className={classes.requiredFields}
                  onChange={(e) => onAnimalSpeciesChange(e)}
                >
                  <MenuItem value="dog">Caine</MenuItem>
                  <MenuItem value="cat">Pisica</MenuItem>
                  <MenuItem value="other">Alta specie</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalSex"
                  label="Sex"
                  variant="outlined"
                  select
                  fullWidth
                  required
                  className={classes.requiredFields}
                  onChange={(e) => onAnimalSexChange(e)}
                >
                  <MenuItem value="male">Mascul</MenuItem>
                  <MenuItem value="female">Femela</MenuItem>
                </TextField>
              </Grid>
              {animalSex === 'female' && (
                <Grid item xs={6} sm={3}>
                  <TextField
                    id="animalPregnancy"
                    label="Gestantă?"
                    variant="outlined"
                    select
                    fullWidth
                    onChange={(e) => onAnimalPregnancyChange(e)}
                  >
                    <MenuItem value="0">Nu</MenuItem>
                    <MenuItem value="1">Da</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalBirthday"
                  label="Data naşterii"
                  variant="outlined"
                  fullWidth
                  // required
                  onChange={(e) => onAnimalBirthdayChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalDetails"
                  label="Semnalmente (culoare, talie, semne particulare)"
                  variant="outlined"
                  multiline
                  fullWidth
                  rows={2}
                  // required
                  onChange={(e) => onAnimalDetailsChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalWeight"
                  label="Greutate"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalWeightChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalHeight"
                  label="Înălţime (până la greabăn)"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalHeightChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalTemper"
                  label="Temperament"
                  variant="outlined"
                  multiline
                  rows={2}
                  fullWidth
                  onChange={(e) => onAnimalTemperChange(e)}
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="animalTatooStatus"
                  label="Tatuaj"
                  variant="outlined"
                  select
                  fullWidth
                  // required
                  onChange={(e) => onAnimalTatooStatusChange(e)}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={8} sm={4}>
                <TextField
                  id="animalEartag"
                  label="Crotal"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalEartagChange(e)}
                />
              </Grid>
              <Grid item xs={4} sm={2}>
                <TextField
                  id="animalMicrochipStatus"
                  label="Microcip"
                  variant="outlined"
                  select
                  fullWidth
                  // required
                  onChange={(e) => onAnimalMicrochipStatusChange(e)}
                >
                  <MenuItem value="eduxanima">Eduxanima</MenuItem>
                  <MenuItem value="other">Alt cabinet</MenuItem>
                  <MenuItem value="no">Nu</MenuItem>
                </TextField>
              </Grid>
              {animalMicrochipStatus && animalMicrochipStatus !== 'no' && (
                <Grid item xs={8} sm={4}>
                  <TextField
                    id="animalMicrochipNumber"
                    label="Număr microcip"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnimalMicrochipNumberChange(e)}
                    InputProps={{ inputProps: { min: 15, max: 15 } }}
                    // required={
                    //   animalMicrochipStatus && animalMicrochipStatus !== 'no'
                    // }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={5}>
                <TextField
                  id="animalCard"
                  label="Serie şi nr. carnet"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalCardChange(e)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  id="animalPassportStatus"
                  label="Paşaport"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalPassportStatusChange(e)}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="animalLeaveDate"
                  label="Poate pleca începând cu data"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => onAnimalLeaveDateChange(e)}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <TextField
                  id="animalSterilization"
                  label="Sterilizare"
                  variant="outlined"
                  select
                  fullWidth
                  // required
                  onChange={(e) => onAnimalSterilizationChange(e)}
                >
                  <MenuItem value="eduxanima">Eduxanima</MenuItem>
                  <MenuItem value="other">Alt cabinet</MenuItem>
                  <MenuItem value="no">Nu</MenuItem>
                  <MenuItem value="unknown">Nu se ştie</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  id="animalSterilizationDate"
                  label="Dată sterilizare"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => onAnimalSterilizationDateChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalSterilizationObs"
                  label="Observaţii sterilizare"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalSterilizationObsChange(e)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalInternalDisinfestationStatus"
                  label="Deparazitare internă"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) =>
                    onAnimalInternalDisinfestationStatusChange(e)
                  }
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalInternalDisinfestationStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalInternalDisinfestationDetails"
                    label="Detalii deparazitare internă"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      onAnimalInternalDisinfestationDetailsChange(e)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalExternalDisinfestationStatus"
                  label="Deparazitare externă"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) =>
                    onAnimalExternalDisinfestationStatusChange(e)
                  }
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalExternalDisinfestationStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalExternalDisinfestationDetails"
                    label="Detalii deparazitare externă"
                    variant="outlined"
                    fullWidth
                    onChange={(e) =>
                      onAnimalExternalDisinfestationDetailsChange(e)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalVaccineStatus"
                  label="Vaccinări"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalVaccineStatusChange(e)}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalVaccineStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalVaccineDetais"
                    label="Detalii vaccinări"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnimalVaccineDetailsChange(e)}
                  />
                </Grid>
              )}

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Alte intervenţii chirurgicale
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={6}>
                <TextField
                  id="animalSurgeryStatus"
                  label="S-au efectuat alte intervenţii chirurgicale?"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalSurgeryStatusChange(e)}
                  required
                  className={classes.requiredFields}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              {animalSurgeryStatus && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="animalSurgeryDetails"
                    label="Operaţii chirurgicale efectuate"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnimalSurgeryDetailsChange(e)}
                  />
                </Grid>
              )}

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Alte tratamente
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={4}>
                <TextField
                  id="animalTreatmentStatus"
                  label="S-au efectuat tratamente medicale?"
                  variant="outlined"
                  select
                  fullWidth
                  onChange={(e) => onAnimalTreatmentStatusChange(e)}
                >
                  <MenuItem value="0">Nu</MenuItem>
                  <MenuItem value="1">Da</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id="animalDiagnostic"
                  label="Diagnostic"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onAnimalDiagnosticChange(e)}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalTreatmentDetails"
                  label="Tratamente efectuate şi observaţii"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalTreatmentDetailsChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="animalOtherObs"
                  label="Alte observaţii"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={(e) => onAnimalOtherObsChange(e)}
                />
              </Grid>

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Medic veterinar
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={6}>
                <TextField
                  id="vetName"
                  label="Nume şi prenume medic veterinar"
                  variant="outlined"
                  fullWidth
                  // required
                  onChange={(e) => onVetNameChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="holdingObs"
                  label="Observaţii referitoare la condiţiile de deţinere"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => onHoldingObsChange(e)}
                />
              </Grid>

              <br />

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Donate de Eduxanima
                </Typography>
              </Grid>
              <Divider />

              <Grid item xs={12} sm={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={chain}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="chain"
                      />
                    }
                    label="lanţ"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={harness}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="harness"
                      />
                    }
                    label="ham"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={collar}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="collar"
                      />
                    }
                    label="zgardă"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={slidingWire}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="slidingWire"
                      />
                    }
                    label="sârmă culisantă"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cage}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="cage"
                      />
                    }
                    label="cuşcă"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={food}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="food"
                      />
                    }
                    label="hrană"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={others}
                        onChange={(e) => onDonatedByEduxanimaChange(e)}
                        name="others"
                      />
                    }
                    label="altele"
                  />
                </FormGroup>
              </Grid>
              {others && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="othersDetails"
                    label="Detalii pentru alte donaţii"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(e) => onDonationOthers(e)}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-animal-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
