import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import { Container, TextField, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DateFnsAdapter from '@date-io/date-fns';

import { DatePicker } from '@mui/x-date-pickers';

import { GoogleMapsAutocomplete } from '../components/GoogleMapsAutocomplete';
import { Loading } from '../components/Loading';
import { MainContainer } from '../components/MainContainer';
import { AnimalsResultsChart } from '../components/AnimalsResultsChart';
import { AnimalsResultsTable } from '../components/AnimalsResultsTable';

import { GET_STERILIZED_ANIMALS } from '../gql/queries/getSterilizedAnimals';
import { GET_ANIMALS_WITH_SURGERIES } from '../gql/queries/getAnimalsWithSurgeries';
import { GET_ANIMALS_WITH_TREATMENT } from '../gql/queries/getAnimalsWithTreatment';
import { GET_NOT_REGISTERED_ANIMALS_WITH_TREATMENT } from '../gql/queries/getNotRegisteredAnimalsWithTreatment';

interface ResultsProps extends RouteComponentProps {
  me?: any;
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
}));

export const Results: React.FC<ResultsProps> = ({ me }) => {
  const classes = useStyles();
  const today = new Date();
  const dateFns = new DateFnsAdapter();

  const newDate = dateFns.formatByString(today, 'yyyy-MM-dd');
  const initialDate = new Date('2021-01-01');
  const monthBeginning = dateFns.formatByString(today, 'yyyy-MM-01');

  const [startDate, setStartDate] = useState<any>(monthBeginning);
  const [endDate, setEndDate] = useState<any>(newDate);
  const [sterilizationLocation, setSterilizationLocation] = useState<any>();

  const titleStartDate = dateFns.formatByString(
    new Date(startDate),
    'dd/MM/yyyy'
  );

  const titleEndDate = dateFns.formatByString(new Date(endDate), 'dd/MM/yyyy');

  function onStartDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setStartDate(newValue);
  }

  function onEndDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setEndDate(newValue);
  }

  function onSterilizationLocationChange(value: any) {
    setSterilizationLocation(value);
  }

  const {
    data: sterilizedData,
    loading: sterilizedLoading,
    error: sterilizedError,
  } = useQuery(GET_STERILIZED_ANIMALS, {
    variables: {
      startDate,
      endDate,
      location: sterilizationLocation,
    },
    fetchPolicy: 'network-only',
  });
  const {
    data: surgeriesData,
    loading: surgeriesLoading,
    error: surgeriesError,
  } = useQuery(GET_ANIMALS_WITH_SURGERIES, {
    variables: {
      startDate,
      endDate,
      location: sterilizationLocation,
    },
    fetchPolicy: 'network-only',
  });
  const {
    data: treatmentData,
    loading: treatmentLoading,
    error: treatmentError,
  } = useQuery(GET_ANIMALS_WITH_TREATMENT, {
    variables: {
      startDate,
      endDate,
      location: sterilizationLocation,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: notRegTreatmentData,
    loading: notRegTreatmentLoading,
    error: notRegTreatmentError,
  } = useQuery(GET_NOT_REGISTERED_ANIMALS_WITH_TREATMENT, {
    variables: {
      startDate,
      endDate,
      location: sterilizationLocation,
    },
    fetchPolicy: 'network-only',
  });

  if (
    sterilizedLoading ||
    surgeriesLoading ||
    treatmentLoading ||
    notRegTreatmentLoading
  )
    return <Loading />;
  if (
    sterilizedError ||
    surgeriesError ||
    treatmentError ||
    notRegTreatmentError
  ) {
    return <p>Error</p>;
  }

  const { getSterilizedAnimals } = sterilizedData;
  const { getAnimalsWithSurgeries } = surgeriesData;
  const { getAnimalsWithTreatment } = treatmentData;
  const { getNotRegisteredAnimalsWithTreatment } = notRegTreatmentData;

  return (
    <MainContainer maxWidth="xl">
      <Container className={classes.cardGrid}>
        <br />
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="Începând cu"
                value={startDate}
                onChange={onStartDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Până la"
                value={endDate}
                onChange={onEndDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
          </Grid>
          <br />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6}>
              <GoogleMapsAutocomplete
                onChange={(e: any, newValue: any) =>
                  onSterilizationLocationChange(newValue)
                }
                value={sterilizationLocation}
              />
            </Grid>
          </Grid>
        </Container>

        <br />

        <AnimalsResultsTable
          title={`Animale sterilizate ${
            sterilizationLocation
              ? `în: ${sterilizationLocation}`
              : `în toate locaţiile`
          }`}
          subtitle={`(${titleStartDate} - ${titleEndDate})`}
          animals={getSterilizedAnimals}
          notRegAnimals=""
          maxWidth="md"
          tableId="sterilizedPdf"
        />
        <AnimalsResultsChart
          title={`Animale sterilizate ${
            sterilizationLocation
              ? `în: ${sterilizationLocation}`
              : `în toate locaţiile`
          }`}
          subtitle={`(${titleStartDate} - ${titleEndDate})`}
          animals={getSterilizedAnimals}
          maxWidth="md"
        />
        <br />
        <br />

        <AnimalsResultsTable
          title={`Animale operate ${
            sterilizationLocation
              ? `în: ${sterilizationLocation}`
              : `în toate locaţiile`
          }`}
          subtitle={`(${titleStartDate} - ${titleEndDate})`}
          animals={getAnimalsWithSurgeries}
          notRegAnimals=""
          maxWidth="md"
          tableId="surgeriesPdf"
        />
        <AnimalsResultsChart
          title={`Animale operate ${
            sterilizationLocation
              ? `în: ${sterilizationLocation}`
              : `în toate locaţiile`
          }`}
          subtitle={`(${titleStartDate} - ${titleEndDate})`}
          animals={getAnimalsWithSurgeries}
          maxWidth="md"
        />
        <br />
        <br />

        <AnimalsResultsTable
          title={`Animale tratate ${
            sterilizationLocation
              ? `în: ${sterilizationLocation}`
              : `în toate locaţiile`
          }`}
          subtitle={`(${titleStartDate} - ${titleEndDate})`}
          animals={getAnimalsWithTreatment}
          notRegAnimals={getNotRegisteredAnimalsWithTreatment}
          maxWidth="md"
          hasButton={true}
          startDate={startDate}
          endDate={endDate}
          tableId="treatmentPdf"
        />
        <AnimalsResultsChart
          title={`Animale tratate ${
            sterilizationLocation
              ? `în: ${sterilizationLocation}`
              : `în toate locaţţile`
          }`}
          subtitle={`(${titleStartDate} - ${titleEndDate})`}
          animals={getAnimalsWithTreatment}
          maxWidth="md"
        />
      </Container>
    </MainContainer>
  );
};
