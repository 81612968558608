import { gql } from '@apollo/client';

export const GET_INVENTORIES = gql`
  query getInventories($startDate: String, $endDate: String) {
    getInventories(input: { startDate: $startDate, endDate: $endDate }) {
      id
      product {
        id
        name
        type
        sku
        uom
        description
        active
        supplier {
          id
          name
          description
          location {
            location
            lat
            lng
          }
          phone
        }
      }
      date
      quantity
      invoiceNumber
      details
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
    }
  }
`;
