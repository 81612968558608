import React, { useState, useRef, useEffect } from 'react';
import throttle from 'lodash.throttle';
import parse from 'autosuggest-highlight/parse';

import { TextField, Grid, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { LocationOn } from '@mui/icons-material';

import { loadScript } from '../utils/loadScript';

const GOOGLE_MAPS_API_KEY = 'AIzaSyCWMJhNwVNZN96IjeQZZmdSIz9g3trgF2Q';
const COUNTRY = 'ro';

export enum PLACETYPE {
  ALL = 0,
  GEOCODE = 'geocode',
  ADDRESS = 'address',
  ESTABLISHMENT = 'establishment',
  REGIONS = '(regions)',
  CITIES = '(cities)',
}

const autocompleteService: any = { current: null };

// const useStyles = makeStyles((theme) => ({
//   icon: {
//     color: theme.palette.text.secondary,
//     marginRight: theme.spacing(2),
//   },
// }));

interface GoogleMapsAutocompleteProps {
  label?: string;
  types?: [PLACETYPE] | PLACETYPE;
  value: any;
  onChange: any;
  textFieldVariant?: any;
  required?: any;
  className?: any;
}

export const GoogleMapsAutocomplete: React.FC<GoogleMapsAutocompleteProps> = ({
  label = 'Adaugă o localitate',
  types = [PLACETYPE.CITIES],
  value,
  onChange,
  textFieldVariant = 'outlined',
  required,
  className,
}) => {
  const [loaded, setLoaded] = useState(false);
  // const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function asyncLoadScript() {
      try {
        await loadScript({
          src: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          id: 'google-maps',
          root: document.head,
        });

        console.log('google maps script loaded!!!');
      } catch (err) {
        console.log('google maps script already exists!!!');
      }

      setLoaded(true);
    }

    asyncLoadScript();
  }, []);

  const fetch = React.useMemo(
    () =>
      throttle((request: any, callback: any) => {
        request.componentRestrictions = { country: COUNTRY };
        request.fields = ['structured_formatting', 'geometry'];
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    if (!loaded) return;

    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? ([value] as any) : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        types,
      },
      (results: any) => {
        if (active) {
          let newOptions: any = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [loaded, inputValue, fetch]);

  if (!loaded) return <></>;

  return (
    <Autocomplete
      getOptionLabel={(option: any) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      key={value}
      onChange={(event: any, newValue: any) => {
        console.log('onChange');
        console.log(newValue);

        setOptions(newValue ? [newValue, ...options] : (options as any));
        onChange(event, newValue?.description);
      }}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={textFieldVariant}
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-autocomplete-field',
          }}
          required={required}
          className={className}
        />
      )}
      renderOption={(props: any, option: any) => {
        if (!option || !option.structured_formatting) {
          return <></>;
        }

        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <Grid container alignItems="center" {...props}>
            <Grid item>
              <LocationOn />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
