import { gql } from '@apollo/client';

export const GET_ANIMALS_ADOPTED = gql`
  query GetAnimalsAdopted($startDate: String, $endDate: String) {
    getAnimalsAdopted(input: { startDate: $startDate, endDate: $endDate }) {
      id
      regNumber
      animalEnteredDate
      animalStatus {
        entered
        adopted
        intermediated
      }
      animalSpecies
      animalSex
      animalName
      animalMicrochipStatus
      animalMicrochipNumber
      animalLocation
      animalLeaveDate
      animalOtherObs
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      lastUpdate
      updatedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
