import { gql, ApolloCache, Resolvers } from '@apollo/client';

export const typeDefs = gql`
  extend type RootQuery {
    isLoggedIn: Boolean!
    type: String
  }

  extend type RootQuery {
    alertOpen: Boolean
    alertSeverity: String
    alertMessage: String
  }

  extend type RootQuery {
    navigationAction: String
  }
`;

type ResolverFn = (
  parent: any,
  args: any,
  { cache }: { cache: ApolloCache<any> }
) => any;

interface ResolverMap {
  [field: string]: ResolverFn;
}

interface AppResolvers extends Resolvers {
  Query: ResolverMap;
  Mutation: ResolverMap;
}

export const resolvers: AppResolvers = {
  Query: {},
  Mutation: {},
};
