import React, { useState } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { Grid, TextField, Button, Box } from '@mui/material';
import isEmail from 'validator/lib/isEmail';

import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';

interface SignInProps {
  signIn: (a: { variables: any }) => void;
  submitted: boolean;
  onSubmittedChange: any;
}

export const SignInForm: React.FC<SignInProps> = ({
  signIn,
  submitted,
  onSubmittedChange,
}) => {
  const client: ApolloClient<any> = useApolloClient();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function onEmailChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setEmail(value);
  }
  function onPasswordChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setPassword(value);
  }

  async function onSignInSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      onSubmittedChange(true);

      if (!email) throw new Error('Adresa ta de email lipseşte.');
      if (!password) throw new Error('Parola ta lipseşte.');
      if (!isEmail(email))
        throw new Error('Formatul emailului tău este incorect.');

      await signIn({
        variables: {
          email,
          password,
        },
      });
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  const renderForm = (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Adresa de email"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(e) => onEmailChange(e)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Parola"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={(e) => onPasswordChange(e)}
      />
    </>
  );

  const renderActionButton = (
    <Box flex="flex" flexDirection="row">
      <Button
        type="submit"
        form="signin-form"
        variant="contained"
        color="primary"
        fullWidth
      >
        Autentifică-te
      </Button>
    </Box>
  );

  return (
    <Grid container component="main" justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <form noValidate id="signin-form" onSubmit={(e) => onSignInSubmit(e)}>
          {renderForm}
          <br />
          {renderActionButton}
        </form>
      </Grid>
    </Grid>
  );
};
