import { gql } from '@apollo/client';

export const UPDATE_SUPPLIER = gql`
  mutation updateSupplier(
    $id: ID!
    $name: String!
    $description: String
    $location: LocationInput
    $phone: String
  ) {
    updateSupplier(
      id: $id
      input: {
        name: $name
        description: $description
        location: $location
        phone: $phone
      }
    ) {
      id
      name
      description
      location {
        lat
        lng
        location
      }
      phone
      createdBy {
        id
        email
        type
        firstName
        lastName
      }
      createdAt
      updatedBy {
        id
        email
        type
        firstName
        lastName
      }
      lastUpdate
    }
  }
`;
