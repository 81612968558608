import { gql } from '@apollo/client';

export const CREATE_SUPPLIER = gql`
  mutation createSupplier(
    $name: String!
    $description: String
    $location: LocationInput
    $phone: String
  ) {
    createSupplier(
      input: {
        name: $name
        description: $description
        location: $location
        phone: $phone
      }
    ) {
      id
      name
      description
      location {
        location
        lat
        lng
      }
      phone
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
    }
  }
`;
