import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import {
  useQuery,
  ApolloClient,
  useApolloClient,
  useMutation,
} from '@apollo/client';

// import { makeStyles } from '@mui/styles';
import { DeleteOutline, EditOutlined, Add } from '@mui/icons-material';
import MaterialTable from 'material-table';
import DateFnsAdapter from '@date-io/date-fns';

import { MainContainer } from './MainContainer';
import { Header } from './Header';
import { Loading } from './Loading';
import { AreYouSureDialog } from './AreYouSureDialog';
import { FloatingButton } from './FloatingButton';
import { AddProductDialog } from './AddProductDialog';
import { EditProductDialog } from './EditProductDialog';

import { PatchedPagination } from '../utils/PatchedPagination';

import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { GET_PRODUCTS } from '../gql/queries/getProducts';
import { CREATE_PRODUCT } from '../gql/mutations/CreateProduct';
import { UPDATE_PRODUCT } from '../gql/mutations/UpdateProduct';
import { DELETE_PRODUCT } from '../gql/mutations/DeleteProduct';

interface ProductsTableProps extends RouteComponentProps {
  me?: any;
}

// const useStyles = makeStyles((theme) => ({
//   cardGrid: {
//     backgroundColor: theme.palette.background.paper,
//     paddingTop: theme.spacing(0),
//     paddingBottom: theme.spacing(8),
//   },
// }));

const dateFns = new DateFnsAdapter();

const getColumns: any = ({ me }: any) => [
  {
    title: 'Nume',
    field: 'name',
  },
  {
    title: 'SKU',
    field: 'sku',
  },
  {
    title: 'U.M.',
    field: 'uom',
    render: ({ uom }: any) => {
      switch (uom) {
        case 'DROPPER':
          return 'Pipetă';
        case 'TABLET':
          return 'Tabletă';
        case 'VIAL':
          return 'Flacon';
        case 'PIECE':
          return 'Bucată';
        case 'AMPOULE':
          return 'Fiolă';
        case 'DOSE':
          return 'Doză';
      }
    },
  },
  {
    title: 'Descriere',
    field: 'description',
  },
  {
    title: 'Furnizor',
    field: 'supplier.name',
  },
  {
    title: 'Creat de',
    field: 'createdBy.email',
  },
  {
    title: 'La data de',
    field: 'createdAt',
    render: ({ createdAt }: any) => {
      const newDate = new Date(createdAt);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
  {
    title: 'Modificat de',
    field: 'updatedBy.email',
  },
  {
    title: 'La data de',
    field: 'lastUpdate',
    render: ({ lastUpdate }: any) => {
      const newDate = new Date(lastUpdate);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
];
export const ProductsTable: React.FC<ProductsTableProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();
  // const classes = useStyles();

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();

  const title = 'Eşti sigur că vrei să ştergi această înregistrare?';
  const content =
    'Ştergerea este definitivă şi această înregistrare nu mai poate fi adusă înapoi.';

  const yes = 'Şterge';
  const no = 'Închide';

  const { data, loading, error } = useQuery(GET_PRODUCTS);

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function removeSupplierDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedProduct(rowData);
    setOpenDeleteDialog(true);
  }

  function editSupplierDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedProduct(rowData);
    setOpenEditDialog(true);
  }

  function onCloseEditDialog() {
    setOpenEditDialog(false);
  }

  function onCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  const [
    createProduct,
    { loading: createProductLoading, error: createProductError },
  ] = useMutation(CREATE_PRODUCT, {
    update(cache, { data: { createProduct } }: any) {
      const { getProducts }: any = cache.readQuery({
        query: GET_PRODUCTS,
      });

      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          getProducts: [...getProducts, createProduct],
        },
      });
    },
  });

  const [
    updateProduct,
    { loading: updateProductLoading, error: updateProductError },
  ] = useMutation(UPDATE_PRODUCT, {
    update(cache, { data: { updateProduct } }: any) {
      const { getProducts }: any = cache.readQuery({
        query: GET_PRODUCTS,
      });
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          getProducts: [
            ...getProducts.filter(
              (product: any) => product.id !== updateProduct.id
            ),
            updateProduct,
          ],
        },
      });
    },
  });

  const [
    deleteProduct,
    { loading: deleteProductLoading, error: deleteProductError },
  ] = useMutation(DELETE_PRODUCT, {
    update(cache, { data: deleteSupplier }: any) {
      const { getProducts }: any = cache.readQuery({
        query: GET_PRODUCTS,
      });
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: {
          getProducts: getProducts.filter(
            (product: any) => product.id !== selectedProduct?.id
          ),
        },
      });
    },
  });

  async function registerProduct(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.name)
        throw new Error('Numele produsului nu este completat.');
      if (!variables.uom)
        throw new Error('Unitatea de măsură nu este completată.');
      if (!variables.supplierId)
        throw new Error('Furnizorul nu este completat.');

      await createProduct({ variables });

      return onCloseAddDialog();
    } catch (err: any) {
      console.log('Error: ', err);
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onEditProduct(
    event: React.FormEvent<HTMLFormElement>,
    input: any
  ) {
    event.preventDefault();
    try {
      if (!input) throw new Error('Nu există variabile.');
      if (!input.name)
        throw new Error('Numele furnizorului nu este completat.');

      const variables: any = {
        id: selectedProduct?.id,
        ...input,
      };

      await updateProduct({ variables });

      return onCloseEditDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onRemoveProduct() {
    const variables: any = { id: selectedProduct?.id };
    await deleteProduct({ variables });
    setOpenDeleteDialog(false);
  }

  if (
    loading ||
    createProductLoading ||
    updateProductLoading ||
    deleteProductLoading
  )
    return <Loading />;
  if (error || createProductError || updateProductError || deleteProductError)
    return (
      <p>{`Error: ${
        error || createProductError || updateProductError || deleteProductError
      }`}</p>
    );

  const products = data?.getProducts.map((o: any) => ({
    ...o,
  }));

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />

      <MaterialTable
        title={`Furnizori`}
        columns={getColumns({ me })}
        data={products}
        components={{
          Pagination: PatchedPagination,
        }}
        actions={[
          {
            icon: DeleteOutline,
            tooltip: 'Şterge înregistrarea',
            onClick: (event, rowData) => removeSupplierDialog(event, rowData),
            disabled: me.type === 'VIEWER',
          },
          {
            icon: EditOutlined,
            tooltip: 'Editează înregistrarea',
            onClick: (event, rowData) => editSupplierDialog(event, rowData),
            disabled: me.type === 'VIEWER',
          },
        ]}
      />

      <FloatingButton icon={<Add />} onClick={() => openAdd()} />
      <AddProductDialog
        title="Adaugă un produs nou"
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={registerProduct}
      />
      <EditProductDialog
        title="Editează produsul"
        open={openEditDialog}
        onClose={onCloseEditDialog}
        onSave={onEditProduct}
        product={selectedProduct}
      />
      <AreYouSureDialog
        title={title}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        onYes={onRemoveProduct}
      />
    </MainContainer>
  );
};
