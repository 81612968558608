import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import {
  useQuery,
  ApolloClient,
  useApolloClient,
  useMutation,
} from '@apollo/client';

// import { makeStyles } from '@mui/styles';
import { DeleteOutline, EditOutlined, Add } from '@mui/icons-material';
import MaterialTable from 'material-table';
import DateFnsAdapter from '@date-io/date-fns';

import { MainContainer } from './MainContainer';
import { Header } from './Header';
import { Loading } from './Loading';
import { AreYouSureDialog } from './AreYouSureDialog';
import { FloatingButton } from './FloatingButton';
import { AddSupplierDialog } from './AddSupplierDialog';
import { EditSupplierDialog } from './EditSupplierDialog';

import { PatchedPagination } from '../utils/PatchedPagination';

import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { GET_SUPPLIERS } from '../gql/queries/getSuppliers';
import { CREATE_SUPPLIER } from '../gql/mutations/CreateSupplier';
import { UPDATE_SUPPLIER } from '../gql/mutations/UpdateSupplier';
import { DELETE_SUPPLIER } from '../gql/mutations/DeleteSupplier';

interface SuppliersTableProps extends RouteComponentProps {
  me?: any;
}

// const useStyles = makeStyles((theme) => ({
//   cardGrid: {
//     backgroundColor: theme.palette.background.paper,
//     paddingTop: theme.spacing(0),
//     paddingBottom: theme.spacing(8),
//   },
// }));

const dateFns = new DateFnsAdapter();

const getColumns: any = ({ me }: any) => [
  {
    title: 'Nume',
    field: 'name',
  },
  {
    title: 'Descriere',
    field: 'description',
  },
  {
    title: 'Locaţie',
    field: 'location.location',
  },
  {
    title: 'Telefon',
    field: 'phone',
  },
  {
    title: 'Creat de',
    field: 'createdBy.email',
  },
  {
    title: 'La data de',
    field: 'createdAt',
    render: ({ createdAt }: any) => {
      const newDate = new Date(createdAt);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
  {
    title: 'Modificat de',
    field: 'updatedBy.email',
  },
  {
    title: 'La data de',
    field: 'lastUpdate',
    render: ({ lastUpdate }: any) => {
      const newDate = new Date(lastUpdate);
      return dateFns.formatByString(newDate, 'HH:mm dd/MM/yyyy');
    },
  },
];
export const SuppliersTable: React.FC<SuppliersTableProps> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();
  // const classes = useStyles();

  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState<any>();

  const title = 'Eşti sigur că vrei să ştergi această înregistrare?';
  const content =
    'Ştergerea este definitivă şi această înregistrare nu mai poate fi adusă înapoi.';

  const yes = 'Şterge';
  const no = 'Închide';

  const { data, loading, error } = useQuery(GET_SUPPLIERS);

  function openAdd() {
    setOpenAddDialog(true);
  }

  function onCloseAddDialog() {
    setOpenAddDialog(false);
  }

  function removeSupplierDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedSupplier(rowData);
    setOpenDeleteDialog(true);
  }

  function editSupplierDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedSupplier(rowData);
    setOpenEditDialog(true);
  }

  function onCloseEditDialog() {
    setOpenEditDialog(false);
  }

  function onCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  const [
    createSupplier,
    { loading: createSupplierLoading, error: createSupplierError },
  ] = useMutation(CREATE_SUPPLIER, {
    update(cache, { data: { createSupplier } }: any) {
      const { getSuppliers }: any = cache.readQuery({
        query: GET_SUPPLIERS,
      });

      cache.writeQuery({
        query: GET_SUPPLIERS,
        data: {
          getSuppliers: [...getSuppliers, createSupplier],
        },
      });
    },
  });

  const [
    updateSupplier,
    { loading: updateSupplierLoading, error: updateSupplierError },
  ] = useMutation(UPDATE_SUPPLIER, {
    update(cache, { data: { updateSupplier } }: any) {
      const { getSuppliers }: any = cache.readQuery({
        query: GET_SUPPLIERS,
      });
      cache.writeQuery({
        query: GET_SUPPLIERS,
        data: {
          getSuppliers: [
            ...getSuppliers.filter(
              (supplier: any) => supplier.id !== updateSupplier.id
            ),
            updateSupplier,
          ],
        },
      });
    },
  });

  const [
    deleteSupplier,
    { loading: deleteSupplierLoading, error: deleteSupplierError },
  ] = useMutation(DELETE_SUPPLIER, {
    update(cache, { data: deleteSupplier }: any) {
      const { getSuppliers }: any = cache.readQuery({
        query: GET_SUPPLIERS,
      });
      cache.writeQuery({
        query: GET_SUPPLIERS,
        data: {
          getSuppliers: getSuppliers.filter(
            (supplier: any) => supplier.id !== selectedSupplier?.id
          ),
        },
      });
    },
  });

  async function registerSupplier(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.name)
        throw new Error('Numele furnizorului nu este completat.');

      await createSupplier({ variables });

      return onCloseAddDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onEditSupplier(
    event: React.FormEvent<HTMLFormElement>,
    input: any
  ) {
    event.preventDefault();
    try {
      if (!input) throw new Error('Nu există variabile.');
      if (!input.name)
        throw new Error('Numele furnizorului nu este completat.');

      const variables: any = {
        id: selectedSupplier?.id,
        ...input,
      };

      await updateSupplier({ variables });

      return onCloseEditDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function onRemoveSupplier() {
    const variables: any = { id: selectedSupplier?.id };
    await deleteSupplier({ variables });
    setOpenDeleteDialog(false);
  }

  if (
    loading ||
    createSupplierLoading ||
    updateSupplierLoading ||
    deleteSupplierLoading
  )
    return <Loading />;
  if (
    error ||
    createSupplierError ||
    updateSupplierError ||
    deleteSupplierError
  )
    return <p>Error</p>;

  const suppliers = data?.getSuppliers.map((o: any) => ({
    ...o,
  }));

  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />

      <MaterialTable
        title={`Furnizori`}
        columns={getColumns({ me })}
        data={suppliers}
        components={{
          Pagination: PatchedPagination,
        }}
        actions={[
          {
            icon: DeleteOutline,
            tooltip: 'Şterge înregistrarea',
            onClick: (event, rowData) => removeSupplierDialog(event, rowData),
            disabled: me.type === 'VIEWER',
          },
          {
            icon: EditOutlined,
            tooltip: 'Editează înregistrarea',
            onClick: (event, rowData) => editSupplierDialog(event, rowData),
            disabled: me.type === 'VIEWER',
          },
        ]}
      />

      <FloatingButton icon={<Add />} onClick={() => openAdd()} />
      <AddSupplierDialog
        title="Adaugă un furnizor nou"
        open={openAddDialog}
        onClose={onCloseAddDialog}
        onSave={registerSupplier}
      />
      <EditSupplierDialog
        title="Editează furnizorul"
        open={openEditDialog}
        onClose={onCloseEditDialog}
        onSave={onEditSupplier}
        supplier={selectedSupplier}
      />
      <AreYouSureDialog
        title={title}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        onYes={onRemoveSupplier}
      />
    </MainContainer>
  );
};
