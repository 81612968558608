import type React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

interface AreYouSureDialogProps {
  title: any;
  content: any;
  open: any;
  onClose: any;
  yes: any;
  no: any;
  onYes?: any;
  onNo?: any;
}
export const AreYouSureDialog: React.FC<AreYouSureDialogProps> = ({
  title,
  content,
  open,
  onClose,
  yes,
  no,
  onYes,
  onNo,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onYes}>
            {yes}
          </Button>
          <Button onClick={onClose} color="primary">
            {no}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
