import { gql } from '@apollo/client';

export const GET_ALL_ANIMALS = gql`
  query GetAllAnimals($startDate: String, $endDate: String) {
    allAnimals(input: { startDate: $startDate, endDate: $endDate }) {
      id
      imagesUrls {
        url
        fileName
      }
      regNumber
      sheetDate
      sterilizationLocation {
        location
        lat
        lng
      }
      cageNumber
      cageLocation {
        location
        lat
        lng
      }
      animalOwnerStatus
      ownerName
      ownerCNP
      ownerAddress
      animalAddress
      ownerPhone
      attendantName
      originAddress
      attendantPhone
      animalSpecies
      animalSex
      animalPregnancy
      animalBirthday
      animalDetails
      animalWeight
      animalHeight
      animalTemper
      animalTatooStatus
      animalEartag
      animalMicrochipStatus
      animalMicrochipNumber
      animalCard
      animalPassportStatus
      animalLeaveDate
      animalSterilization
      animalSterilizationDate
      animalInternalDisinfestationStatus
      animalInternalDisinfestationDetails
      animalExternalDisinfestationStatus
      animalExternalDisinfestationDetails
      animalVaccineStatus
      animalVaccineDetails
      animalSurgeryStatus
      animalSurgeryDetails
      animalTreatmentStatus
      animalDiagnostic
      animalTreatmentDetails
      animalOtherObs
      vetName
      holdingObs
      donatedByEduxanima {
        cage
        chain
        harness
        slidingWire
        others
        othersDetails
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      lastUpdate
      updatedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
