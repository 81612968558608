import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
} from '@mui/material';

interface EditUserDialogProps {
  title: any;
  open: boolean;
  onClose: any;
  onSave: any;
  user: any;
}

export const EditUserDialog: React.FC<EditUserDialogProps> = ({
  title,
  open,
  onClose,
  onSave,
  user,
}) => {
  const [email, setEmail] = useState<any>(user?.email);
  const [type, setType] = useState<any>(user?.type);
  const [firstName, setFirstName] = useState<any>(user?.firstName);
  const [lastName, setLastName] = useState<any>(user?.lastName);
  const [location, setLocation] = useState<any>(user?.location);
  const [phone, setPhone] = useState<any>(user?.phone);

  useEffect(() => {
    setEmail(user?.email);
    setType(user?.type);
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setLocation(user?.location);
    setPhone(user?.phone);
  }, [user]);

  function onEmailChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setEmail(value);
  }

  function onUserTypeChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setType(value);
  }

  function onFirstNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setFirstName(value);
  }
  function onLastNameChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setLastName(value);
  }
  function onLocationChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setLocation(value);
  }
  function onPhoneChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setPhone(value);
  }

  const variables = {
    email,
    type,
    firstName,
    lastName,
    location: {
      location,
    },
    phone,
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEnforceFocus
        fullWidth
        scroll="paper"
        maxWidth="xs"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <i>Câmpurile cu * sunt obligatorii</i>
          <form
            noValidate
            id="register-user-form"
            onSubmit={(e) => onSave(e, variables)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="firstName"
                  label="Prenume"
                  fullWidth
                  autoFocus
                  onChange={(e) => onFirstNameChange(e)}
                  value={firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="lastName"
                  label="Nume"
                  fullWidth
                  autoFocus
                  onChange={(e) => onLastNameChange(e)}
                  value={lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Adresa de email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => onEmailChange(e)}
                  value={email}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="user-type"
                  label="Funcţia utilizatorului"
                  select
                  onChange={(e) => onUserTypeChange(e)}
                  value={type}
                >
                  <MenuItem value="ADMIN">Administrator</MenuItem>
                  <MenuItem value="EDITOR">Editor</MenuItem>
                  <MenuItem value="VIEWER">Privitor</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="location"
                  label="Locaţie"
                  fullWidth
                  autoFocus
                  onChange={(e) => onLocationChange(e)}
                  value={location?.location}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="phone"
                  label="Telefon"
                  fullWidth
                  autoFocus
                  onChange={(e) => onPhoneChange(e)}
                  value={phone}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" type="submit" form="register-user-form">
            Salvează
          </Button>
          <Button onClick={onClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
