import { gql } from '@apollo/client';

export const CHANGE_PASSWORD = gql`
  mutation changeUserPassword(
    $id: ID!
    $currentPassword: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    changeUserPassword(
      id: $id
      input: {
        currentPassword: $currentPassword
        newPassword: $newPassword
        confirmNewPassword: $confirmNewPassword
      }
    ) {
      id
      isEnabled
      email
      type
      firstName
      lastName
      location {
        lat
        lng
        location
      }
      phone
      title
      createdBy {
        id
        email
        type
        firstName
        lastName
      }
      createdAt
      updatedBy {
        id
        email
        type
        firstName
        lastName
      }
      lastUpdate
    }
  }
`;
