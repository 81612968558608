import { gql } from '@apollo/client';

export const CREATE_ADOPTION = gql`
  mutation createAdoption(
    $regNumber: String!
    $animalEnteredDate: String!
    $animalStatus: AnimalStatusInput!
    $animalSpecies: String!
    $animalSex: String
    $animalName: String
    $animalMicrochipStatus: String!
    $animalMicrochipNumber: String
    $animalLocation: String
    $animalLeaveDate: String
    $animalOtherObs: String
  ) {
    createAdoption(
      input: {
        regNumber: $regNumber
        animalEnteredDate: $animalEnteredDate
        animalStatus: $animalStatus
        animalSpecies: $animalSpecies
        animalSex: $animalSex
        animalName: $animalName
        animalMicrochipStatus: $animalMicrochipStatus
        animalMicrochipNumber: $animalMicrochipNumber
        animalLocation: $animalLocation
        animalLeaveDate: $animalLeaveDate
        animalOtherObs: $animalOtherObs
      }
    ) {
      id
      regNumber
      animalEnteredDate
      animalStatus {
        entered
        adopted
        intermediated
      }
      animalSpecies
      animalSex
      animalName
      animalMicrochipStatus
      animalMicrochipNumber
      animalLocation
      animalLeaveDate
      animalOtherObs
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
      }
      lastUpdate
      updatedBy {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
