import React, { useEffect } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { ApolloClient, useApolloClient } from '@apollo/client';

import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';
import { IS_USER_LOGGED_IN } from '../gql/queries/IsUserLoggedIn';
import { NAVIGATION_ACTION } from '../gql/queries/navigationAction';

interface SignOutProps extends RouteComponentProps {}

export function signout(client: ApolloClient<any>) {
  client.writeQuery({
    query: IS_USER_LOGGED_IN,
    data: {
      isLoggedIn: false,
      type: null,
    },
  });
  client.writeQuery({
    query: NAVIGATION_ACTION,
    data: {},
  });
  localStorage.removeItem('token');
  localStorage.removeItem('type');
  client.writeQuery({
    query: ANY_ALERT_AVAILABLE,
    data: {
      alertOpen: true,
      alertSeverity: 'success',
      alertMessage: `You are logged out.`,
    },
  });

  return navigate('/signin');
}

export const SignOut: React.FC<SignOutProps> = () => {
  const client: ApolloClient<any> = useApolloClient();

  useEffect(() => {
    signout(client);
  });

  return null;
};
