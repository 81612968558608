import React, { useState, useEffect } from 'react';
import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';
import {
  Avatar,
  Container,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Tooltip,
  Box,
  Link,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { SaveAlt, Add } from '@mui/icons-material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { AddNotRegisteredAnimalsDialog } from './AddNotRegisteredAnimalsDialog';
import { Loading } from './Loading';

import { CREATE_NOT_REGISTERED_ANIMALS } from '../gql/mutations/CreateNotRegisteredAnimals';
import { GET_NOT_REGISTERED_ANIMALS_WITH_TREATMENT } from '../gql/queries/getNotRegisteredAnimalsWithTreatment';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';

// const useStyles = makeStyles((theme) => ({
//   cardGrid: {
//     backgroundColor: theme.palette.background.paper,
//     paddingTop: theme.spacing(0),
//     paddingBottom: theme.spacing(8),
//     position: 'relative',
//   },
//   title: {
//     display: 'flex',
//     justifyContent: 'center',
//   },
//   table: {
//     border: '1px solid rgba(224, 224, 224, 1)',
//     '& .MuiTableCell-root': {
//       borderLeft: '1px solid rgba(224, 224, 224, 1)',
//     },
//   },
//   exportBtnContainer: {
//     display: 'flex',
//     justifyContent: 'flex-end',
//     padding: '10px',
//   },
//   iconButton: {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     zIndex: 2000,
//   },
//   avatar: {
//     width: theme.spacing(5),
//     height: theme.spacing(5),
//     backgroundColor: 'rgba(0,0,0,0.86)',
//   },
//   menu: {
//     zIndex: '2050!important' as any,
//   },
//   notRegisteredAnimals: {
//     color: 'rgba(255, 165, 0,0.96)',
//   },
//   totalAnimals: {
//     backgroundColor: 'rgba(0,0,0,0.86)',
//     color: 'rgba(255, 255, 255, 1)',
//   },
// }));

const theme = createTheme();

const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 2000,
}));

const StyledCardGrid = styled(Paper)(() => ({
  backgroundColor: theme.palette.background.paper,
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(8),
  position: 'relative',
}));

const StyledTitle = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const StyledTable = styled(Table)(() => ({
  border: '1px solid rgba(224, 224, 224, 1)',
  '& .MuiTableCell-root': {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
}));

const StyledExportBtnContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '10px',
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  backgroundColor: 'rgba(0,0,0,0.86)',
}));

const StyledTotalAnimals = styled(TableCell)(() => ({
  backgroundColor: 'rgba(0,0,0,0.86)',
  color: 'rgba(255, 255, 255, 1)',
}));

const StyledNotRegisteredAnimals = styled(TableCell)(() => ({
  color: 'rgba(255, 165, 0,0.96)',
}));

interface AnimalsResultsTableProps {
  animals: any;
  notRegAnimals?: any;
  title: any;
  subtitle?: any;
  maxWidth: any;
  hasButton?: boolean;
  startDate?: any;
  endDate?: any;
  tableId: any;
}

export const AnimalsResultsTable: React.FC<AnimalsResultsTableProps> = ({
  animals,
  notRegAnimals,
  title,
  subtitle,
  maxWidth,
  hasButton,
  startDate,
  endDate,
  tableId,
}) => {
  const client: ApolloClient<any> = useApolloClient();
  // const classes = useStyles();
  const documentName = `${title} ${subtitle}`;
  const [openNotRegisteredAnimalsDialog, setOpenNotRegisteredAnimalsDialog] =
    useState<boolean>(false);
  const [totalTotal, setTotalTotal] = useState<any>([]);

  const [
    createNotRegisteredAnimals,
    {
      loading: createNotRegisteredAnimalsLoading,
      error: createNotRegisteredAnimalsError,
    },
  ] = useMutation(CREATE_NOT_REGISTERED_ANIMALS, {
    refetchQueries: [{ query: GET_NOT_REGISTERED_ANIMALS_WITH_TREATMENT }],
  });

  function printDocument() {
    const pdf = new jsPDF();
    autoTable(pdf, {
      html: `#${tableId}`,
      theme: 'grid',
      headStyles: {
        fillColor: [50, 200, 240],
        textColor: [0, 0, 0],
        halign: 'center',
        lineColor: [211, 211, 211],
        lineWidth: 0.1,
      },
      columnStyles: {
        0: {
          halign: 'center',
        },
        1: {
          halign: 'center',
        },
        2: {
          halign: 'center',
        },
        3: {
          halign: 'center',
        },
        4: {
          halign: 'center',
        },
        5: {
          halign: 'center',
        },
        6: {
          halign: 'center',
        },
        7: {
          halign: 'center',
        },
        8: {
          halign: 'center',
        },
      },
    });
    pdf.save(`${documentName}.pdf`);
  }

  const renderButton = (
    <StyledIconButton
      aria-label="floating menu"
      // aria-controls={menuId}
      aria-haspopup="true"
      onClick={() => handleNotRegisteredAnimalsDialog()}
      color="inherit"
    >
      <StyledAvatar>
        <Add />
      </StyledAvatar>
    </StyledIconButton>
  );

  function handleNotRegisteredAnimalsDialog() {
    setOpenNotRegisteredAnimalsDialog(true);
  }

  function handleCloseNotRegisteredAnimalsDialog() {
    setOpenNotRegisteredAnimalsDialog(false);
  }

  async function addNotRegisteredAnimals(
    event: React.FormEvent<HTMLFormElement>,
    variables: any
  ) {
    event.preventDefault();

    try {
      if (!variables) throw new Error('Nu există variabile.');
      if (!variables.sheetDate) throw new Error('Data nu este completată.');
      if (!variables.animalSpecies)
        throw new Error('Specia animalului nu este completată.');
      if (!variables.animalSex)
        throw new Error('Sexul animalului nu este completat.');
      if (typeof variables.animalOwnerStatus === 'undefined')
        throw new Error('Are proprietar? nu este completat.');
      if (!variables.total) throw new Error('Numărul total nu este completat');

      await createNotRegisteredAnimals({
        variables: {
          ...variables,
          animalTreatmentStatus: true,
        },
      });

      return handleCloseNotRegisteredAnimalsDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  useEffect(() => {
    function getTotal() {
      let totalSum: any[] = [];
      if (animals && notRegAnimals) {
        for (let i = 0; i < animals.length; i++) {
          const sum = animals[i].total + notRegAnimals[i].total;
          totalSum.push(sum);
        }
      }
      return setTotalTotal(totalSum);
    }

    getTotal();
  }, [notRegAnimals, animals]);

  if (createNotRegisteredAnimalsLoading) return <Loading />;
  if (createNotRegisteredAnimalsError) {
    return <p>Error</p>;
  }

  return (
    <Container maxWidth={maxWidth}>
      <StyledCardGrid elevation={10}>
        <StyledExportBtnContainer>
          <Tooltip title="Exportă">
            <IconButton onClick={() => printDocument()} aria-label="export-btn">
              <SaveAlt />
            </IconButton>
          </Tooltip>
        </StyledExportBtnContainer>

        {animals && (
          <TableContainer component={Paper}>
            <StyledTable size="small" id={tableId}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <StyledTitle variant="h3" gutterBottom>
                      {title}
                    </StyledTitle>
                    <StyledTitle variant="body2" gutterBottom>
                      {subtitle}
                    </StyledTitle>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    Dogs
                  </TableCell>
                  <TableCell align="center" colSpan={4}>
                    Cats
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    Total
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    Females
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Males
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Females
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    Males
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">Owned</TableCell>
                  <TableCell align="center">Stray</TableCell>
                  <TableCell align="center">Owned</TableCell>
                  <TableCell align="center">Stray</TableCell>
                  <TableCell align="center">Owned</TableCell>
                  <TableCell align="center">Stray</TableCell>
                  <TableCell align="center">Owned</TableCell>
                  <TableCell align="center">Stray</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {animals.map((animal: any, index: any) => {
                    return (
                      <TableCell key={index} align="center">
                        {animal.total}
                      </TableCell>
                    );
                  })}
                  <TableCell key="total" align="center">
                    {animals.reduce((total: any, currentValue: any) => {
                      return total + currentValue.total;
                    }, 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  {hasButton &&
                    notRegAnimals &&
                    notRegAnimals.map((animal: any, index: any) => {
                      return (
                        <StyledNotRegisteredAnimals key={index} align="center">
                          {animal.total}
                        </StyledNotRegisteredAnimals>
                      );
                    })}
                  {notRegAnimals && (
                    <StyledNotRegisteredAnimals key="total" align="center">
                      {notRegAnimals.reduce((total: any, currentValue: any) => {
                        return total + currentValue.total;
                      }, 0)}
                    </StyledNotRegisteredAnimals>
                  )}
                </TableRow>
                <TableRow>
                  {notRegAnimals &&
                    totalTotal.map((total: any, index: any) => {
                      return (
                        <StyledTotalAnimals align="center" key={index}>
                          {total}
                        </StyledTotalAnimals>
                      );
                    })}
                  {notRegAnimals && (
                    <StyledTotalAnimals key="total" align="center">
                      {totalTotal.reduce((total: any, currentValue: any) => {
                        return total + currentValue;
                      }, 0)}
                    </StyledTotalAnimals>
                  )}
                </TableRow>
              </TableBody>
            </StyledTable>
            {notRegAnimals && (
              <Box display="flex" justifyContent="center" padding="15px 0">
                <Link href="/not-registered-animals-with-treatment">
                  Vezi animale fără fişă
                </Link>
              </Box>
            )}
          </TableContainer>
        )}
        {hasButton && renderButton}
      </StyledCardGrid>
      <AddNotRegisteredAnimalsDialog
        open={openNotRegisteredAnimalsDialog}
        title=""
        onClose={handleCloseNotRegisteredAnimalsDialog}
        onSave={addNotRegisteredAnimals}
      />
    </Container>
  );
};
