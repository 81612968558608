import React, { useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import {
  useQuery,
  ApolloClient,
  useApolloClient,
  useMutation,
} from '@apollo/client';

import { Container, TextField, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import MaterialTable from 'material-table';
import DateFnsAdapter from '@date-io/date-fns';

import { MainContainer } from '../components/MainContainer';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';
import { EditNotRegisteredAnimalsDialog } from '../components/EditNotRegisteredAnimalsDialog';
import { AreYouSureDialog } from '../components/AreYouSureDialog';

import { PatchedPagination } from '../utils/PatchedPagination';

import { GET_ALL_NOT_REGISTERED_ANIMALS } from '../gql/queries/getAllNotRegisteredAnimals';
import { UPDATE_NOT_REGISTERED_ANIMALS } from '../gql/mutations/UpdateNotRegisteredAnimals';
import { DELETE_NOT_REGISTERED_ANIMALS } from '../gql/mutations/DeleteNotRegisteredAnimals';
import { ANY_ALERT_AVAILABLE } from '../gql/queries/anyAlertAvailable';

interface NotRegAnimalsWithTreatmentProps extends RouteComponentProps {
  me?: any;
}

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(8),
  },
}));

const dateFns = new DateFnsAdapter();
const monthNames = [
  'Ianuarie',
  'Februarie',
  'Martie',
  'Aprilie',
  'Mai',
  'Iunie',
  'Iulie',
  'August',
  'Septembrie',
  'Octombrie',
  'Noiembrie',
  'Decembrie',
];

const getColumns: any = ({ me }: any) => [
  {
    title: 'Perioada',
    field: 'sheetDate',
    render: ({ sheetDate }: any) => {
      const newDate = new Date(sheetDate);
      const monthName = monthNames[dateFns.getMonth(newDate)];
      const year = dateFns.getYear(newDate);
      return `${monthName} ${year}`;
    },
  },
  {
    title: 'Au tratament',
    field: 'animalTreatmentStatus',
    render: ({ animalTreatmentStatus }: any) => {
      switch (animalTreatmentStatus) {
        case true:
          return 'Da';
        case false:
          return 'Nu';
      }
    },
  },
  {
    title: 'Specie',
    field: 'animalSpecies',
    render: ({ animalSpecies }: any) => {
      switch (animalSpecies) {
        case 'dog':
          return 'caine';
        case 'cat':
          return 'pisica';
        case 'other':
          return 'alta specie';
      }
    },
    lookup: {
      dog: 'caine',
      cat: 'pisica',
      other: 'alta specie',
    },
  },
  {
    title: 'Sex',
    field: 'animalSex',
    render: ({ animalSex }: any) => {
      switch (animalSex) {
        case 'male':
          return 'mascul';
        case 'female':
          return 'femela';
      }
    },
    lookup: {
      male: 'mascul',
      female: 'femela',
    },
  },
  {
    title: 'Au proprietar',
    field: 'animalOwnerStatus',
    render: ({ animalOwnerStatus }: any) => {
      switch (animalOwnerStatus) {
        case true:
          return 'Da';
        case false:
          return 'Nu';
      }
    },
  },
  {
    title: 'Total',
    field: 'total',
  },
];
export const NotRegAnimalsWithTreatment: React.FC<
  NotRegAnimalsWithTreatmentProps
> = ({ me }) => {
  const client: ApolloClient<any> = useApolloClient();
  const classes = useStyles();
  const today = new Date();
  const dateFns = new DateFnsAdapter();
  const newDate = dateFns.formatByString(today, 'yyyy-MM-dd');
  const monthBeginning = dateFns.formatByString(today, 'yyyy-MM-01');

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedNotRegisteredAnimals, setSelectedNotRegisteredAnimals] =
    useState<any>();
  const [startDate, setStartDate] = useState<any>(monthBeginning);
  const [endDate, setEndDate] = useState<any>(newDate);

  const title = 'Eşti sigur că vrei să ştergi această înregistrare?';
  const content =
    'Ştergerea este definitivă şi această înregistrare nu mai poate fi adusă înapoi.';

  const yes = 'Şterge';
  const no = 'Închide';

  const { data, loading, error } = useQuery(GET_ALL_NOT_REGISTERED_ANIMALS, {
    variables: {
      startDate,
      endDate,
    },
  });

  function onStartDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setStartDate(value);
  }

  function onEndDateChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = (event.target as HTMLInputElement).value;
    setEndDate(value);
  }

  function removeAnimalDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedNotRegisteredAnimals(rowData);
    setOpenDeleteDialog(true);
  }

  function editAnimalDialog(event: any, rowData: any) {
    event.preventDefault();

    setSelectedNotRegisteredAnimals(rowData);
    setOpenEditDialog(true);
  }

  function onCloseEditDialog() {
    setOpenEditDialog(false);
  }

  function onCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  const [
    updateNotRegisteredAnimals,
    {
      loading: updateNotRegisteredAnimalsLoading,
      error: updateNotRegisteredAnimalsError,
    },
  ] = useMutation(UPDATE_NOT_REGISTERED_ANIMALS, {
    update(cache, { data: { updateNotRegisteredAnimals } }: any) {
      const { getAllNotRegisteredAnimals }: any = cache.readQuery({
        query: GET_ALL_NOT_REGISTERED_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
      });
      cache.writeQuery({
        query: GET_ALL_NOT_REGISTERED_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
        data: {
          getAllNotRegisteredAnimals: [
            ...getAllNotRegisteredAnimals.filter(
              (notRegisteredAnimals: any) =>
                notRegisteredAnimals.id !== updateNotRegisteredAnimals.id
            ),
            updateNotRegisteredAnimals,
          ],
        },
      });
    },
  });

  const [
    deleteNotRegisteredAnimals,
    {
      loading: deleteNotRegisteredAnimalsLoading,
      error: deleteNotRegisteredAnimalsError,
    },
  ] = useMutation(DELETE_NOT_REGISTERED_ANIMALS, {
    update(cache, { data: deleteNotRegisteredAnimals }: any) {
      const { getAllNotRegisteredAnimals }: any = cache.readQuery({
        query: GET_ALL_NOT_REGISTERED_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
      });
      cache.writeQuery({
        query: GET_ALL_NOT_REGISTERED_ANIMALS,
        variables: {
          startDate,
          endDate,
        },
        data: {
          getAllNotRegisteredAnimals: getAllNotRegisteredAnimals.filter(
            (notRegisteredAnimals: any) =>
              notRegisteredAnimals.id !== selectedNotRegisteredAnimals?.id
          ),
        },
      });
    },
  });

  async function onEditNotRegisteredAnimals(
    event: React.FormEvent<HTMLFormElement>,
    input: any
  ) {
    event.preventDefault();
    try {
      if (!input) throw new Error('Nu există variabile.');
      if (!input.sheetDate) throw new Error('Data nu este completată.');
      if (!input.animalSpecies)
        throw new Error('Specia animalului nu este completată.');
      if (!input.animalSex)
        throw new Error('Sexul animalului nu este completat.');
      if (typeof input.animalOwnerStatus === 'undefined')
        throw new Error('Are proprietar? nu este completat.');
      if (!input.total) throw new Error('Numărul total nu este completat');

      const variables: any = {
        id: selectedNotRegisteredAnimals?.id,
        ...input,
      };

      await updateNotRegisteredAnimals({ variables });

      return onCloseEditDialog();
    } catch (err: any) {
      client.writeQuery({
        query: ANY_ALERT_AVAILABLE,
        data: {
          alertOpen: true,
          alertSeverity: 'error',
          alertMessage: `${err.message}`,
        },
      });
    }
  }

  async function removeNotRegisteredAnimals() {
    const variables: any = { id: selectedNotRegisteredAnimals?.id };
    await deleteNotRegisteredAnimals({ variables });
    setOpenDeleteDialog(false);
  }

  if (
    loading ||
    updateNotRegisteredAnimalsLoading ||
    deleteNotRegisteredAnimalsLoading
  )
    return <Loading />;
  if (
    error ||
    updateNotRegisteredAnimalsError ||
    deleteNotRegisteredAnimalsError
  )
    return <p>Error</p>;

  const allNotRegisteredAnimals = data?.getAllNotRegisteredAnimals.map(
    (o: any) => ({
      ...o,
    })
  );
  return (
    <MainContainer maxWidth="xl">
      <Header me={me} />
      <Container maxWidth="sm" className={classes.cardGrid}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="startDate"
              label="Începând cu"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              value={startDate}
              onChange={(e) => onStartDateChange(e)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="endDate"
              label="Până la"
              variant="outlined"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              value={endDate}
              onChange={(e) => onEndDateChange(e)}
            />
          </Grid>
        </Grid>
      </Container>
      <MaterialTable
        columns={getColumns({ me })}
        data={allNotRegisteredAnimals}
        components={{
          Pagination: PatchedPagination,
        }}
        actions={[
          {
            icon: DeleteOutline,
            tooltip: 'Şterge înregistrarea',
            onClick: (event, rowData) => removeAnimalDialog(event, rowData),
            disabled: me.type === 'VIEWER',
          },
          {
            icon: EditOutlined,
            tooltip: 'Editează înregistrarea',
            onClick: (event, rowData) => editAnimalDialog(event, rowData),
            disabled: me.type === 'VIEWER',
          },
        ]}
      />
      <EditNotRegisteredAnimalsDialog
        open={openEditDialog}
        title=""
        notRegisteredAnimals={selectedNotRegisteredAnimals}
        onClose={onCloseEditDialog}
        onSave={onEditNotRegisteredAnimals}
      />
      <AreYouSureDialog
        title={title}
        content={content}
        yes={yes}
        no={no}
        open={openDeleteDialog}
        onClose={onCloseDeleteDialog}
        onYes={removeNotRegisteredAnimals}
      />
    </MainContainer>
  );
};
