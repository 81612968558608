import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';

import { Container, Paper, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DatePicker } from '@mui/x-date-pickers';

import DateFnsAdapter from '@date-io/date-fns';

import { AllAnimalsChart } from '../components/AllAnimalsChart';
import { AnimalsResultsChart } from '../components/AnimalsResultsChart';
import { Loading } from '../components/Loading';

import { GET_ANIMALS_TOTAL } from '../gql/queries/getAnimalsTotal';
import { GET_ALL_ANIMALS } from '../gql/queries/getAllAnimals';
import { GET_STERILIZED_ANIMALS } from '../gql/queries/getSterilizedAnimals';
import { GET_ANIMALS_WITH_SURGERIES } from '../gql/queries/getAnimalsWithSurgeries';
import { GET_ANIMALS_WITH_TREATMENT } from '../gql/queries/getAnimalsWithTreatment';

const useStyles = makeStyles(() => ({
  welcomeCard: {
    minHeight: '80px',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
  },
}));
interface HomeProps extends RouteComponentProps {
  user?: any;
  animalsTotal?: any;
}

export const Home: React.FC<HomeProps> = ({ user, animalsTotal }) => {
  const classes = useStyles();
  const today = new Date();
  const dateFns = new DateFnsAdapter();
  const newDate = dateFns.formatByString(today, 'yyyy-MM-dd');
  const initialDate = new Date('2021-01-01');
  const beginning = dateFns.formatByString(initialDate, 'yyyy-MM-dd');

  const [startDate, setStartDate] = useState<any>(beginning);
  const [endDate, setEndDate] = useState<any>(newDate);

  function onStartDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setStartDate(newValue);
  }

  function onEndDateChange(value: any) {
    const newValue = dateFns.formatByString(value, 'yyyy-MM-dd');
    setEndDate(newValue);
  }

  const titleStartDate = dateFns.formatByString(
    new Date(startDate),
    'dd/MM/yyyy'
  );
  const titleEndDate = dateFns.formatByString(new Date(endDate), 'dd/MM/yyyy');

  const {
    data: animalsTotalData,
    loading: animalsTotalLoading,
    error: animalsTotalError,
  } = useQuery(GET_ANIMALS_TOTAL, {
    variables: {
      startDate,
      endDate,
    },
  });

  const {
    data: animals,
    loading: animalsLoading,
    error: animalsError,
  } = useQuery(GET_ALL_ANIMALS, {
    variables: {
      startDate,
      endDate,
    },
  });

  // const {
  //   data: sterilizedData,
  //   loading: sterilizedLoading,
  //   error: sterilizedError,
  // } = useQuery(GET_STERILIZED_ANIMALS, {
  //   variables: {
  //     startDate,
  //     endDate,
  //   },
  // });
  // const {
  //   data: surgeriesData,
  //   loading: surgeriesLoading,
  //   error: surgeriesError,
  // } = useQuery(GET_ANIMALS_WITH_SURGERIES, {
  //   variables: {
  //     startDate,
  //     endDate,
  //   },
  // });
  // const {
  //   data: treatmentData,
  //   loading: treatmentLoading,
  //   error: treatmentError,
  // } = useQuery(GET_ANIMALS_WITH_TREATMENT, {
  //   variables: {
  //     startDate,
  //     endDate,
  //   },
  // });

  useEffect(() => {}, [user, animalsTotalData]);

  if (
    animalsTotalLoading ||
    animalsLoading
    // sterilizedLoading ||
    // surgeriesLoading ||
    // treatmentLoading
  )
    return <Loading />;
  if (
    animalsTotalError ||
    animalsError
    // sterilizedError ||
    // surgeriesError ||
    // treatmentError
  ) {
    return <p>Error</p>;
  }

  const { getAnimalsTotal } = animalsTotalData;
  // const { getSterilizedAnimals } = sterilizedData;
  // const { getAnimalsWithSurgeries } = surgeriesData;
  // const { getAnimalsWithTreatment } = treatmentData;

  return (
    <Container>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Paper elevation={10} className={classes.welcomeCard}>
            Bine ai venit, {user?.firstName || user?.email}!
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <DatePicker
                label="Începând cu"
                value={startDate}
                onChange={onStartDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="Până la"
                value={endDate}
                onChange={onEndDateChange}
                inputFormat={'dd/MM/yyyy'}
                renderInput={(params) => <TextField {...params} />}
                minDate={initialDate}
                maxDate={today}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AllAnimalsChart
            animalsTotal={getAnimalsTotal}
            title="Total animale înregistrate"
            subtitle={`(${titleStartDate} - ${titleEndDate})`}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <AnimalsResultsChart
            title="Total animale sterilizate"
            animals={getSterilizedAnimals}
            maxWidth="sm"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AnimalsResultsChart
            title="Total animale cu operaţii"
            animals={getAnimalsWithSurgeries}
            maxWidth="sm"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AnimalsResultsChart
            title="Total animale cu tratamente"
            animals={getAnimalsWithTreatment}
            maxWidth="sm"
          />
        </Grid> */}
      </Grid>
      <br /> <br />
    </Container>
  );
};
