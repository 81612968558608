import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      email
      type
      phone
      location {
        location
        lat
        lng
      }
      title
      createdAt
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
    }
  }
`;
