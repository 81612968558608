import type React from 'react';

import {
  // Menu,
  // MenuItem,
  // Divider,
  IconButton,
  Avatar,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

interface FloatingButtonProps {
  me?: any;
  onClick: any;
  icon: any;
  isRemove?: boolean;
}

const theme = createTheme();

const StyledIconButton = styled(IconButton)(() => ({
  position: 'fixed',
  bottom: 30,
  right: -35,
  transform: 'translate(-50%, -50%)',
  zIndex: 2000,
  // menu: {
  //   zIndex: '2050!important' as any,
  // },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  backgroundColor: 'rgba(0, 0, 0, 0.87)',
}));

export const FloatingButton: React.FC<FloatingButtonProps> = ({
  me,
  onClick,
  icon,
  isRemove,
}) => {
  const renderButton = (
    <StyledIconButton
      aria-label="floating menu"
      // aria-controls={menuId}
      aria-haspopup="true"
      onClick={() => onClick()}
      color="inherit"
      sx={{ bottom: isRemove ? '90px' : '30px' }}
    >
      <StyledAvatar>{icon}</StyledAvatar>
    </StyledIconButton>
  );

  return <ThemeProvider theme={theme}>{renderButton}</ThemeProvider>;
};
