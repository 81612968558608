import { gql } from '@apollo/client';

export const CREATE_INVENTORY = gql`
  mutation createInventory(
    $productId: ID!
    $date: String!
    $quantity: Float!
    $details: String
    $invoiceNumber: String
  ) {
    createInventory(
      input: {
        productId: $productId
        date: $date
        quantity: $quantity
        details: $details
        invoiceNumber: $invoiceNumber
      }
    ) {
      id
      product {
        id
        name
        type
        sku
        uom
        description
        active
        status
        supplier {
          id
          name
          description
          location {
            location
            lat
            lng
          }
          phone
        }
      }
      date
      quantity
      details
      invoiceNumber
      createdBy {
        id
        firstName
        lastName
        email
        type
      }
      createdAt
      updatedBy {
        id
        firstName
        lastName
        email
        type
      }
      lastUpdate
    }
  }
`;
